import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Tilt from "react-tilt";
import Fade from "react-reveal/Fade";
import { hotjar } from "react-hotjar";
import ModalVideo from "react-modal-video";

import SessionHelper from "./../../helpers/SessionHelper";
import ModuleHelper from "./../../helpers/ModuleHelper";
import "./Login.scss";
import { auth } from "./../../utils/auth";
import urlWithTenant from "./../../utils/urlHelper";
import { HOTJAR_ENABLED } from "./../../constants/envConstants";

import ButtonMain from "./../../components/buttons/ButtonMain/ButtonMain";

import PlayVideo from "./../../assets/images/landing_images/play_video_icon.png";
import videoPlaceholderImage1 from "./../../assets/images/landing_images/video-placeholder-01.png";
import videoPlaceholderImage2 from "./../../assets/images/landing_images/video-placeholder-02.png";
import videoPlaceholderImage3 from "./../../assets/images/landing_images/video-placeholder-03.png";
import videoPlaceholderImage4 from "./../../assets/images/landing_images/video-placeholder-04.png";
import videoPlaceholderImage5 from "./../../assets/images/landing_images/video-placeholder-05.png";
import heroGifImage from "./../../assets/images/landing_images/landing-gif.gif";
import BrowserNotSupported from "./../../components/BrowserNotSupported/BrowserNotSupported";
import browsersSupported from "./../../utils/browsersSupported";
import { featureFlag } from "./../../helpers/FeatureFlag";
import { EN } from "./../../constants/localizationConstants";
import { EXPLORE_AUTOMATIONS_PROGRAM_ROUTE } from "../../constants/routesConstants";

const landingPageData = [
  {
    id: 1,
    key: "landing_page_need_inspiration",
    title: "Need automation inspiration?",
    subtitle:
      "Not sure yet what to do? Get inspired by other people’s ideas or check out the knowledge center.",

    imgSrc: videoPlaceholderImage1,
    imagePull: "right"
  },
  {
    id: 2,
    key: "landing_page_submit_idea",
    title: "Submit your idea",
    subtitle:
      "Describe what you want to automate. Let the wizard walk you through. All it takes is a few easy steps.",
    imgSrc: videoPlaceholderImage2,
    imagePull: "left"
  },
  {
    id: 3,
    key: "landing_page_collaborate",
    title: "Collaborate with your colleagues",
    subtitle:
      "See your colleagues’ ideas, comment on them, and upvote the ones you like.",
    imgSrc: videoPlaceholderImage3,
    imagePull: "right"
  },
  {
    id: 4,
    key: "landing_page_watch_idea",
    title: "Watch your idea incubate",
    subtitle:
      "Follow the progress of the ideas you care about. Earn badges for your ideas and get on the leaderboard!",
    imgSrc: videoPlaceholderImage4,
    imagePull: "left"
  },
  {
    id: 5,
    key: "landing_page_come_to_life",
    title: "See your idea come to life!",
    subtitle:
      "Developers will turn your idea into a time-saving reality for you and your company.",
    imgSrc: videoPlaceholderImage5,
    imagePull: "right"
  }
];

const HOW_VIDEO_ID = "393789899";
const WHY_VIDEO_ID = "393789991";

const HOTJAR_ID = 1386178;
const HOTJAR_VERSION = 6;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whyVideoIsOpen: false,
      howVideoIsOpen: false
    };
  }

  componentDidMount() {
    auth.getLoggedInUserTokenFromHash(user_token => {
      if (user_token) {
        const pathWithTimestamp = SessionHelper.getValueForKey(
          "pathWithTimestamp"
        );
        // Make sure the pathWithTimestamp local storage object exists
        // make sure it has a path and timestamp properties
        // make sure it has not been more than 2 minutes since the object was set
        if (
          pathWithTimestamp &&
          pathWithTimestamp.path &&
          pathWithTimestamp.timestamp &&
          Date.now() <= 1000 * 60 * 2 + pathWithTimestamp.timestamp
        ) {
          SessionHelper.clearValueForKey("pathWithTimestamp");
          this.props.history.push(pathWithTimestamp.path);
        } else {
          SessionHelper.clearValueForKey("pathWithTimestamp");
          let hasNav = ModuleHelper.isNavigatorEnabled();
          let hasGo = ModuleHelper.isGoEnabled();
          if (hasNav) {
            this.props.history.push(
              urlWithTenant(EXPLORE_AUTOMATIONS_PROGRAM_ROUTE)
            );
          } else if (hasGo) {
            this.props.history.push(urlWithTenant("/explore-components"));
          } else {
            this.props.history.push(urlWithTenant("/explore-people"));
          }
        }
      }
    });
  }

  onWhyVideoClick = () => {
    this.setState({ whyVideoIsOpen: true });
  };

  onHowVideoClick = () => {
    this.setState({ howVideoIsOpen: true });
  };

  playSelectedVideo = () => {
    this.setState({ videoIsPlaying: true });
  };

  stopVideoIsPlaying = () => {
    this.setState({ videoIsPlaying: false });
  };

  handleSubmit = e => {
    e.preventDefault();
    auth.authorize();
  };

  generateValueProps = () => {
    const animationDuration = 300;
    const animationDistance = "40px";
    const module = landingPageData.map((valueProp, i) => {
      const pullClasses =
        valueProp.imagePull === "right"
          ? "Login__value-prop-container--right"
          : "Login__value-prop-container--left";
      return (
        <div
          className={`Login__value-prop-container ${pullClasses}`}
          key={`landing-item-${i}`}
        >
          <div className="Login__value-text">
            <Fade
              bottom
              duration={animationDuration}
              distance={animationDistance}
            >
              <div className="Login__value-line"></div>
            </Fade>
            <Fade
              bottom
              duration={animationDuration}
              distance={animationDistance}
              delay={100}
            >
              <h2 className="Login__value-title">
                {this.props.t([valueProp.key + "_title", valueProp.title])}
              </h2>
            </Fade>
            <Fade
              bottom
              duration={animationDuration}
              distance={animationDistance}
              delay={200}
            >
              <p className="Login__value-subtitle">
                {this.props.t([valueProp.key + "_title", valueProp.subtitle])}
              </p>
            </Fade>
          </div>
          <Fade
            bottom
            duration={animationDuration}
            distance={animationDistance}
            delay={400}
          >
            <Tilt options={{ max: 20, scale: 1 }} style={{ width: "100%" }}>
              <img
                src={valueProp.imgSrc}
                className="Login__value-image"
                alt=""
              />
            </Tilt>
          </Fade>
        </div>
      );
    });
    return module;
  };

  renderLoginContent = () => {
    const language = this.props.i18n.language || EN;
    return (
      <>
        <div className="Login" data-testid="Login">
          <div className="Login__hero">
            <div className="Login__hero-inner">
              <div className="Login__hero-left">
                <h2 className="Login__value-title">
                  {this.props.t([
                    "landing_page_title_new",
                    "Capture automation ideas as they arise. Manage and track them in one place."
                  ])}
                </h2>

                <div className="Login__video-btn-container">
                  <div
                    className="Login__video-btn-container-inner"
                    onClick={this.onWhyVideoClick}
                  >
                    <div>
                      <img
                        src={PlayVideo}
                        alt="Play Video Icon"
                        className="Login__hero-play-icon"
                        onClick={this.playSelectedVideo}
                      />
                    </div>
                    <div className="Login__video-btn-container-label">
                      {this.props.t(["landing_page_why", "WHY AUTOMATE?"])}
                    </div>
                  </div>
                  <div
                    className="Login__video-btn-container-inner"
                    onClick={this.onHowVideoClick}
                  >
                    <div>
                      <img
                        src={PlayVideo}
                        alt="Play Video Icon"
                        className="Login__hero-play-icon"
                        onClick={this.playSelectedVideo}
                      />
                    </div>
                    <div className="Login__video-btn-container-label">
                      {this.props.t(["landing_page_how", "HOW DO I START?"])}
                    </div>
                  </div>
                </div>
              </div>

              <div className="Login__hero-right">
                <div className="Login__hero-right-img">
                  <img src={heroGifImage} alt="Hero" />
                </div>
              </div>
            </div>

            <ModalVideo
              id="whyVideo"
              channel="vimeo"
              vimeo={{
                allow: `&texttrack=${language}&autoplay=1&loop=1&title=0&byline=0&portrait=0&#t=0s`
              }}
              isOpen={this.state.whyVideoIsOpen}
              videoId={WHY_VIDEO_ID}
              animationSpeed={2000}
              autoplay={1}
              onClose={() => this.setState({ whyVideoIsOpen: false })}
            />

            <ModalVideo
              id="howVideo"
              channel="vimeo"
              vimeo={{
                allow: "&autoplay=1&loop=1&title=0&byline=0&portrait=0&#t=0s"
              }}
              isOpen={this.state.howVideoIsOpen}
              videoId={HOW_VIDEO_ID}
              animationSpeed={2000}
              autoplay={1}
              onClose={() => this.setState({ howVideoIsOpen: false })}
            />
          </div>

          <div className="Login__value-prop-outer">
            {this.generateValueProps()}
          </div>
          <div className="Login__signup-container">
            <h1 className="Login__signup-title">
              {this.props.t([
                "landing_page_ready_to_try",
                "Ready to give it a try?"
              ])}
            </h1>
            <form
              className="Login__form"
              autoComplete="off"
              onSubmit={this.handleSubmit}
            >
              <ButtonMain className="Login__button Login__button">
                {this.props.t([
                  "landing_page_signin",
                  "Sign in and Get Started"
                ])}
              </ButtonMain>
            </form>
          </div>
        </div>
        {/*<MainFooter />*/}
      </>
    );
  };

  render() {
    if (HOTJAR_ENABLED === "ENABLED" && !featureFlag.isHotjarDisabled()) {
      hotjar.initialize(HOTJAR_ID, HOTJAR_VERSION);
    }

    return (
      <React.Fragment>
        {!browsersSupported() ? (
          <BrowserNotSupported />
        ) : (
          this.renderLoginContent()
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation("common")(Login));
