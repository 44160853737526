export const CustomQuestionSlugs = {
  PRIMARY_REASON_TO_AUTOMATE: "PRIMARY_REASON_TO_AUTOMATE",
  PROCESS_CHANGE_EXPECTED: "CHANGES_EXPCT",
  APPLICATION_CHANGE_EXPECTED: "APPS_CHANGES_EXPCT",
  AVERAGE_WORKING_DAYS_PER_YEAR: "WORKING_DAYS",
  WORKING_HOURS_PER_DAY: "WORKING_HOURS",
  AVERAGE_EMPLOYEE_FULL_COST_YEAR: "EMPLOYEE_COST_YEARLY",
  TASK_PROCESS_FREQUENCY: "FREQ",
  ACTIVITY_VOLUME_AVERAGE: "VOLUMES",
  NUMBER_OF_EMPLOYEES_PERFORMING_TASK: "NB_EMPLOYEES",
  AVG_PROCESS_TIME_TRANSACTION: "TIME_PER_TRANS",
  ACTIVITY_VOLUME_AVERAGE_FREQUENCY: "VOLUMES_FREQUENCY",
  TOTAL_PROCESSING_TIME: "TOTAL_PROC_TIME",
  AVG_ERROR_RATE: "ERROR_RATE",
  AVG_REWORK_TIME: "REWORK_TIME",
  TOTAL_REWORK_TIME: "TOTAL_REWORK_TIME",
  AVG_WORK_REVIEWED: "REVIEW_RATE",
  AVG_REV_TIME: "REVIEW_TIME",
  TOTAL_REV_TIME: "TOTAL_REVIEW_TIME",
  TOTAL_TIME_NEEDED_AS_IS: "HOURS_PER_YEAR",
  FTE_REQUIRED: "FTE",
  COST_PER_YEAR_AS_IS: "COST_PER_YEAR",
  PROCESS_PEAKS: "PROCESS_PEAKS",
  AVERAGE_NUMBER_OF_STEPS: "NB_STEPS",
  NUMBER_OF_WAYS_TO_COMPLETE_THE_PROCESS: "NB_WAYS_COMP_PROC",
  PCT_DATA_DIGITAL_INPUT: "PCT_DIGITAL_DATA",
  SCANNED_DATA_INPUT: "OCR",
  PCT_STRUCTURED_DIGITAL_DATA_INPUT: "PCT_STRC_DATA",
  APPLICATIONS: "COUNT_APPS",
  APPLICATIONS_THIN: "COUNT_THIN_APPS",
  APPLICATION_THIN_CLIENT_MULTIPLIER: "THIN_CLIENT_M",
  APPLICATION_NUMBER_MULTIPLIER: "NB_APPS",
  AUTOMATION_POTENTIAL: "AUTOMATION_POTENTIAL",
  EASE_OF_IMPLEMENTATION: "EASE_OF_IMPLEMENTATION",
  EASE_OF_IMPLEMENTATION_LEVEL: "EASE_OF_IMPLEMENTATION_LEVEL",
  BENEFIT_PER_COMPANY_HOURS: "BENEFIT_CO_HOURS",
  BENEFIT_PER_COMPANY_CURRENCY: "BENEFIT_CO_CURRENCY",
  BENEFIT_PER_COMPANY_FTE: "BENEFIT_CO_FTE",
  BENEFIT_PER_EMPLOYEE_HOURS: "BENEFIT_PER_EMPLOYEE_HOURS",
  BENEFIT_PER_EMPLOYEE_CURRENCY: "BENEFIT_PER_EMPLOYEE_CURRENCY",
  BENEFIT_PER_EMPLOYEE_FTES: "BENEFIT_PER_EMPLOYEE_FTES",
  CHART_AUTOMATION_POTENTIAL: "CHART_AUTOMATION_POTENTIAL",

  // same variables as Q3
  DA_AVG_ERROR_RATE_AFTER_AUTOMATION: "DA_AVG_ERROR_RATE_AFTER_AUTO",
  DA_AVG_PROCESS_DURATION_AFTER_AUTOMATION:
    "DA_AVG_PROCESS_DURATION_AFTER_AUTO",
  DA_AVG_REWORK_TIME_AFTER_AUTOMATION: "DA_AVG_REWORK_TIME_AFTER_AUTO",

  DA_LIVE_BENEFIT_EM_HRS_MO: "DA_LIVE_BENEFIT_EM_HRS_MO",
  CTZN_DEV_LIVE_BENEFIT_EM_HRS_MO: "CTZN_DEV_LIVE_BENEFIT_EM_HRS_MO",
  CBA_LIVE_BENEFIT_EM_HRS_MO: "CBA_LIVE_BENEFIT_EM_HRS_MO",

  Q1_DECISIONS_STRAIGHTFORWARD_RULES: "DECISIONS_STRAIGHTFORWARD_RULES",
  Q1_INPUT_DATA_ACCESSED_DIGITALLY: "INPUT_DATA_ACCESSED_DIGITALLY",
  Q1_DATA_HIGHLY_FORMATTED: "DATA_HIGHLY_FORMATTED",
  Q1_NO_EXPECTED_CHANGES: "NO_EXPECTED_CHANGES",
  Q1_PROCESS_DOCUMENTS: "PROCESS_DOCUMENTS",

  Q1_SUITABILITY_SCORE: "SUITABILITY_SCORE",
  Q1_READINESS_SCORE: "READINESS_SCORE",
  Q1_OVERALL_GAUGE_SCORE: "OVERALL_GAUGE_SCORE",
  CHART_IDEA_SCORE: "CHART_IDEA_SCORE",

  OVERVIEW_NAME: "OVERVIEW_NAME",
  OVERVIEW_DESCRIPTION: "OVERVIEW_DESCRIPTION",
  OVERVIEW_CATEGORY: "OVERVIEW_CATEGORY",
  OVERVIEW_AUTO_GENERATED_ID: "OVERVIEW_AUTO_GENERATED_ID",
  OVERVIEW_AUTOMATION_ID: "OVERVIEW_AUTOMATION_ID",
  OVERVIEW_PAIN_POINTS: "OVERVIEW_PAIN_POINTS",
  OVERVIEW_NEGATIVE_IMPACT: "OVERVIEW_NEGATIVE_IMPACT",
  OVERVIEW_TAGS: "OVERVIEW_TAGS",
  OVERVIEW_PRIORITY: "OVERVIEW_PRIORITY",
  OVERVIEW_DEVELOPMENT_TYPE: "DEVELOPMENT_TYPE",
  OVERVIEW_PROCESS_SUBMISSION_TYPE: "OVERVIEW_PROCESS_SUBMISSION_TYPE",
  OVERVIEW_PROCESS_SUBMITTER: "OVERVIEW_PROCESS_SUBMITTER",
  OVERVIEW_PROCESS_PHASE_STATUS: "OVERVIEW_PROCESS_PHASE_STATUS",

  OVERVIEW_USEFUL_LINK: "OVERVIEW_USEFUL_LINK",
  MISC_EXPECTED_BENEFITS: "MISC_EXPECTED_BENEFITS",
  MISC_ACTUAL_BENEFITS: "MISC_ACTUAL_BENEFITS",
  MISC_EXPECTED_CHALLENGES: "MISC_EXPECTED_CHALLENGES",
  MISC_ACTUAL_CHALLENGES: "MISC_ACTUAL_CHALLENGES",
  MISC_LESSONS_LEARNED: "MISC_LESSONS_LEARNED",
  MISC_MEDIA: "MISC_MEDIA",
  MISC_SIMILAR_AUTOMATIONS: "MISC_SIMILAR_AUTOMATIONS",

  PROCESS_OWNER_Q1: "PROCESS_OWNER_Q1",
  PROCESS_OWNER_Q2: "PROCESS_OWNER_Q2",

  CBA_ESTIMATED_BENEFIT_FTES: "CBA_ESTIMATED_BENEFIT_FTES",
  NUMBER_OF_ROBOTS: "NUMBER_OF_ROBOTS",
  ESTIMATED_IMPLEMENTATION_COSTS: "ESTIMATED_IMPLEMENTATION_COSTS",
  ESTIMATED_RUNNING_COSTS: "ESTIMATED_RUNNING_COSTS",
  ESTIMATED_NET_BENEFIT_YEAR_ONE: "ESTIMATED_NET_BENEFIT_YEAR_ONE",
  ESTIMATED_NET_BENEFIT_YEAR_TWO: "ESTIMATED_NET_BENEFIT_YEAR_TWO",
  FTR_OVERRIDDEN: "FTR_OVERRIDDEN",

  CBA_EASE_OF_IMPLEMENTATION: "CBA_EASE_OF_IMPLEMENTATION",
  CBA_EASE_OF_IMPLEMENTATION_LEVEL: "CBA_EASE_OF_IMPLEMENTATION_LEVEL",

  CBA_AUTOMATION_POTENTIAL: "CBA_AUTOMATION_POTENTIAL",
  CBA_ESTIMATED_BENEFIT_COST: "CBA_ESTIMATED_BENEFIT_COST",
  CBA_ESTIMATED_BENEFIT_SCORE: "CBA_ESTIMATED_BENEFIT_SCORE",
  CBA_ESTIMATED_BENEFIT_HOURS_PER_EMPLOYEE:
    "CBA_ESTIMATED_BENEFIT_HOURS_PER_EMPLOYEE",
  CBA_ESTIMATED_COST_BENEFIT_PER_EMPLOYEE:
    "CBA_ESTIMATED_COST_BENEFIT_PER_EMPLOYEE",
  CBA_ESTIMATED_BENEFIT_FTES_PER_EMPLOYEE:
    "CBA_ESTIMATED_BENEFIT_FTES_PER_EMPLOYEE",
  CBA_STATS_SOFTWARE_VENDOR: "CBA_STATS_SOFTWARE_VENDOR",
  CBA_STATS_LICENSE_TYPE: "CBA_STATS_LICENSE_TYPE",
  CBA_STATS_PROCESS_TYPE: "CBA_STATS_PROCESS_TYPE",

  AUTOMATION_PROCESS_FREQUENCY: "AUTOMATION_PROCESS_FREQUENCY",
  AUTOMATION_PROCESS_PEAKS: "AUTOMATION_PROCESS_PEAKS",
  AUTOMATION_VOLUME_AVERAGE: "AUTOMATION_VOLUME_AVERAGE",
  AUTOMATION_NUMBER_OF_PEOPLE_BENEFIT: "AUTOMATION_NUMBER_OF_PEOPLE_BENEFIT",
  AVERAGE_PROCESS_DURATION_BEFORE_AUTOMATION: "PROCESS_DURATION_BEFORE_AUTO",
  AVERAGE_PROCESS_DURATION_AFTER_AUTOMATION: "AVG_PROCESS_DURATION_AFTER_AUTO",
  AUTOMATION_AVERAGE_PROCESS_DURATION_DIFFERENCE:
    "AVG_PROCESS_DURATION_DIFFERENCE",
  AVERAGE_ERROR_RATE_BEFORE_AUTOMATION: "AVG_ERROR_RATE_BEFORE_AUTO",
  AVERAGE_ERROR_RATE_AFTER_AUTOMATION: "AVG_ERROR_RATE_AFTER_AUTO",
  AUTOMATION_AVERAGE_ERROR_RATE_DIFFERENCE: "AVG_ERROR_RATE_DIFFERENCE",
  AVERAGE_REWORK_TIME_BEFORE_AUTOMATION: "AVG_REWORK_TIME_BEFORE_AUTO",
  AVERAGE_REWORK_TIME_AFTER_AUTOMATION: "AVG_REWORK_TIME_AFTER_AUTO",
  AUTOMATION_AVERAGE_REWORK_TIME_DIFFERENCE: "AVG_REWORK_TIME_DIFFERENCE",
  AUTOMATION_SOURCE_CODE: "AUTOMATION_SOURCE_CODE",
  AUTOMATION_PACKAGE: "AUTOMATION_PACKAGE",
  AUTOMATION_USER_GUIDES_AND_DOCS: "AUTOMATION_USER_GUIDES_AND_DOCS",
  AUTOMATION_COMPONENTS: "AUTOMATION_COMPONENTS",
  AUTOMATION_COMPLIANCE_AND_PRIVACY: "AUTOMATION_COMPLIANCE_AND_PRIVACY",
  Q3_AUTOMATION_POTENTIAL_BENEFIT_PER_EMPLOYEE:
    "AUTOMATION_POTENTIAL_BENEFIT_PER_EMPLOYEE",
  Q3_AUTOMATION_POTENTIAL_BENEFIT_ACROSS_COMPANY:
    "AUTOMATION_POTENTIAL_BENEFIT_ACROSS_COMPANY",
  STATS_PROCESS_NUM_FOLLOWERS: "PROCESS_NUM_FOLLOWERS",
  STATS_PROCESS_NUM_VOTES: "PROCESS_NUM_VOTES",
  STATS_PROCESS_NUM_MEDIA: "PROCESS_NUM_MEDIA",
  STATS_PROCESS_NUM_DOCUMENTS: "PROCESS_NUM_DOCUMENTS",
  STATS_PROCESS_NUM_COLLABORATORS: "PROCESS_NUM_COLLABORATORS",
  STATS_PROCESS_NUM_COMPONENTS: "PROCESS_NUM_COMPONENTS",
  STATS_PROCESS_NUM_COMMENTS: "PROCESS_NUM_COMMENTS",
  STATS_PROCESS_NUM_APPLICATIONS: "PROCESS_NUM_APPLICATIONS",
  STATS_PROCESS_APPLICATIONS: "PROCESS_APPLICATIONS",
  STATS_PROCESS_NUM_SIMILAR_AUTOMATIONS: "PROCESS_NUM_SIMILAR_AUTOMATIONS",
  STATS_PROCESS_NUM_REQUESTS: "PROCESS_NUM_REQUESTS",
  STATS_PROCESS_TAGS: "PROCESS_TAGS",
  STATS_PROCESS_IDEA_PHASE_APPROVER: "STATS_PROCESS_IDEA_PHASE_APPROVER",
  STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVER:
    "STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVER",
  STATS_PROCESS_QUALIFICATION_PHASE_APPROVER:
    "STATS_PROCESS_QUALIFICATION_PHASE_APPROVER",
  STATS_PROCESS_IDEA_PHASE_APPROVED_EPOCH:
    "STATS_PROCESS_IDEA_PHASE_APPROVED_EPOCH",
  STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVED_EPOCH:
    "STATS_PROCESS_LAUNCH_ASSESSMENT_APPROVED_EPOCH",
  STATS_PROCESS_QUALIFICATION_PHASE_APPROVED_EPOCH:
    "STATS_PROCESS_QUALIFICATION_PHASE_APPROVED_EPOCH",
  STATS_PROCESS_CREATED_EPOCH: "STATS_PROCESS_CREATED_EPOCH",
  STATS_PROCESS_UPDATED_EPOCH: "STATS_PROCESS_UPDATED_EPOCH",
  STATS_PROCESS_LAUNCHED_EPOCH: "STATS_PROCESS_LAUNCHED_EPOCH",
  STATS_AUTOMATION_IS_PUBLISHED: "STATS_AUTOMATION_IS_PUBLISHED",
  STATS_AUTOMATION_NUM_DOWNLOADS: "STATS_AUTOMATION_NUM_DOWNLOADS",
  STATS_AUTOMATION_REVIEW_SCORE: "STATS_AUTOMATION_REVIEW_SCORE",
  STATS_AUTOMATION_NUM_REVIEWS: "STATS_AUTOMATION_NUM_REVIEWS",
  STATS_PDD_LINKS: "STATS_PDD_LINKS",
  STATS_PROCESS_NUM_CHANGE_REQUESTS: "PROCESS_NUM_CHANGE_REQUESTS",

  CR_REASON_FOR_CHANGE: "CR_REASON_FOR_CHANGE",
  CR_URGENCY: "CR_URGENCY",
  CR_DOCUMENTATION: "CR_DOCUMENTATION",
  CR_COLLABORATORS: "CR_COLLABORATORS",
  CR_BENEFIT_PER_COMPANY_HOURS: "CR_BENEFIT_PER_COMPANY_HOURS",
  CR_BENEFIT_PER_COMPANY_CURRENCY: "CR_BENEFIT_PER_COMPANY_CURRENCY",
  CR_BENEFIT_PER_COMPANY_FTE: "CR_BENEFIT_PER_COMPANY_FTE",
  CR_TARGET_LAUNCH_DATE: "CR_TARGET_LAUNCH_DATE",
  CR_PARENT_AUTOMATION: "CR_PARENT_AUTOMATION",
  CR_HLA_PROCESS_OPTIONS: "CR_HLA_PROCESS_OPTIONS",
  CR_DA_PROCESS_OPTIONS: "CR_DA_PROCESS_OPTIONS",
  CR_CDA_PROCESS_OPTIONS: "CR_CDA_PROCESS_OPTIONS",
  CR_MISC_PROCESS_OPTIONS: "CR_MISC_PROCESS_OPTIONS",
  CR_APPLICATIONS: "CR_APPLICATIONS"
};

export const questionImpactsCBA = [
  CustomQuestionSlugs.TASK_PROCESS_FREQUENCY,
  CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE,
  CustomQuestionSlugs.NUMBER_OF_EMPLOYEES_PERFORMING_TASK,
  CustomQuestionSlugs.AVG_PROCESS_TIME_TRANSACTION,
  CustomQuestionSlugs.AVG_ERROR_RATE,
  CustomQuestionSlugs.AVG_REWORK_TIME,
  CustomQuestionSlugs.AVG_WORK_REVIEWED,
  CustomQuestionSlugs.AVG_REV_TIME,
  CustomQuestionSlugs.TOTAL_PROCESSING_TIME,
  CustomQuestionSlugs.AVERAGE_EMPLOYEE_FULL_COST_YEAR,
  CustomQuestionSlugs.FTE_REQUIRED
];

export const questionImpactsDashboards = [
  CustomQuestionSlugs.NUMBER_OF_EMPLOYEES_PERFORMING_TASK,
  CustomQuestionSlugs.TASK_PROCESS_FREQUENCY,
  CustomQuestionSlugs.ACTIVITY_VOLUME_AVERAGE,
  CustomQuestionSlugs.AVG_PROCESS_TIME_TRANSACTION,
  CustomQuestionSlugs.BENEFIT_PER_COMPANY_HOURS,
  CustomQuestionSlugs.BENEFIT_PER_COMPANY_CURRENCY
];

export const CustomQuestionVariableName = (custom_question_slug: string) => {
  switch (custom_question_slug) {
    case CustomQuestionSlugs.PROCESS_OWNER_Q1:
    case CustomQuestionSlugs.PROCESS_OWNER_Q2: {
      return "PROCESS_OWNER";
    }
    case CustomQuestionSlugs.MISC_MEDIA: {
      return "MEDIA";
    }
    case CustomQuestionSlugs.APPLICATIONS:
    case CustomQuestionSlugs.CR_APPLICATIONS: {
      return "COUNT_APPS";
    }
  }
  return custom_question_slug;
};

export enum StandardQuestionSlugs {
  STANDARD_TEXT = "question_standard_text",
  RICH_TEXT = "question_rich_text",
  MULTIPLE_CHOICE = "question_multiple_choice",
  SINGLE_CHOICE = "question_single_choice",
  SINGLE_CHOICE_NESTED = "question_single_choice_nested",
  PERCENTAGE = "question_pct",
  NUMBER = "question_number",
  RANGED_NUMBER = "question_ranged_number",
  APPLICATIONS_COUNT = "applications", // this is actually application count - not saving the applications
  PROCESS_PROPERTY = "property",
  MEDIA = "media",
  AUTOMATIONS = "automations", //for similar automations
  USER = "user",
  DOCUMENT = "document",
  COMPONENTS = "components",
  LINK = "link",
  DATE = "date",
  COLLABORATORS = "collaborators",
  PROCESS_LINK = "process_link",
  ORCHESTRATOR_PACKAGE_MAPPING = "orchestrator_package_mapping"
}

export enum NonStandardQuestionSlugs {
  OVR_AUTOMATION_NAME = "ovr_automation_name",
  OVR_CATEGORIES = "ovr_categories",
  OVR_TAGS = "ovr_tags",
  Q1_DOCUMENTS = "q1_documents"
}

export enum StandardQuestionAnswerOptionSlugs {
  STANDARD_TEXT_OPTION = "answer_option_standard_text",
  STANDARD_RICH_TEXT_OPTION = "answer_option_rich_text",
  STANDARD_NUMBER_OPTION = "answer_option_standard_number",
  STANDARD_PERCENT_OPTION = "answer_option_standard_percent",
  STANDARD_SINGLE_CHOICE_OPTION = "answer_option_single_choice",
  QUESTION_ANSWER_SINGLE_CHOICE_OPTION = "answer_option_question_answer_option", // answer is dependent on a variable - the value will be the ahid
  STANDARD_MULTIPLE_CHOICE_OPTION = "answer_option_multiple_choice",
  PROCESS_PROPERTY_CHOICE_OPTION = "answer_option_process_property",
  STANDARD_DOCUMENT_ANSWER = "answer_document",
  STANDARD_EXTERNAL_DATA_ANSWER = "answer_external",
  USER_OPTION = "answer_option_user",
  STANDARD_DATE_OPTION = "standard_date_option",
  PROCESS_LINK_OPTION = "process_link_option",
  ORCHESTRATOR_PACKAGE_MAPPING_OPTION = "orchestrator_package_mapping_option"
}

export enum NonStandardQuestionAnswerOptionSlugs {
  DOCUMENT_MULTIPLE_CHOICE_OPTION = "answer_option_document_multiple_choice"
}
export enum StandardKPISlugs {
  NUMBER_KPI = "NUMBER_KPI",
  PERCENT_KPI = "PERCENT_KPI",
  LOCKED_PERCENT_KPI = "LOCKED_PERCENT_KPI",
  LOCKED_NUMBER_KPI = "LOCKED_NUMBER_KPI",
  CHART_KPI = "CHART_KPI",
  APPLICATION_KPI = "APPLICATION_KPI",
  REFERENCE_KPI = "REFERENCE_KPI",
  NUMBER_REFERENCE_KPI = "NUMBER_REFERENCE_KPI",
  GAUGE_KPI = "GAUGE_KPI"
}

export enum NonStandardKPISlugs {
  Q3_POTENTIAL_BENEFIT_KPI = "Q3_POTENTIAL_BENEFIT_KPI"
}

export enum StandardProcessAnswerSlugs {
  KPI = "PROCESS_ANSWER_KPI_V1",
  QUESTION = "PROCESS_ANSWER_QUESTION_V1"
}
