import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./DropDownNavAuth.scss";
import styleTheme from "../../constants/styleTheme";
import { setActiveLinkAttribute } from "../../utils/urlHelper";
import SessionHelper from "../../helpers/SessionHelper";
import { getNestedThemeContainer } from "../../utils/elementUtils";

const DropDownNavAuth = props => {
  let {
    title,
    hasArrow,
    children,
    titleComponent,
    rightItem,
    adminOptions,
    className
  } = props;
  const [isMobileItemsOpen, setIsMobileItemsOpen] = useState(false);
  const { t } = useTranslation("common");

  let baseClass = "DropDownNavAuth";

  let dropDownTitleClass = hasArrow
    ? "DropDownNavAuth__title DropDownNavAuth__title--arrow"
    : "DropDownNavAuth__title";

  let itemsClass = "DropDownNavAuth__items";

  if (rightItem) {
    itemsClass += " DropDownNavAuth__items--right";
    baseClass += " DropDownNavAuth--right";
  }

  if (isMobileItemsOpen) {
    itemsClass += " DropDownNavAuth__items--mobile-open";
  }

  if (adminOptions) {
    itemsClass += " DropDownNavAuth__items--admin";
  }

  if (className) {
    baseClass += ` ${className}`;
  }

  if (SessionHelper.isFpsUri()) {
    baseClass += " DropDownNavAuth--fps";
    itemsClass += " DropDownNavAuth__items--fps";
  }

  if (Array.isArray(children)) {
    let hasActiveChild = false;
    children.forEach(child => {
      if (
        child &&
        child.props &&
        setActiveLinkAttribute(child.props.to, child.props.match) === "active"
      ) {
        hasActiveChild = true;
      }
    });
    if (hasActiveChild) {
      dropDownTitleClass += " DropDownNavAuth__title--active";
    }
  }

  const showMobileChildren = () => {
    const themeContainer = getNestedThemeContainer();
    if (themeContainer && themeContainer.classList.contains("compact-nav")) {
      setIsMobileItemsOpen(true);
    }
  };

  const closeMobileChildren = () => {
    // blur element to prevent accessibility focus on click keeping the element open
    document.activeElement?.blur();
    setIsMobileItemsOpen(false);
  };

  return (
    <div className={baseClass}>
      {title ? (
        <button className={dropDownTitleClass} onClick={showMobileChildren}>
          {title}
        </button>
      ) : (
        ""
      )}
      {titleComponent ? (
        <button
          onClick={showMobileChildren}
          className="DropDownNavAuth__title-button-container"
        >
          {titleComponent}
        </button>
      ) : (
        ""
      )}
      <div className={itemsClass}>
        <button
          className="DropDownNavAuth__item-back-button"
          onClick={closeMobileChildren}
        >
          <i /> {t(["auth_nav_dropdown_back", "Back"])}
        </button>
        {children}
      </div>
    </div>
  );
};

export default DropDownNavAuth;
