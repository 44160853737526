import styled, { css } from "styled-components/macro";
import React, {
  useEffect,
  useRef,
  ReactElement,
  useMemo,
  useContext,
  useLayoutEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FPS_BASE_URL } from "../constants/envConstants";
import { auth } from "../utils/auth";
import SessionHelper from "../helpers/SessionHelper";
import { LANGUAGE_COOKIE_NAME } from "../constants/localizationConstants";
import { AUTOMATION_STORE_SERVICE } from "../constants/urlConstants";
import { ThemeChangedEvent } from "@uipath/portal-shell-types/models/events/themeChangedEvent";
import {
  ApolloThemeContext,
  APOLLO_THEME_LIGHT_KEY,
  APOLLO_THEME_DARK_KEY
} from "../components/StyleThemeProvider/StyleThemeProvider";
import { featureFlag } from "../helpers/FeatureFlag";
import urlWithTenant from "../utils/urlHelper";
import { setLanguagePropertyForPage } from "../components/LanguagePicker/utils/languageUtils";
import { afterLanguageChangeCallback } from "../components/LanguagePicker/LanguagePicker";
import { FPS_LOGIN_EVENT_NAME } from "../utils/authFPSHandler";
import { useHistory, useLocation } from "react-router";
import jwtDecode from "jwt-decode";

const Container = styled.div<{ $showNav?: boolean }>(
  props => `
  padding-left: ${
    props.$showNav ? props.theme?.sizes?.["fps-side-nav-width"] : "0px"
  };
`
);

const Nav = styled("div")(
  () => css`
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    z-index: 102;
  `
);
const handleBrowserFocus = () => {
  try {
    // trigger a resize event so the layout
    // refreshes when window is back in focus
    // Mostly needed for an issue with react-text-truncate
    window.dispatchEvent(new Event("resize"));
  } catch (_) {
    // fail silently
  }
};

const MasterLayoutWrapper = (props: any): ReactElement => {
  const { i18n } = useTranslation("common");
  const { setTheme } = useContext(ApolloThemeContext);
  const accessToken = SessionHelper.getUserCloudAccessToken() || "";
  const cloudTenant = SessionHelper.fpsTenantFromCurrentPath();
  const cloudAccount = SessionHelper.fpsAccountFromCurrentPath();
  const showNav = SessionHelper.isFpsUri();
  const hasAddedWindowEventListeners = useRef<boolean>(false);
  // need to keep a counter that will trigger a rerender when
  // fps auth token gets updated
  const [renderCounter, setRenderCounter] = useState(0);
  const history = useHistory();
  const location = useLocation();

  const activeService = useMemo(() => {
    return SessionHelper.currentFpsService() === AUTOMATION_STORE_SERVICE
      ? "automationstore"
      : "automationhub";
  }, [window.location.pathname]);

  useLayoutEffect(() => {
    // Listen for fps token update so components rerender with valid token
    window.addEventListener(FPS_LOGIN_EVENT_NAME, handleFpsAuth);
    // Need to check if the cloud auth_time mismatch, only when page first loads - RPANAV-9767
    checkCloudTokenAuthTime();
    return () => {
      window.removeEventListener(FPS_LOGIN_EVENT_NAME, handleFpsAuth);
    };
  }, []);

  useEffect(() => {
    // Call on mount incase tab was inactive and reloaded
    handleBrowserFocus();
    window.addEventListener("focus", handleBrowserFocus);

    return () => {
      window.removeEventListener("focus", handleBrowserFocus);
    };
  }, []);

  // only need to add these event listeners to the window once
  // useEffect and useLayoutEffect had race condition where initial events would not get handled
  if (hasAddedWindowEventListeners.current === false) {
    window?.addEventListener("logoutClicked", () => {
      auth.logout();
    });

    window?.addEventListener("tenantChanged", (event: any) => {
      if (event && event.detail && event.detail.selectedTenantId) {
        const newTenant = event.detail.selectedTenantId;
        const currentTenant =
          event.detail.activeTenantId ||
          SessionHelper.fpsTenantFromCurrentPath();
        if (newTenant !== currentTenant) {
          if (newTenant.toLowerCase() === currentTenant.toLowerCase()) {
            let existingPath =
              window.location.pathname +
              window.location.hash +
              window.location.search;
            let newPath = existingPath.replace(currentTenant, newTenant);
            console.log({ newPath });
            history.replace(newPath);
          } else {
            const existingUrlPath = urlWithTenant(
              "/",
              SessionHelper.currentFpsService()
            );
            const newUrl =
              window.location.origin +
              existingUrlPath.replace(`/${currentTenant}/`, `/${newTenant}/`);
            console.log(`newUrl ${newUrl}`);
            window.location.assign(newUrl);
          }
        }
      }
      console.log(`selected tenant ${event.detail.selectedTenantId}`);
    });

    window?.addEventListener("languageChanged", (event: any) => {
      let language: string = event.detail.selectedLanguageId;
      console.log(`selected language ${language}`);

      if (!language) {
        return;
      }

      SessionHelper.setDataForKey(language, LANGUAGE_COOKIE_NAME);
      moment.locale(language);
      setLanguagePropertyForPage(language);
      i18n.changeLanguage(language, afterLanguageChangeCallback);
      SessionHelper.updateUserPilot();
    });

    if (featureFlag.isApolloDarkThemeEnabled()) {
      window?.addEventListener("themeChanged", ((
        evt: CustomEvent<ThemeChangedEvent>
      ) => {
        console.log(
          `portal-nav: event triggered : themeChanged ${evt.detail.selectedThemeId}`
        );

        if (setTheme) {
          const updatedThemeValue =
            evt.detail.selectedThemeId === APOLLO_THEME_DARK_KEY
              ? APOLLO_THEME_DARK_KEY
              : APOLLO_THEME_LIGHT_KEY;
          setTheme(updatedThemeValue);
          SessionHelper.updateUserPilot({ theme: updatedThemeValue });
        }
      }) as EventListener);
    }
    // only need to add these event listeners to the window once
    hasAddedWindowEventListeners.current = true;
  }

  const handleFpsAuth = () => {
    // Probably dont want this counter getting too big
    setRenderCounter(renderCounter > 100 ? 0 : renderCounter + 1);
  };

  // Need to check if there is  a cloud access token auth_time mismatch
  // this could mean when the user logged out their ah tokens were not cleared from storage
  const checkCloudTokenAuthTime = () => {
    const cloudSetUserCloudAccessToken = SessionHelper.getCloudSetUserCloudAccessToken();
    const automationHubSetUserCloudAccessToken = SessionHelper.getUserCloudAccessToken();
    if (cloudSetUserCloudAccessToken && automationHubSetUserCloudAccessToken) {
      let cloudTokenJson, ahTokenJson;
      try {
        cloudTokenJson = jwtDecode(cloudSetUserCloudAccessToken);
      } catch (e) {
        console.log(e);
      }
      try {
        ahTokenJson = jwtDecode(automationHubSetUserCloudAccessToken);
      } catch (e) {
        console.log(e);
      }
      // if everything decodes and cloud access token auth_time is after ah cloud access token auth_time
      if (cloudTokenJson?.auth_time > ahTokenJson?.auth_time) {
        // Clear out cloud token and current user so a reauth is triggered
        SessionHelper.clearCurrentUser();
        SessionHelper.clearUserCloudAccessToken();
        // by pushing back to the same page it will detect the user object is missing and trigger a
        // login, after login the user will be redirected to this route
        history.push(location.pathname + location.search);
      }
    }
  };

  return (
    <Container $showNav={showNav}>
      {showNav ? (
        <Nav>
          {/* @ts-ignore */}
          <portal-nav
            compact-mode-override={
              featureFlag.isApolloCompactHeaderOverrideEnabled()
                ? "true"
                : undefined
            }
            id="portalNav"
            url={FPS_BASE_URL || ""}
            account={cloudAccount}
            tenant={cloudTenant}
            active={activeService}
            token={accessToken}
          >
            {/* @ts-ignore */}
          </portal-nav>
        </Nav>
      ) : (
        ""
      )}

      <div>{props.children}</div>
    </Container>
  );
};

export default MasterLayoutWrapper;
