import { PATH } from "../constants/co-path.constants";
import { optionsInContext } from "../helpers/co-context.helper";
import {
  COApplicationInfoItem,
  COContextInterface,
  COProcessExternalDataInterface,
  defaultCurrency
} from "../interfaces/co-interfaces";
import { getValue } from "../utils/co-path.utils";
import { isNullOrUndefined } from "../utils/co-utils";

export interface COProcessExternalData extends COProcessExternalDataInterface {}
export class COProcessExternalData {
  dont_resolve?: boolean = true;
  constructor() {
    this.dont_resolve = true;
    this.process_id = 0;
    this.phase_status_key = undefined;
    this.post_action_phase_status_key = undefined; // this we pop in right before validation depending on the action the user has taken
    this.container_size = {};
    this.application_data = {
      applications: [],
      allApplications: []
    };
    this.category_data = {
      categories: [],
      selected_categories: []
    };
    this.document_data = {
      documents: []
    };
    this.currency = defaultCurrency;
    this.collaborator_data = {
      collaborators: [],
      process_owner: null
    };
    this.media_data = {
      media: []
    };
    this.similar_automations = {
      processes: []
    };
    this.tag_data = {
      tags: []
    };
    this.component_data = {
      components: []
    };
    this.process = undefined;
  }

  updateFromProcess?(data: COProcessExternalDataInterface) {
    let allKeys = [...Object.keys(this), ...Object.keys(data)];
    for (const prop of allKeys) {
      if (!isNullOrUndefined(data[prop])) {
        if (isNullOrUndefined(this[prop])) {
          this[prop] = data[prop];
        } else {
          //merge objects at at least top level for things like application info and stuff
          if (
            typeof data[prop] === "object" &&
            typeof this[prop] === "object"
          ) {
            this[prop] = { ...this[prop], ...data[prop] };
          } else {
            this[prop] = data[prop];
          }
        }
      }
    }
  }

  syncUserPicker?({
    context,
    user,
    external_data_source
  }: {
    context: COContextInterface;
    user: any;
    external_data_source: string;
  }) {
    if (this.collaborator_data) {
      let splitDataSource = external_data_source.split(".");
      if (splitDataSource.length > 2) {
        // todo this should be able to recurse back or down further - generalize
        let propertyKey = splitDataSource[splitDataSource.length - 1];
        let parentObjectKey = splitDataSource[splitDataSource.length - 2];
        //setValue({ context, parentObject: this.collaborator_data });
        this[parentObjectKey][propertyKey] = user;
      }
    }

    if (this.dataSyncUserCallback) {
      this.dataSyncUserCallback({ user, external_data_source });
    }
  }

  syncMedia?(media: any) {
    let mediaItems: COApplicationInfoItem[] = [];
    if (this.media_data) {
      if (Array.isArray(media)) {
        for (const med of media) {
          mediaItems.push(med);
        }
      }
      this.media_data.media = media;
      if (this.dataSyncMediaCallback) {
        this.dataSyncMediaCallback(this.media_data.media);
      }
    }
  }

  syncCollaborators?(collaborators: any[]) {
    if (this.collaborator_data?.collaborators) {
      this.collaborator_data.collaborators = [...(collaborators || [])];
      if (this.dataSyncCollaboratorsProcessCallback) {
        this.dataSyncCollaboratorsProcessCallback(
          this.collaborator_data.collaborators
        );
      }
    }
  }

  syncComponents?(components: any) {
    let items: any[] = [];
    if (this.component_data) {
      if (Array.isArray(components)) {
        for (const component of components) {
          items.push(component);
        }
      }
      this.component_data.components = items;
      if (this.dataSyncComponentsCallback) {
        this.dataSyncComponentsCallback(this.component_data.components);
      }
    }
  }

  syncDocuments?(documents: any) {
    let items: any[] = [];
    if (this.document_data) {
      if (Array.isArray(documents)) {
        for (const item of documents) {
          items.push(item);
        }
      }
      this.document_data.documents = items;
      if (this.dataSyncDocumentsProcessCallback) {
        this.dataSyncDocumentsProcessCallback(this.document_data.documents);
      }
    }
  }

  syncSimilarAutomations?(automations: any) {
    let items: any[] = [];
    if (this.similar_automations) {
      if (Array.isArray(automations)) {
        for (const automation of automations) {
          items.push(automation);
        }
      }
      this.similar_automations.processes = items;
      if (this.dataSyncSimilarAutomationsCallback) {
        this.dataSyncSimilarAutomationsCallback(
          this.similar_automations.processes
        );
      }
    }
  }

  syncApplications?(newApplications: COApplicationInfoItem[]) {
    let applicationItems: COApplicationInfoItem[] = [];
    if (this.application_data) {
      if (Array.isArray(newApplications)) {
        for (const app of newApplications) {
          applicationItems.push(app);
        }
      }

      this.application_data.applications = applicationItems;
      if (this.dataSyncApplicationsProcessCallback) {
        this.dataSyncApplicationsProcessCallback(
          this.application_data.applications
        );
      }
    }
  }

  syncTags?(tags: any) {
    let items: any[] = [];
    if (this.tag_data) {
      if (Array.isArray(tags)) {
        for (const automation of tags) {
          items.push(automation);
        }
      }
      this.tag_data.tags = items;
      if (this.dataSyncTagsCallback) {
        this.dataSyncTagsCallback(this.tag_data.tags);
      }
    }
  }

  syncSelectedCategories?(categories: any[]) {
    let items: any[] = [];
    if (this.category_data) {
      if (Array.isArray(categories)) {
        for (const cat of categories) {
          items.push(cat);
        }
      }
      this.category_data.selected_categories = items;
      if (this.dataSyncCategoryCallback) {
        this.dataSyncCategoryCallback(this.category_data.selected_categories);
      }
    }
  }

  // for if we have questions that let you choose the priority, submission type, development type
  // not hooked up yet
  syncProcessProperty?(updatedData: any) {
    if (this.process) {
      for (const updatedKey of updatedData) {
        this.process[updatedKey] = updatedData[updatedKey];
      }
      if (this.dataSyncProcessPropertyCallback) {
        this.dataSyncProcessPropertyCallback({ updatedData });
      }
    }
  }

  // used in path system
  // used by question answer options to get the value of a external property

  functionExternalPropertyValueForQuestion?(context: COContextInterface) {
    if (context) {
      if (context.question) {
        if (context.question.options?.external_answer_source) {
          let resolvedOptions = optionsInContext({
            context: context,
            options: context.question.options,
            should_resolve: true
          });
          let pathToProperty = resolvedOptions.external_answer_source;
          if (pathToProperty) {
            let qualifedPathToProperty = PATH({ route: pathToProperty });
            let valueForPath = getValue(context, qualifedPathToProperty);
            return valueForPath;
          }
        }
      }
    }
    return undefined;
  }
}
