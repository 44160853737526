import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { createGlobalStyle } from "styled-components/macro";
import styleTheme from "../../constants/styleTheme";
import { AUTOMATION_STORE_SERVICE } from "../../constants/urlConstants";
import { featureFlag } from "../../helpers/FeatureFlag";
import SessionHelper from "../../helpers/SessionHelper";
import urlWithTenant from "../../utils/urlHelper";
import { LogoDivider } from "../NavAuth/NavAuth";
import NavAuthApolloContainer from "../NavAuth/NavAuthApolloContainer";
import ShareIdeaButton from "../NavAuth/ShareIdeaButton/ShareIdeaButton";

const AutomationStoreNavStyle = createGlobalStyle`
  .header {
    background-color: black !important;
 }
`;

const NavAutomationStore = ({
  showSubmitIdeaButton = true
}: {
  showSubmitIdeaButton?: boolean;
}) => {
  const user = SessionHelper.currentUser() || {};
  const history = useHistory();

  const isFpsUri = SessionHelper.isFpsUri();
  const cloudAccessToken = SessionHelper.getUserCloudAccessToken();

  useEffect(() => {
    window.addEventListener("iconClicked", onIconClicked);

    return () => {
      window.removeEventListener("iconClicked", onIconClicked);
    };
  }, []);

  const onIconClicked = () => {
    history.push(
      urlWithTenant("/explore-published-automations", AUTOMATION_STORE_SERVICE)
    );
  };

  const appBarStyles: any = {
    position: "fixed",
    top: 0,
    zIndex: 101,
    left: styleTheme.sizes["fps-side-nav-width"],
    width: `calc(100% - ${styleTheme.sizes["fps-side-nav-width"]})`
  };

  return (
    <div style={appBarStyles}>
      <AutomationStoreNavStyle />
      <NavAuthApolloContainer
        product="Automation Store"
        showTrademark={false}
        hideTenantPicker={isFpsUri ? "false" : "true"}
        isStandalone={isFpsUri ? "false" : "true"}
        hideAvatar={isFpsUri ? "false" : "true"}
        theme={featureFlag.isApolloDarkThemeEnabled() ? undefined : "light"}
        compactModeOverride={
          featureFlag.isApolloCompactHeaderOverrideEnabled()
            ? "true"
            : undefined
        }
        token={cloudAccessToken}
      >
        <div slot="slot-mid" style={{ display: "flex", flexGrow: 1 }}>
          <LogoDivider />
        </div>
        {showSubmitIdeaButton ? (
          <div slot="slot-end" className="large-nav">
            <ShareIdeaButton user={user} isOpen={false} dropRight />
          </div>
        ) : (
          ""
        )}
      </NavAuthApolloContainer>
    </div>
  );
};

export default NavAutomationStore;
