type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export enum COQueueItemState {
  PENDING = 0,
  RUNNING = 1,
  COMPLETED = 2,
  EXPIRED = 3,
  ERROR = 4
}

export enum COQueueFunctions {
  UPDATE_ASSESSMENT_VERSION = "UPDATE_ASSESSMENT_VERSION",
  UPDATE_CATEGORY_ANCESTORS = "UPDATE_CATEGORY_ANCESTORS",
  UPDATE_CALCULATED_VALUE = "UPDATE_CALCULATED_VALUE",
  TASK_REMINDER_EMAIL = "TASK_REMINDER_EMAIL"
}

export class COQueueItemDBClass {
  constructor(
    readonly co_queue_item_uuid?: string,
    readonly co_queue_item_processing_claim_uuid?: string,
    readonly co_queue_item_creation_epoch?: number,
    readonly co_queue_item_minimum_start_epoch?: number,
    readonly co_queue_item_maximum_start_epoch?: number,
    readonly co_queue_item_state?: COQueueItemState,
    readonly co_queue_item_processing_started_epoch?: number,
    readonly co_queue_item_max_processing_time_ms?: number,
    readonly co_queue_item_processing_completed_epoch?: number,
    readonly co_queue_item_processing_error_json?: string,
    readonly co_queue_item_processing_output_json?: string,
    readonly co_queue_item_batch_uuid?: string,
    readonly co_queue_item_batch_item_count?: number,
    readonly co_queue_item_function?: COQueueFunctions | string,
    readonly co_queue_item_function_parameters_json?: string | any,
    readonly co_queue_item_priority?: number,
    readonly co_queue_item_request_json?: string | any
  ) {}
}

export interface COQueueItemInterface extends Writeable<COQueueItemDBClass> {
  co_queue_item_id?: number;
  co_queue_batch_items?: COQueueItemInterface[];
}

export interface COBulkAssessmentParametersInterface {
  from_assessment_ids?: number[];
  to_assessment_id: number;
  process_ids?: number[];
}
