import { COContext } from "../classes/co-context.class";
import { COProcessExternalData } from "../classes/co-process-external-data.class";
import {
  COBaseInterface,
  CODocumentOptionsInterface,
  COExternalCallbackInterface,
  COSwalPopUpInterface,
  COContextInterface
} from "../interfaces/co-interfaces";
import { COModalType } from "../constants/co-constants";

// sets the reference (which can be shared among multiple assessments)
export const setProcessData = (
  context: COContext,
  data: COProcessExternalData
): COContext => {
  context.process_external_data = data;
  return context;
};

// merges external process data
export const updateProcessData = (
  context: COContext,
  data: COProcessExternalData
): COContext => {
  if (!context.process_external_data) {
    context.process_external_data = new COProcessExternalData();
  }
  context.process_external_data?.updateFromProcess?.(data);
  return context;
};

export const setFunctionHooks = ({
  context,
  showModal,
  showSwalPopUp,
  documentSelectPopup,
  openEditViewForProcess
}: {
  context: COContext;
  showModal?: (props: {
    context: any;
    modalType: COModalType;
    payload: any;
  }) => void;
  showSwalPopUp?: (props: {
    context: any;
    co_swal_pop_up: COSwalPopUpInterface;
    callback?: (confirmed: boolean) => void;
  }) => void;
  documentSelectPopup?: (props: {
    context: COContext;
    question?: COBaseInterface;
    documentOptions: CODocumentOptionsInterface;
    callback: Function;
  }) => void;
  openEditViewForProcess?: (props: { context: COContextInterface }) => void;
}) => {
  if (!context.function_hooks) {
    context.function_hooks = {};
  }
  if (showModal) {
    context.function_hooks.showModal = showModal;
  }
  if (showSwalPopUp) {
    context.function_hooks.showSwalPopUp = showSwalPopUp;
  }
  if (documentSelectPopup) {
    context.function_hooks.documentSelectPopup = documentSelectPopup;
  }
  if (openEditViewForProcess) {
    context.function_hooks.openEditViewForProcess = openEditViewForProcess;
  }
  return context;
};

interface COExternalCallbackInterfaceWithContext
  extends COExternalCallbackInterface {
  context: COContext;
}

export const setProcessCallbacks = ({
  context,
  applicationsCallback,
  userPickerCallback,
  documentsCallback,
  mediaCallback,
  processCallback,
  similarAutomationCallback,
  componentsCallback,
  tagsCallback,
  categoriesCallback,
  collaboratorsCallback
}: COExternalCallbackInterfaceWithContext): COContext => {
  if (!context.process_external_data) {
    context.process_external_data = new COProcessExternalData();
  }
  if (applicationsCallback) {
    context.process_external_data.dataSyncApplicationsProcessCallback = applicationsCallback;
  }
  if (documentsCallback) {
    context.process_external_data.dataSyncDocumentsProcessCallback = documentsCallback;
  }
  if (userPickerCallback) {
    context.process_external_data.dataSyncUserCallback = userPickerCallback;
  }
  if (mediaCallback) {
    context.process_external_data.dataSyncMediaCallback = mediaCallback;
  }
  if (processCallback) {
    context.process_external_data.dataSyncProcessPropertyCallback = processCallback;
  }
  if (similarAutomationCallback) {
    context.process_external_data.dataSyncSimilarAutomationsCallback = similarAutomationCallback;
  }
  if (componentsCallback) {
    context.process_external_data.dataSyncComponentsCallback = componentsCallback;
  }
  if (tagsCallback) {
    context.process_external_data.dataSyncTagsCallback = tagsCallback;
  }
  if (categoriesCallback) {
    context.process_external_data.dataSyncCategoryCallback = categoriesCallback;
  }
  if (collaboratorsCallback) {
    context.process_external_data.dataSyncCollaboratorsProcessCallback = collaboratorsCallback;
  }
  return context;
};
