export enum EXTERNAL_CONNECTION_SETTING_NAMES {
  // Jira related settings
  JIRA_SITE_URL = "site_url",
  JIRA_PROJECT = "project",
  JIRA_ISSUE_TYPE = "issue_type",
  JIRA_TEMPLATE = "template",
  JIRA_FIELDS = "fields",
  IS_DISABLED = "is_disabled"
}

export enum EXTERNAL_CONNECTION_CONSTANTS {
  // Jira related constants
  URL_BROWSE = "browse"
}

export enum EXTERNAL_CONNECTION_ERROR_TYPES {
  // Jira related error types
  JIRA_INTEGRATION_FEATURE_FLAG_IS_DISABLED = "integrationFeatureFlagIsDisabled",
  JIRA_CONNECTION_IS_DISABLED = "isDisabled",
  JIRA_NOT_SET_UP = "jiraNotSetUp", // Meaning required settings are not set up or no connection found in DB
  JIRA_CONNECTION_NOT_FOUND_IN_IS = "connectionNotFoundInIS",
  JIRA_CREATE_METADATA_NOT_FOUND = "createMetadataNotFoundInIS",
  PROCESS_NOT_FOUND = "processNotFound",
  JIRA_ISSUE_IS_INVALID = "issueIsInvalid",
  JIRA_CREATE_ISSUE_BAD_REQUEST = "createIssueBadRequest"
}
