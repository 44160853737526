import React from "react";
import { Link } from "react-router-dom";
import { getFullName } from "../../utils/stringParsers";
import "./DropdownCollaborators.scss";

import urlWithTenant from "../../utils/urlHelper";

import LoaderLogoSmall from "../LoaderLogoSmall/LoaderLogoSmall";
import Avatar from "../Avatar/Avatar";

const renderCollaborators = collaborators => {
  if (!collaborators) {
    return <LoaderLogoSmall />;
  }

  return (collaborators || []).map(user => {
    const {
      user_profile_slug,
      user_first_name,
      user_last_name,
      user_roles
    } = user;

    let userRole = "None";
    if (user_roles && user_roles.length > 0) {
      for (const role of user_roles) {
        if (role.role_is_visible) {
          userRole = role.role_name;
          break;
        }
      }
    }

    //user_roles.length > 0 ? user_roles[0].role_name : "None";

    const profilePicture = (
      <Avatar
        user={user}
        className="DropdownCollaborators__profile-pic"
        width="50px"
      />
    );

    return (
      <div
        className="DropdownCollaborators__collaborator"
        key={user_profile_slug}
      >
        {profilePicture}
        <div className="DropdownCollaborators__collaborator-main">
          <Link
            className="DropdownCollaborators__collaborator-name"
            to={urlWithTenant(`/user/${user_profile_slug}`)}
          >
            {getFullName(user_first_name, user_last_name)}
          </Link>
          <span className="DropdownCollaborators__collaborator-job-title">
            {userRole}
          </span>
        </div>
      </div>
    );
  });
};

const DropdownCollaborators = props => {
  let { collaborators } = props;
  const classes = ["DropdownCollaborators"];

  return (
    <div
      className={classes.join(" ")}
      data-testid="DropdownCollaboratorsContainer"
    >
      <div className="DropdownCollaborators__top">
        <h4 className="DropdownCollaborators__header">All Collaborators</h4>
      </div>

      <div className="DropdownCollaborators__main">
        <div className="DropdownCollaborators__collaborators">
          {renderCollaborators(collaborators)}
        </div>
      </div>
    </div>
  );
};

export default DropdownCollaborators;
