import {
  COComponentType,
  CODevelopmentTypes,
  COProcessSubmissionType,
  COConditionSlugs,
  COAssessmentTypes
} from "../../../constants/co-constants";
import {
  COPhaseKeys,
  COPhaseStatusKeys
} from "../../../constants/co-phase-status.constants";
import {
  COCondition,
  COConditionCalculationInterface,
  COConditionOverride,
  COConditionOverrideSlugPayload,
  COVisibilityConditionOperator
} from "../../../interfaces/co-interfaces";
import {
  PATH,
  PATH_OPERATORS,
  PATH_ROUTE
} from "../../../constants/co-path.constants";
import { calculatedValueKey } from "../../../helpers/co-calculated-type.helper";
import { CustomQuestionSlugs } from "../../../constants/co-question.constants";
import { ASSESSMENT_VISIBILITY_HIDDEN_OPTION_VALUE } from "../../../constants/co-question-answer-option.constants";

// ok so the payload can be used in the templates dynamically and stored in the conditional override slugpayload object
export const conditionOverrideFromSlugPayload = (
  slugPayload: COConditionOverrideSlugPayload
): COConditionOverride => {
  let payload = slugPayload.payload;
  let slug = slugPayload.slug;
  switch (slug) {
    case COConditionSlugs.HIDDEN: {
      return {
        slug,
        meta: {
          value: "Hide everywhere",
          value_localization_key: "co_visibility_option_hidden"
        },
        priority: 100,
        add_condition_override_slugs_when_selected: [],
        add_condition_override_slugs_when_deselected: [],
        remove_condition_override_slugs_when_selected: [
          { slug: COConditionSlugs.SECTION_HIDDEN_FOR_SUBMIT },
          { slug: COConditionSlugs.QUESTION_HIDDEN_FOR_SUBMIT },
          { slug: COConditionSlugs.HIDE_FROM_PROFILE },
          { slug: COConditionSlugs.HIDE_FROM_PIPELINE },
          { slug: COConditionSlugs.SECTION_SHOWN_AFTER_LIVE },
          { slug: COConditionSlugs.QUESTION_SHOWN_AFTER_LIVE },
          { slug: COConditionSlugs.SIDEBAR },
          { slug: COConditionSlugs.EDITABLE_PRE_PUBLISH }
        ],
        remove_condition_override_slugs_when_deselected: [],
        condition: {
          display_context: {
            // everything except for customize
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1,
            all_pipelines: 1
          },
          or_conditions: [],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.QUESTION_HIDDEN_FOR_SUBMIT: {
      return {
        slug,
        meta: {
          value: "Hide from submission forms",
          value_localization_key: "co_visibility_option_hide_for_submit",
          tooltip:
            "Question still appears on the automation profile, just not during submission.",
          tooltip_localization_key:
            "co_visibility_option_hide_question_for_submit_tooltip"
        },
        remove_condition_override_slugs_when_selected: [
          { slug: COConditionSlugs.HIDDEN }
        ],
        priority: 70,
        condition: {
          display_context: {
            assessment_submission: 1
          },
          or_conditions: [phaseLessOrEqualTo(COPhaseKeys.INITIAL)],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.SECTION_HIDDEN_FOR_SUBMIT: {
      return {
        slug,
        meta: {
          value: "Hide from submission forms",
          value_localization_key: "co_visibility_option_hide_for_submit",
          tooltip:
            "Section still appears on the automation profile, just not during submission.",
          tooltip_localization_key:
            "co_visibility_option_hide_section_for_submit_tooltip"
        },
        remove_condition_override_slugs_when_selected: [
          { slug: COConditionSlugs.HIDDEN }
        ],
        priority: 70,
        condition: {
          display_context: {
            assessment_submission: 1
          },
          or_conditions: [phaseLessOrEqualTo(COPhaseKeys.INITIAL)],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.SUBMISSION_FOR_TASK_AND_PROCESS_MINING_ONLY: {
      return {
        slug,
        meta: {
          value:
            "During submission, this is available for Task Mining and Process Mining only"
        },
        priority: 60,
        condition: {
          display_context: {
            assessment_submission: 1
          },
          or_conditions: [
            {
              desired_value: COProcessSubmissionType.PROCESS_MINING + "",
              operator: COVisibilityConditionOperator.NOT_EQUALS,
              process_column_name: "process_submission_type",
              and_conditions: [
                {
                  desired_value: COProcessSubmissionType.TASK_MINING + "",
                  operator: COVisibilityConditionOperator.NOT_EQUALS,
                  process_column_name: "process_submission_type"
                },
                phaseLessOrEqualTo(COPhaseKeys.INITIAL)
              ]
            }
          ],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.HIDE_FOR_EDIT: {
      return {
        slug,
        meta: {
          value: "Hide from editing profile",
          value_localization_key: "co_visibility_option_hide_for_edit"
        },
        remove_condition_override_slugs_when_selected: [
          { slug: COConditionSlugs.HIDDEN }
        ],
        condition: {
          display_context: {
            assessment_submission: 1
          },
          or_conditions: [phaseGreaterOrEqualTo(COPhaseKeys.IDEA)],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.HIDE_FROM_PROFILE: {
      return {
        slug,
        meta: {
          value: "Hide from About page",
          value_localization_key: "co_visibility_option_hide_from_profile",
          tooltip:
            "Question appears in the header or on one of the other profile pages: Documentation, Components, Collaborators.",
          tooltip_localization_key:
            "co_visibility_option_hide_from_profile_tooltip"
        },
        priority: 50,
        remove_condition_override_slugs_when_selected: [
          { slug: COConditionSlugs.HIDDEN }
        ],
        condition: {
          display_context: {
            assessment_view: 1
          },
          or_conditions: [],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }

    case COConditionSlugs.HIDE_FROM_PIPELINE: {
      return {
        slug,
        meta: {
          value: "Hide from Automation Pipeline",
          value_localization_key: "co_visibility_option_hide_from_pipleline",
          tooltip:
            "Question is not displayed in the Columns list of the Automation Pipeline table.",
          tooltip_localization_key:
            "co_visibility_option_hide_from_pipleline_tooltip"
        },
        remove_condition_override_slugs_when_selected: [
          { slug: COConditionSlugs.HIDDEN }
        ],
        priority: 50,
        condition: {
          display_context: {
            all_pipelines: 1
          },
          or_conditions: [],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.OVERRIDEN_BY_ANOTHER_COLUMN_IN_PIPELINE: {
      return {
        slug,
        meta: {
          value:
            "This question has another column in the pipeline that replaces it because that column shows this question's value better"
        },
        remove_condition_override_slugs_when_selected: [
          { slug: COConditionSlugs.HIDDEN }
        ],
        priority: 100,
        condition: {
          display_context: {
            all_pipelines: 1
          },
          or_conditions: [],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.QUESTION_SHOWN_AFTER_LIVE: {
      return {
        slug,
        meta: {
          value: "Hide before Live phase",
          value_localization_key: "co_visibility_option_after_live",
          tooltip:
            "Question is displayed in the automation profile only in the Live phase.",
          tooltip_localization_key:
            "co_visibility_option_question_after_live_tooltip"
        },
        remove_condition_override_slugs_when_selected: [
          { slug: COConditionSlugs.HIDDEN }
        ],
        priority: 50,
        condition: {
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1
          },
          or_conditions: [phaseLessOrEqualTo(COPhaseKeys.TESTING)],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.SECTION_SHOWN_AFTER_LIVE: {
      return {
        slug,
        meta: {
          value: "Hide before Live phase",
          value_localization_key: "co_visibility_option_after_live",
          tooltip:
            "Section is displayed in the automation profile only in the Live phase.",
          tooltip_localization_key:
            "co_visibility_option_section_after_live_tooltip"
        },
        remove_condition_override_slugs_when_selected: [
          { slug: COConditionSlugs.HIDDEN }
        ],
        priority: 50,
        condition: {
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1
          },
          or_conditions: [phaseLessOrEqualTo(COPhaseKeys.TESTING)],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.SIDEBAR: {
      return {
        slug,
        meta: {
          value: "Visible in the profile sidebar",
          value_localization_key: "co_visibility_option_visible_in_sidebar",
          tooltip:
            "Section is displayed in the automation profile sidebar instead of inline with the other sections.",
          tooltip_localization_key:
            "co_visibility_option_visible_in_sidebar_tooltip"
        },
        remove_condition_override_slugs_when_selected: [
          { slug: COConditionSlugs.HIDDEN }
        ],
        priority: 45,
        conditions: [
          {
            display_context: {
              assessment_view: 1
            },
            overrides: {
              is_hidden: 1
            }
          },
          {
            display_context: {
              assessment_view_sidebar: 1 // for view and side bar - we want to always hide it. except if it's the sidebar
            },
            overrides: {
              is_hidden: 0
            }
          }
        ]
      };
    }
    case COConditionSlugs.EDITABLE_PRE_PUBLISH: {
      return {
        slug: slug,
        meta: {
          value: "Visible before publishing to Store",
          value_localization_key: "co_visibility_option_edit_pre_publish",
          tooltip:
            "Questions in this section can also be answered during the publish to Automation Store flow.",
          tooltip_localization_key:
            "co_visibility_option_edit_pre_publish_tooltip"
        },
        remove_condition_override_slugs_when_selected: [
          { slug: COConditionSlugs.HIDDEN }
        ],
        priority: 65, // needs to be higher the hide after submit
        condition: {
          display_context: {
            assessment_submission_sidebar: 1
          },
          overrides: {
            is_hidden: 0
          },
          or_conditions: [phaseGreaterOrEqualTo(COPhaseKeys.LIVE)]
        }
      };
    }
    case COConditionSlugs.DISABLE_IF_LEGACY_ANSWER_SELECTED: {
      return {
        slug,

        condition: {
          overrides: {
            is_disabled: 1
          },
          display_context: {
            assessment_submission: 1
          },
          or_conditions: [
            {
              desired_value: payload?.legacy_slugs?.join(",") || "",
              operator: COVisibilityConditionOperator.HAS_COMMON_ELEMENT,
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.PROCESS_ANSWER
                    .FUNCTION_SELECTED_ANSWER_OPTION_LEGACY_SLUGS,
                operator: PATH_OPERATORS.FUNCTION
              })
            }
          ]
        }
      };
    }
    case COConditionSlugs.DISABLE_IF_LEGACY_ANSWER_SELECTED_EXCEPT: {
      return {
        slug,
        condition: {
          overrides: {
            is_disabled: 1
          },
          display_context: {
            assessment_submission: 1
          },
          or_conditions: [
            {
              // has to be non empty (as in answered)
              desired_value: "",
              operator: COVisibilityConditionOperator.NOT_EQUALS,
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.PROCESS_ANSWER
                    .FUNCTION_SELECTED_ANSWER_OPTION_LEGACY_SLUGS,
                operator: PATH_OPERATORS.FUNCTION
              }),
              and_conditions: [
                {
                  // and has to not be one of the things passed in
                  desired_value: payload?.legacy_slugs?.join(",") || "", // TODO exception if payload missing?
                  operator: COVisibilityConditionOperator.HAS_NO_COMMON_ELEMENT,
                  current_value: PATH({
                    route:
                      PATH_ROUTE.CONTEXT.PROCESS_ANSWER
                        .FUNCTION_SELECTED_ANSWER_OPTION_LEGACY_SLUGS,
                    operator: PATH_OPERATORS.FUNCTION
                  })
                }
              ]
            }
          ]
        }
      };
    }
    case COConditionSlugs.Q1_VISIBILITY: {
      return {
        slug,
        priority: 50,
        meta: { value: "High Level Assessment Visibility" },
        condition: {
          overrides: {
            is_hidden: 1
          },
          or_conditions: [
            {
              desired_value: COProcessSubmissionType.EMPLOYEE_DRIVEN + "",
              operator: COVisibilityConditionOperator.NOT_EQUALS,
              process_column_name: "process_submission_type"
            }
          ]
        }
      };
    }
    case COConditionSlugs.Q2_VISIBILITY: {
      return {
        slug,
        meta: { value: "The Detailed Assessment Visibility" },
        priority: 50,

        condition: {
          overrides: {
            is_hidden: 1
          },
          or_conditions: [
            {
              desired_value: COProcessSubmissionType.EMPLOYEE_DRIVEN + "", // hide if employee driven submit
              operator: COVisibilityConditionOperator.EQUALS,
              process_column_name: "process_submission_type",
              and_conditions: [phaseLessOrEqualTo(COPhaseKeys.IDEA)]
            },
            {
              desired_value:
                COProcessSubmissionType.CITIZEN_DEVELOPMENT_AUTOMATION + "", // hide if Citizen dev
              operator: COVisibilityConditionOperator.EQUALS,
              process_column_name: "process_submission_type",
              and_conditions: [
                {
                  desired_value: CODevelopmentTypes.COE + "", // except if the development type is COE
                  operator: COVisibilityConditionOperator.NOT_EQUALS,
                  process_column_name: "process_development_type",
                  and_conditions: []
                }
              ]
            }
          ]
        }
      };
    }
    case COConditionSlugs.Q3_VISIBILITY: {
      return {
        slug,
        meta: { value: "Citizen Developer Visibility" },
        priority: 50,
        condition: {
          or_conditions: [
            {
              desired_value:
                COProcessSubmissionType.CITIZEN_DEVELOPMENT_AUTOMATION + "",
              operator: COVisibilityConditionOperator.NOT_EQUALS,
              process_column_name: "process_submission_type",
              and_conditions: []
            }
          ],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.CR_VISIBILITY: {
      return {
        slug,
        meta: { value: "Change Request Assessment Visibility" },
        priority: 50,
        condition: {
          or_conditions: [
            {
              desired_value: COProcessSubmissionType.CHANGE_REQUEST + "",
              operator: COVisibilityConditionOperator.NOT_EQUALS,
              process_column_name: "process_submission_type",
              and_conditions: []
            }
          ],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.Q2_REQUIRED_OVERRIDES: {
      return {
        slug,
        meta: {
          value: "Soft Validation if Detailed Assessment isn't being submitted"
        },
        priority: 50,
        condition: {
          or_conditions: [
            {
              // it's top down and initial submission
              desired_value: COProcessSubmissionType.TOP_DOWN_SUBMISSION + "",
              operator: COVisibilityConditionOperator.EQUALS,
              process_column_name: "process_submission_type",
              and_conditions: [phaseLessOrEqualTo(COPhaseKeys.INITIAL)]
            },
            {
              // it's about to go do QUALIFICATION_AWAITING_REVIEW (via the submit action)
              desired_value:
                COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW + "",
              operator: COVisibilityConditionOperator.EQUALS,
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA
                    .POST_ACTION_PHASE_STATUS_KEY
              })
            }
          ],
          overrides: {
            optional_for_submission: 0
          }
        }
      };
    }
    case COConditionSlugs.DONT_SHOW_IN_SIDEBAR: {
      return {
        slug,
        meta: { value: "Don't Show in Sidebar" },
        priority: 2, // this is overrdden by show in sidebar
        condition: {
          display_context: {
            assessment_view_sidebar: 1,
            assessment_submission_sidebar: 1
          },
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.HIDE_QUESTION_IF_NOT_ANSWERED_IN_VIEW_CONTEXT: {
      return {
        slug,
        meta: { value: "Hide Unanswered Questions in View" },
        priority: 50,
        condition: {
          display_context: {
            assessment_view: 1
          },
          or_conditions: [
            {
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.QUESTION.FUNCTION_QUESTION_IS_ANSWERED,
                operator: PATH_OPERATORS.FUNCTION
              }),
              operator: COVisibilityConditionOperator.NOT_EQUALS,
              desired_value: true
            }
          ],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.HIDE_KPI_IF_NOT_CALCULATED_IN_VIEW_CONTEXT: {
      return {
        slug,
        meta: {
          value: "Hide KPI if it hasn't been calculated yet"
        },
        priority: 50,
        condition: {
          display_context: {
            assessment_view: 1
          },
          or_conditions: [
            {
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.QUESTION.CO_PROCESS_CALCULATED_VALUE
                    .CO_CALCULATED_VALUE
              }),
              operator: COVisibilityConditionOperator.NOT_EXISTS
            }
          ],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.HIDE_IF_PATH_ROUTE: {
      return {
        slug,
        priority: 100,
        condition: {
          overrides: {
            is_hidden: 1
          },
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1,
            all_pipelines: 1
          },
          or_conditions: [
            {
              desired_value: payload?.desired_value || 0,
              operator: COVisibilityConditionOperator.EQUALS,
              current_value: PATH({
                route: payload?.pathRoute || ""
              })
            }
          ]
        }
      };
    }
    case COConditionSlugs.HIDE_IF_NO_QUESTIONS_VISIBLE_IN_SECTION: {
      return {
        slug,
        meta: {
          value: "Hide if No Questions Visible In Section"
        },
        priority: 100, // needs to be higher the the sidebar stuff

        condition: {
          display_context: {
            assessment_view: 1,
            assessment_submission: 1,
            assessment_view_sidebar: 1
          },
          overrides: {
            is_hidden: 1
          },
          or_conditions: [
            {
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.SECTION.FUNCTION_SECTION_VISIBLE_QUESTIONS,
                operator: PATH_OPERATORS.FUNCTION
              }),
              operator: COVisibilityConditionOperator.EQUALS,
              desired_value: 0
            }
          ]
        }
      };
    }
    case COConditionSlugs.HIDE_IF_NO_QUESTIONS_VISIBLE_IN_ASSESSMENT: {
      return {
        slug,
        meta: {
          value: "Hide if No Questions Visible In Assessment"
        },
        priority: 100, // needs to be higher the the sidebar stuff

        condition: {
          display_context: {
            assessment_view: 1,
            assessment_submission: 1,
            assessment_view_sidebar: 1
          },
          overrides: {
            is_hidden: 1
          },
          or_conditions: [
            {
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.ASSESSMENT
                    .FUNCTION_ASSESSMENT_VISIBLE_SECTIONS,
                operator: PATH_OPERATORS.FUNCTION
              }),
              operator: COVisibilityConditionOperator.EQUALS,
              desired_value: 0
            }
          ]
        }
      };
    }
    case COConditionSlugs.DONT_HIDE_IF_NO_QUESTIONS_VISIBLE_IN_ASSESSMENT: {
      return {
        slug,
        meta: {
          value:
            "Don't Hide if No Questions Visible In Assessment - Overrides the Default One"
        },
        priority: 101, // needs to be higher the HIDE_IF_NO_QUESTIONS_VISIBLE_IN_ASSESSMENT
        condition: {
          display_context: {
            assessment_view: 1,
            assessment_submission: 1,
            assessment_view_sidebar: 1
          },
          overrides: {
            is_hidden: 0
          },
          or_conditions: [
            {
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.ASSESSMENT
                    .FUNCTION_ASSESSMENT_VISIBLE_SECTIONS,
                operator: PATH_OPERATORS.FUNCTION
              }),
              operator: COVisibilityConditionOperator.EQUALS,
              desired_value: 0
            }
          ]
        }
      };
    }
    case COConditionSlugs.REQUIRED_FOR_PHASE_STATUS_CHANGE: {
      return {
        slug,
        meta: {
          value: "Make Required = 1 for Phase Status Change"
        },
        priority: 60, // needs to be higher the hide after submit // because we are unhiding it during
        condition: {
          display_context: {
            assessment_submission: 1
          },
          overrides: {
            optional_for_submission: 0,
            is_hidden: 0 // the validator won't validate hidden things
          },
          or_conditions: [
            {
              current_value: PATH({
                route: PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA.PHASE_STATUS_KEY
              }),
              operator: COVisibilityConditionOperator.EQUALS,
              desired_value: payload?.phase_status_key,
              and_conditions: [
                {
                  current_value: PATH({
                    route:
                      PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA
                        .POST_ACTION_PHASE_STATUS_KEY
                  }),
                  operator: COVisibilityConditionOperator.EQUALS,
                  desired_value: payload?.next_phase_status_key
                }
              ]
            }
          ]
        }
      };
    }
    case COConditionSlugs.REQUIRED_FOR_NEXT_PHASE_STATUS_CHANGE: {
      return {
        slug,
        meta: {
          value: "Make Required = 1 for Phase Next Status Change"
        },
        priority: 60, // needs to be higher the hide after submit // because we are unhiding it during
        condition: {
          display_context: {
            assessment_submission: 1
          },
          overrides: {
            optional_for_submission: 0,
            is_hidden: 0 // the validator won't validate hidden things
          },
          or_conditions: [
            {
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA
                    .POST_ACTION_PHASE_STATUS_KEY
              }),
              operator: COVisibilityConditionOperator.EQUALS,
              desired_value: payload?.next_phase_status_key
            }
          ]
        }
      };
    }
    case COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE: {
      return {
        slug,
        meta: {
          value: "Inline",
          value_localization_key: "co_control_inline",
          tooltip:
            "Question, answers, and comments appear on the page vertically stacked.",
          tooltip_localization_key: "co_control_inline_tooltip"
        },
        priority: 100,
        condition: {
          overrides: {
            component_type: COComponentType.QUESTION_STANDALONE
          }
        }
      };
    }
    case COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE: {
      return {
        slug,
        meta: {
          value: "Table",
          value_localization_key: "co_control_table",
          tooltip:
            "Question, answers, and comments appear adjacent to each other.",
          tooltip_localization_key: "co_control_table_tooltip"
        },
        condition: {
          overrides: {
            component_type: COComponentType.QUESTION_TABLE_ROW
          }
        }
      };
    }
    case COConditionSlugs.QUESTION_NOT_REQUIRED: {
      return {
        slug,
        meta: {
          value: "Not Required"
        },
        condition: {
          overrides: {
            optional_for_submission: 1
          }
        }
      };
    }
    case COConditionSlugs.QUESTION_IS_REQUIRED: {
      return {
        slug,
        meta: {
          value: "Required",
          value_localization_key: "co_control_required_title",
          tooltip:
            "When turned off, question is optional for assessment submission",
          tooltip_localization_key: "co_control_required_tooltip"
        },
        priority: 100,
        condition: {
          overrides: {
            optional_for_submission: 0
          }
        }
      };
    }
    case COConditionSlugs.QUESTION_DOES_NOT_IMPACT_KPI: {
      return {
        slug,
        meta: {
          value: "This question does not Impact KPIs",
          value_localization_key: "co_control_does_not_impact_kpi_title"
        },
        condition: {
          overrides: {
            can_impact_kpi: 0
          }
        }
      };
    }
    case COConditionSlugs.QUESTION_CAN_IMPACT_KPI: {
      return {
        slug,
        meta: {
          value: "Can Impact KPI",
          value_localization_key: "co_control_impact_kpi_title",
          tooltip:
            "When turned off, the question can not be used in a KPI and has no Answer Values",
          tooltip_localization_key: "co_control_impact_kpi_tooltip"
        },
        priority: 100,
        condition: {
          overrides: {
            can_impact_kpi: 1
          }
        }
      };
    }
    case COConditionSlugs.QUESTION_HIDES_COMMENTS_SECTION: {
      return {
        slug,
        meta: {
          value: "Hides comments section",
          value_localization_key: "co_control_hide_comments_section"
        },
        condition: {
          overrides: {
            hide_comments_section: 1
          }
        }
      };
    }
    case COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION: {
      return {
        slug,
        meta: {
          value: "Show comments section",
          value_localization_key: "co_control_show_comments_title"
        },
        priority: 100,
        condition: {
          overrides: {
            hide_comments_section: 0
          }
        }
      };
    }
    case COConditionSlugs.QUESTION_HAS_PREFILL_ANSWERS: {
      return {
        slug,
        meta: {
          value: "Pre-fill answers",
          value_localization_key: "co_control_prefill_questions_title",
          tooltip:
            "Adding default values to questions makes it easier to fill in the form in case of questions with standard answers. It also benefits the KPIs recalculations when applying a new assessment version to existing ideas",
          tooltip_localization_key: "co_control_prefill_questions_tooltip"
        },
        condition: {
          overrides: {
            pre_fill_default_answer: 1
          }
        }
      };
    }
    case COConditionSlugs.ASSESSMENT_NOT_OPTIONAL_DURING_INITIAL_PHASE: {
      return {
        slug,
        meta: {
          value: "During submission, this assessment should be not be optional",
          value_localization_key:
            "co_control_assessment_not_optional_during_initial"
        },
        priority: 100,
        condition: {
          or_conditions: [phaseLessOrEqualTo(COPhaseKeys.INITIAL)],
          overrides: {
            optional_for_submission: 0,
            optional_for_submission_with_warning: 0
          }
        }
      };
    }
    case COConditionSlugs.ASSESSMENT_NOT_OPTIONAL: {
      return {
        slug,
        meta: {
          value: "This assessment never be optional when submitting/saving",
          value_localization_key: "co_control_assessment_not_optional"
        },
        priority: 50,
        condition: {
          overrides: {
            optional_for_submission: 0,
            optional_for_submission_with_warning: 0
          }
        }
      };
    }
    case COConditionSlugs.ASSESSMENT_OPTIONAL_WITH_WARNING_WHEN_SAVING: {
      return {
        slug,
        meta: {
          value:
            "This assessment is optional and will show a warning when saving",
          value_localization_key: "co_control_assessment_optional_with_warning"
        },
        priority: 25,
        condition: {
          overrides: {
            optional_for_submission: 1,
            optional_for_submission_with_warning: 1
          }
        }
      };
    }
    case COConditionSlugs.ASSESSMENT_OPTIONAL_WITHOUT_WARNING_BEFORE_QUALIFICATION: {
      return {
        slug,
        meta: {
          value:
            "Assessment not optional without the warning pop-up if the process is before the Qualification phase",
          value_localization_key:
            "co_control_assessment_optional_without_warning_before_qualification"
        },
        priority: 75,
        condition: {
          or_conditions: [phaseLessOrEqualTo(COPhaseKeys.ASSESSMENT)],
          overrides: {
            optional_for_submission: 1,
            optional_for_submission_with_warning: 0
          }
        }
      };
    }
    case COConditionSlugs.ASSESSMENT_NOT_OPTIONAL_DURING_MARK_AS_COMPLETE: {
      return {
        slug,
        meta: {
          value:
            "When the process is being marked as complete, the assessment is not optional",
          value_localization_key:
            "co_control_assessment_not_optional_during_mark_completed"
        },
        priority: 100,
        condition: {
          or_conditions: [
            {
              // it's about to go do QUALIFICATION_AWAITING_REVIEW (via the submit action)
              desired_value:
                COPhaseStatusKeys.QUALIFICATION_AWAITING_REVIEW + "",
              operator: COVisibilityConditionOperator.EQUALS,
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA
                    .POST_ACTION_PHASE_STATUS_KEY
              })
            }
          ],
          overrides: {
            optional_for_submission: 0,
            optional_for_submission_with_warning: 0
          }
        }
      };
    }
    case COConditionSlugs.ASSESSMENT_NOT_OPTIONAL_DURING_IDEA_APPROVAL: {
      return {
        slug,
        meta: {
          value:
            "When the process is being approved as an idea, the assessment is not optional",
          value_localization_key:
            "co_control_assessment_not_optional_during_idea_approval"
        },
        priority: 100,
        condition: {
          or_conditions: [
            {
              // it's about to go to ASSESSMENT_NOT_STARTED (via the approve action)
              desired_value: COPhaseStatusKeys.ASSESSMENT_NOT_STARTED + "",
              operator: COVisibilityConditionOperator.EQUALS,
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA
                    .POST_ACTION_PHASE_STATUS_KEY
              })
            }
          ],
          overrides: {
            optional_for_submission: 0,
            optional_for_submission_with_warning: 0
          }
        }
      };
    }
    case COConditionSlugs.BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_SPECIFIC_OPTION: {
      return {
        slug,
        meta: {
          value: "Hide if question not answered with specific option"
        },
        condition: {
          overrides: {
            is_hidden: 1
          },
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1
          },
          or_conditions: [
            // The answer doesn't exist
            {
              co_question_ahid: payload?.co_question_ahid || "",
              operator: COVisibilityConditionOperator.NOT_EXISTS
            },
            // The answer does exist but it's not the right one
            {
              co_question_ahid: payload?.co_question_ahid || "",
              operator: COVisibilityConditionOperator.EXISTS,
              and_conditions: [
                {
                  current_value: PATH({
                    route:
                      PATH_ROUTE.CONTEXT.ASSESSMENT
                        .FUNCTION_SELECTED_ANSWER_OPTION_AHID_FOR_QUESTION,
                    operator: PATH_OPERATORS.FUNCTION,
                    operatorParams: {
                      co_question_ahid: payload?.co_question_ahid
                    }
                  }),
                  operator: COVisibilityConditionOperator.NOT_EQUALS,
                  desired_value: payload?.co_question_answer_option_ahid
                }
              ]
            }
          ]
        }
      };
    }
    case COConditionSlugs.BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS: {
      return {
        slug,
        meta: {
          value:
            "Hide if question not answered with one of the provided options"
        },
        condition: {
          overrides: {
            is_hidden: 1
          },
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1
          },
          or_conditions: [
            // The answer doesn't exist
            {
              co_question_ahid: payload?.co_question_ahid || "",
              operator: COVisibilityConditionOperator.NOT_EXISTS
            },
            // The answer does exist but it's not one of the right ones
            {
              co_question_ahid: payload?.co_question_ahid || "",
              operator: COVisibilityConditionOperator.EXISTS,
              and_conditions: [
                {
                  current_value: PATH({
                    route:
                      PATH_ROUTE.CONTEXT.ASSESSMENT
                        .FUNCTION_SELECTED_ANSWER_OPTION_AHID_FOR_QUESTION,
                    operator: PATH_OPERATORS.FUNCTION,
                    operatorParams: {
                      co_question_ahid: payload?.co_question_ahid
                    }
                  }),
                  operator: COVisibilityConditionOperator.HAS_NO_COMMON_ELEMENT,
                  desired_value: payload?.co_question_answer_option_ahid
                }
              ]
            }
          ]
        }
      };
    }
    case COConditionSlugs.HIDE_ASSESSMENT_DURING_CHANGE_REQUEST: {
      return {
        slug,
        meta: {
          value: "Hide Assessment during Change Request"
        },
        priority: 60,
        condition: {
          overrides: {
            is_hidden: 1
          },
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1
          },
          or_conditions: [
            {
              desired_value: COProcessSubmissionType.CHANGE_REQUEST + "",
              operator: COVisibilityConditionOperator.EQUALS,
              process_column_name: "process_submission_type"
            }
          ]
        }
      };
    }
    case COConditionSlugs.SHOW_DURING_CR_BASED_ON_Q1_ASSESSMENT_VISIBILITY_QUESTION: {
      return {
        slug,
        meta: {
          value:
            "Show if the Q1 assessment visibility question has a specific answer (or no answer)"
        },
        priority: 75,
        condition: {
          overrides: {
            is_hidden: 0
          },
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1
          },
          or_conditions: [
            // Question is answered with a value more than 0 (0 is Hidden)
            {
              operator: COVisibilityConditionOperator.EXISTS,
              co_calculated_value_key: calculatedValueKey({
                co_assessment_type:
                  COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE,
                co_variable_name: CustomQuestionSlugs.CR_HLA_PROCESS_OPTIONS
              }),
              and_conditions: [
                {
                  operator: COVisibilityConditionOperator.NOT_EQUALS,
                  co_calculated_value_key: calculatedValueKey({
                    co_assessment_type:
                      COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE,
                    co_variable_name: CustomQuestionSlugs.CR_HLA_PROCESS_OPTIONS
                  }),
                  desired_value: ASSESSMENT_VISIBILITY_HIDDEN_OPTION_VALUE
                },
                {
                  desired_value: COProcessSubmissionType.CHANGE_REQUEST + "",
                  operator: COVisibilityConditionOperator.EQUALS,
                  process_column_name: "process_submission_type"
                }
              ]
            }
          ]
        }
      };
    }
    case COConditionSlugs.SHOW_DURING_CR_BASED_ON_Q2_ASSESSMENT_VISIBILITY_QUESTION: {
      return {
        slug,
        meta: {
          value:
            "Show if the Q2 assessment visibility question has a specific answer (or no answer)"
        },
        priority: 75,
        condition: {
          overrides: {
            is_hidden: 0
          },
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1
          },
          or_conditions: [
            // Question is answered with a value more than 0 (0 is Hidden)
            {
              operator: COVisibilityConditionOperator.EXISTS,
              co_calculated_value_key: calculatedValueKey({
                co_assessment_type:
                  COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE,
                co_variable_name: CustomQuestionSlugs.CR_DA_PROCESS_OPTIONS
              }),
              and_conditions: [
                {
                  operator: COVisibilityConditionOperator.NOT_EQUALS,
                  co_calculated_value_key: calculatedValueKey({
                    co_assessment_type:
                      COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE,
                    co_variable_name: CustomQuestionSlugs.CR_DA_PROCESS_OPTIONS
                  }),
                  desired_value: ASSESSMENT_VISIBILITY_HIDDEN_OPTION_VALUE
                },
                {
                  desired_value: COProcessSubmissionType.CHANGE_REQUEST + "",
                  operator: COVisibilityConditionOperator.EQUALS,
                  process_column_name: "process_submission_type"
                }
              ]
            }
          ]
        }
      };
    }
    case COConditionSlugs.SHOW_DURING_CR_BASED_ON_Q3_ASSESSMENT_VISIBILITY_QUESTION: {
      return {
        slug,
        meta: {
          value:
            "Show if the Q3 assessment visibility question has a specific answer (or no answer)"
        },
        priority: 75,
        condition: {
          overrides: {
            is_hidden: 0
          },
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1
          },
          or_conditions: [
            // Question is answered with a value more than 0 (0 is Hidden)
            {
              operator: COVisibilityConditionOperator.EXISTS,
              co_calculated_value_key: calculatedValueKey({
                co_assessment_type:
                  COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE,
                co_variable_name: CustomQuestionSlugs.CR_CDA_PROCESS_OPTIONS
              }),
              and_conditions: [
                {
                  operator: COVisibilityConditionOperator.NOT_EQUALS,
                  co_calculated_value_key: calculatedValueKey({
                    co_assessment_type:
                      COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE,
                    co_variable_name: CustomQuestionSlugs.CR_CDA_PROCESS_OPTIONS
                  }),
                  desired_value: ASSESSMENT_VISIBILITY_HIDDEN_OPTION_VALUE
                },
                {
                  desired_value: COProcessSubmissionType.CHANGE_REQUEST + "",
                  operator: COVisibilityConditionOperator.EQUALS,
                  process_column_name: "process_submission_type"
                }
              ]
            }
          ]
        }
      };
    }
    case COConditionSlugs.SHOW_DURING_CR_BASED_ON_MISC_ASSESSMENT_VISIBILITY_QUESTION: {
      return {
        slug,
        meta: {
          value:
            "Show if the MISC assessment visibility question has a specific answer (or no answer)"
        },
        priority: 75,
        condition: {
          overrides: {
            is_hidden: 0
          },
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1
          },
          or_conditions: [
            // Question is answered with a value more than 0 (0 is Hidden)
            {
              operator: COVisibilityConditionOperator.EXISTS,
              co_calculated_value_key: calculatedValueKey({
                co_assessment_type:
                  COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE,
                co_variable_name: CustomQuestionSlugs.CR_MISC_PROCESS_OPTIONS
              }),
              and_conditions: [
                {
                  operator: COVisibilityConditionOperator.NOT_EQUALS,
                  co_calculated_value_key: calculatedValueKey({
                    co_assessment_type:
                      COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE,
                    co_variable_name:
                      CustomQuestionSlugs.CR_MISC_PROCESS_OPTIONS
                  }),
                  desired_value: ASSESSMENT_VISIBILITY_HIDDEN_OPTION_VALUE
                },
                {
                  desired_value: COProcessSubmissionType.CHANGE_REQUEST + "",
                  operator: COVisibilityConditionOperator.EQUALS,
                  process_column_name: "process_submission_type"
                }
              ]
            }
          ]
        }
      };
    }
    case COConditionSlugs.CR_Q1_BUTTON_VISIBILITY: {
      return {
        slug,
        meta: {
          value: "Visibility for the Q1 button during a Change Request"
        },
        priority: 75,
        condition: {
          overrides: {
            is_hidden: 1
          },
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1
          },
          or_conditions: [
            {
              desired_value: COProcessSubmissionType.EMPLOYEE_DRIVEN + "",
              operator: COVisibilityConditionOperator.NOT_EQUALS,
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA.PROCESS
                    .PARENT_PROCESS.PROCESS_SUBMISSION_TYPE
              })
            }
          ]
        }
      };
    }
    case COConditionSlugs.CR_Q3_BUTTON_VISIBILITY: {
      return {
        slug,
        meta: {
          value: "Visibility for the Q3 button during a Change Request"
        },
        priority: 75,
        condition: {
          overrides: {
            is_hidden: 1
          },
          display_context: {
            assessment_submission: 1,
            assessment_view: 1,
            assessment_view_sidebar: 1
          },
          or_conditions: [
            {
              desired_value:
                COProcessSubmissionType.CITIZEN_DEVELOPMENT_AUTOMATION + "",
              operator: COVisibilityConditionOperator.NOT_EQUALS,
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA.PROCESS
                    .PARENT_PROCESS.PROCESS_SUBMISSION_TYPE
              })
            }
          ]
        }
      };
    }
    case COConditionSlugs.SHOW_WHEN_ANY_REQUIRED_QUESTIONS_UNANSWERED: {
      return {
        slug,
        meta: {
          value: "Show when any required questions are un-answered"
        },
        priority: 50,
        condition: {
          overrides: {
            is_hidden: 0
          },
          display_context: {
            assessment_view: 1
          },
          or_conditions: [
            {
              desired_value: false,
              operator: COVisibilityConditionOperator.EQUALS,
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.ASSESSMENT
                    .FUNCTION_ARE_ALL_REQUIRED_QUESTIONS_ANSWERED,
                operator: PATH_OPERATORS.FUNCTION
              })
            }
          ]
        }
      };
    }
    case COConditionSlugs.HIDE_IF_USER_CANT_EDIT_PROCESS: {
      return {
        slug,
        meta: {
          value:
            "Hide if the user can't edit the current process in the context"
        },
        priority: 100,
        condition: {
          overrides: {
            is_hidden: 1
          },
          display_context: {
            assessment_view: 1
          },
          or_conditions: [
            {
              desired_value: 1,
              operator: COVisibilityConditionOperator.NOT_EQUALS,
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA.PERMISSIONS
                    .PROCESS_PERMISSIONS.P_EDIT
              })
            }
          ]
        }
      };
    }
    case COConditionSlugs.HIDE_IF_IN_TABULAR_SECTION: {
      return {
        slug,
        meta: {
          value:
            "If the context's section is in tabular format override is hidden to true"
        },
        priority: 100,
        condition: {
          or_conditions: [
            {
              desired_value: 1,
              operator: COVisibilityConditionOperator.EQUALS,
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_TABULAR
              })
            }
          ],
          overrides: {
            is_hidden: 1
          }
        }
      };
    }
    case COConditionSlugs.USE_QUESTION_TABLE_ROW_COMPONENT_IF_IN_TABLE_SECTION: {
      return {
        slug,
        meta: {
          value:
            "If the context's section is in tabular format override its component to table format"
        },
        priority: 100,
        condition: {
          or_conditions: [
            {
              desired_value: 1,
              operator: COVisibilityConditionOperator.EQUALS,
              current_value: PATH({
                route:
                  PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_TABULAR
              })
            }
          ],
          overrides: {
            component_type: COComponentType.QUESTION_TABLE_ROW
          }
        }
      };
    }
  }
  return {};
};

// this is a helper function
export const POST_ACTION_PHASE_STATUS_KEY_EQUALS = (
  desired_key: COPhaseStatusKeys
): COCondition[] => {
  return [
    {
      or_conditions: [
        {
          desired_value: desired_key,
          operator: COVisibilityConditionOperator.EQUALS,
          current_value: PATH({
            route:
              PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA
                .POST_ACTION_PHASE_STATUS_KEY
          })
        }
      ]
    }
  ];
};

export const templateQuestionConditions = (): COConditionOverrideSlugPayload[] => {
  return [
    {
      slug: COConditionSlugs.HIDE_QUESTION_IF_NOT_ANSWERED_IN_VIEW_CONTEXT
    },
    {
      slug: COConditionSlugs.QUESTION_HIDES_COMMENTS_SECTION
    },
    {
      slug: COConditionSlugs.QUESTION_NOT_REQUIRED
    },
    {
      slug: COConditionSlugs.QUESTION_DOES_NOT_IMPACT_KPI
    },
    {
      slug:
        COConditionSlugs.USE_QUESTION_TABLE_ROW_COMPONENT_IF_IN_TABLE_SECTION
    }
  ];
};

export const templateSectionConditions = (): COConditionOverrideSlugPayload[] => {
  return [
    ...[
      {
        slug: COConditionSlugs.HIDE_IF_NO_QUESTIONS_VISIBLE_IN_SECTION
      }
    ],
    ...[{ slug: COConditionSlugs.DONT_SHOW_IN_SIDEBAR }]
  ];
};

export const templateAssessmentConditions = (): COConditionOverrideSlugPayload[] => {
  return [
    ...[
      {
        slug: COConditionSlugs.HIDE_IF_NO_QUESTIONS_VISIBLE_IN_ASSESSMENT
      }
    ]
  ];
};

export const phaseConditionCheck = (
  phase: COPhaseKeys | string,
  operator: COVisibilityConditionOperator
): COConditionCalculationInterface => {
  return {
    desired_value: phase,
    operator: operator,
    current_value: PATH({
      route: PATH_ROUTE.CONTEXT.PROCESS_EXTERNAL_DATA.PHASE_STATUS_KEY
    })
  };
};

export const phaseLessOrEqualTo = (
  phase: COPhaseKeys
): COConditionCalculationInterface => {
  return phaseConditionCheck(
    phase,
    COVisibilityConditionOperator.MAX_PHASE_OR_STATUS
  );
};
export const phaseGreaterOrEqualTo = (
  phase: COPhaseKeys
): COConditionCalculationInterface => {
  return phaseConditionCheck(
    phase,
    COVisibilityConditionOperator.MIN_PHASE_OR_STATUS
  );
};

export const betweenPhases = (
  phaseOne: COPhaseKeys,
  phaseTwo: COPhaseKeys
): COConditionCalculationInterface => {
  return phaseConditionCheck(
    [phaseOne, phaseTwo].join(","),
    COVisibilityConditionOperator.BETWEEN_PHASE_OR_STATUSES
  );
};
