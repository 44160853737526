import React from "react";
import { useTranslation } from "react-i18next";
import "./CellStatus.scss";
import {
  APPROVED_IDS,
  NOT_STARTED_IDS,
  AWAITING_REVIEW_IDS,
  ON_TRACK_IDS,
  COMPLETED_IDS,
  REJECTED_IDS,
  AT_RISK_IDS,
  DELAYED_IDS,
  CANCELLED_IDS,
  DUPLICATE_IDS,
  ON_HOLD_IDS,
  ARCHIVED_IDS,
  IN_PROGRESS_IDS,
  READY_FOR_PRODUCTION_IDS,
  REWORK_REQUIRED_IDS,
  ONGOING_IDS
} from "../../constants/automationStatusTypes";
import { ChipApollo } from "../../components/ChipApollo/ChipApollo.tsx";
import {
  CLASSNAME_BLUE,
  CLASSNAME_GREEN,
  CLASSNAME_RED,
  CLASSNAME_YELLOW,
  CLASSNAME_GRAY
} from "../../constants/phaseColorsConstants";

const getChipColor = phaseStatusId => {
  let className = "";

  if (APPROVED_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_GREEN;
  } else if (NOT_STARTED_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_YELLOW;
  } else if (REWORK_REQUIRED_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_YELLOW;
  } else if (AWAITING_REVIEW_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_YELLOW;
  } else if (ON_TRACK_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_GREEN;
  } else if (COMPLETED_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_GREEN;
  } else if (REJECTED_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_RED;
  } else if (AT_RISK_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_RED;
  } else if (DELAYED_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_RED;
  } else if (CANCELLED_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_GRAY;
  } else if (DUPLICATE_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_GRAY;
  } else if (ON_HOLD_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_GRAY;
  } else if (ARCHIVED_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_GRAY;
  } else if (IN_PROGRESS_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_BLUE;
  } else if (ONGOING_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_GREEN;
  } else if (READY_FOR_PRODUCTION_IDS.indexOf(phaseStatusId) > -1) {
    className = CLASSNAME_BLUE;
  }

  return className;
};

export const phaseStatusName = (process_phase_status, t) => {
  const phase_status_name = process_phase_status
    ? process_phase_status.phase_status_name
    : "";

  //e.g. automation_table_sort_options_be_alphabetical
  return process_phase_status
    ? t([
        "automation_table_sort_options_be_" +
          ((process_phase_status || {}).phase_status_key || "").toLowerCase(),
        phase_status_name
      ])
    : phase_status_name;
};

const CellStatus = props => {
  const { process_phase_status } = props || {};
  const { t } = useTranslation("common");

  const phase_status_id = process_phase_status
    ? process_phase_status.phase_status_id
    : -1;

  const chipClassName = getChipColor(phase_status_id);

  return (
    <div className="CellStatus" data-testid="CellStatusContainer">
      <ChipApollo
        label={phaseStatusName(process_phase_status, t)}
        size="small"
        className={chipClassName}
      />
    </div>
  );
};

export default CellStatus;
