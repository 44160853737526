import React from "react";
import Swal from "./../components/SweetAlert/SweetAlert";
import { CustomToast } from "./../components/SweetAlert/SweetAlert";
import { useTranslation } from "react-i18next";
import {
  triggerAnalyticsWithDefaultData,
  NO_RESPONSE_ERROR_EVENT_NAME
} from "./analyticsUtils";
import { getKeyValue } from "./objectParsers";
import SessionHelper from "../helpers/SessionHelper";
import jwtDecode from "jwt-decode";
import urlWithTenant from "./urlHelper";

export default async function apiErrorAlert(error) {
  if (Swal.isVisible()) {
    Swal.close();
  }

  if (error.config && error.response && error.response.status === 401) {
    return false;
  }

  if (error.config && error.response && error.response.status === 503) {
    CustomToast.fire({
      title: <ErrorAlertDeployment />
    });

    return false;
  }

  if (checkForAndRedirectFromBackend(error)) {
    return false;
  }

  if (error.request && error.config && !error.response) {
    const eventErrorData = {};

    if (
      getKeyValue(error, "config.headers") &&
      typeof error.config.headers === "object"
    ) {
      const configCopy = { ...error.config.headers };
      delete configCopy.Authorization;
      eventErrorData.config = JSON.stringify(configCopy);
    }

    if (error.request && typeof error.request === "object") {
      const requestCopy = { ...error.request };
      eventErrorData.request = JSON.stringify(requestCopy);
    } else if (typeof error.request === "string") {
      eventErrorData.request = error.request;
    }

    const userJwtToken = SessionHelper.getUserToken();
    if (userJwtToken) {
      const decodedToken = jwtDecode(userJwtToken);
      if (decodedToken) {
        const tokenExpires = decodedToken.exp;
        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (tokenExpires === null || tokenExpires < currentTimestamp) {
          window.location.href = urlWithTenant("/");
        }
      }
    }

    triggerAnalyticsWithDefaultData(
      NO_RESPONSE_ERROR_EVENT_NAME,
      eventErrorData
    );

    CustomToast.fire({
      title: <ErrorAlertNetwork />
    });

    return false;
  }

  try {
    await swalFromErrorMessage(error);
  } catch (error) {
    //can fail silently
    console.log(error);
  }

  return null;
}

export async function swalFromErrorMessage(error) {
  if (error?.response?.data?.data?.message) {
    const message = error.response.data.data.message;

    return Swal.fire({
      title: <ErrorTextTitle />,
      html: (
        <ErrorTextBody
          errorKey={error.response.data.data.localizationKey || ""}
          message={message}
        />
      )
    });
  } else {
    return Promise.resolve("No error message");
  }
}

export const errorMatchesLocalizationSlug = (error, localizationKey) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.data &&
    error.response.data.data.localizationKey
  ) {
    if (localizationKey === error.response.data.data.localizationKey) {
      return true;
    }
  }
  return false;
};

export const checkForAndRedirectFromBackend = error => {
  if (
    error.config &&
    error.response &&
    (error.response.status === 302 || error.response.status === 405)
  ) {
    redirectFromBackendError(error);
    return true;
  } else {
    return false;
  }
};

const redirectFromBackendError = error => {
  if (
    error.response &&
    error.response.data &&
    error.response.data.data &&
    error.response.data.data &&
    typeof error.response.data.data === "object"
  ) {
    const protocol = window.location.protocol;
    const hostName = window.location.hostname;
    const tenantName = error.response.data.data.t_name
      ? `/${error.response.data.data.t_name}`
      : "";
    const location = error.response.data.data.location || "";

    if (
      location.substring(0, 8).toLowerCase() === "https://" ||
      location.substring(0, 7).toLowerCase() === "https://"
    ) {
      window.location = location;
    } else {
      window.location = protocol + "//" + hostName + tenantName + location;
    }
  }
};

const ErrorTextTitle = () => {
  const { t: translate } = useTranslation("common", { useSuspense: false });
  return <span>{translate("error_alert_title")}</span>;
};

const ErrorTextBody = props => {
  const { t: translate } = useTranslation("common", { useSuspense: false });
  return <span>{translate([props.errorKey || "", props.message])}</span>;
};

const ErrorAlertDeployment = () => {
  const { t: translate } = useTranslation("common", { useSuspense: false });
  return <span>{translate("error_alert_deployment")}</span>;
};

const ErrorAlertNetwork = () => {
  const { t: translate } = useTranslation("common", { useSuspense: false });
  return <span>{translate("error_alert_network")}</span>;
};
