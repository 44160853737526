import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import queryString from "query-string";
import urlWithTenant, {
  redirectToPathWithTimeStampOrDefaultRoute
} from "../../utils/urlHelper";
import styled, { css } from "styled-components/macro";
import { authFPSHandler } from "../../utils/authFPSHandler";
import { SwalForRegisteringError } from "./../../components/SweetAlert/SweetAlert";
import NavLogin from "./../../components/NavLogin/NavLogin";
import FpsProvisionTenantSelector from "./FpsProvisionTenantSelector";
import FPSLoader from "../../assets/images/robot-loading.svg";
import FPSLoaderDark from "../../assets/images/robot-loading--dark.svg";
import FpsLoginRedirectMainContent from "./FpsLoginRedirectMainContent";
import FpsLoginError from "../../components/FpsLoginError/FpsLoginError";
import { EXPLORE_AUTOMATIONS_PROGRAM_ROUTE } from "../../constants/routesConstants";
import ImageThemed from "../../components/ImageThemed/ImageThemed";

const LoginHero = styled.div`
  text-align: center;
  background-size: cover;
  background-position: center;
`;

const LoaderTitle = styled.p(
  props => css`
    text-align: center;
    font-size: 20px;
    color: var(--color-foreground, ${props.theme.colors.black});
    padding: 20px;
  `
);

const LoaderSubtitle = styled.p`
  padding-bottom: 10px;
  text-align: center;
`;

const LoaderImage = styled(ImageThemed)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 400px;
`;

const LoaderContainer = styled.div`
  width: 480px;
  margin: 50px auto;
`;

const FpsLoginRedirectPage = ({ history }: RouteComponentProps) => {
  const [signupInfo, setSignupInfo]: any = useState(null);
  const [tenantsByOwner, setTenantsByOwner]: any[] = useState([]);
  const [tenants, setTenants]: any[] = useState([]);
  const [userEmail, setUserEmail]: any[] = useState("");
  const [showSelectTenantBox, setShowSelectTenantBox]: any[] = useState(false);
  const [showLoader, setShowLoader]: any = useState(false);
  const [showNotAdminPage, setShowNotAdminPage]: any = useState(false);
  const { t } = useTranslation("common");

  useEffect(() => {
    //check to see if we're in iframe
    if (window.location !== window.parent.location) {
      //we dont' want to process the rest of this request if we're inside an iframe - we're likely doing a background refresh at this point
    } else {
      const hash = window.location.hash || "";
      // If there is no hash we cant login and need to go through login again
      if (!hash) {
        history.replace(urlWithTenant("/"));
        return;
      }
      const parsedQueryParams = queryString.parse(hash);
      authFPSHandler.logInUserFromHash(
        parsedQueryParams,
        false,
        user_token => {
          if (user_token) {
            redirectToPathWithTimeStampOrDefaultRoute(
              history,
              history.replace(urlWithTenant(EXPLORE_AUTOMATIONS_PROGRAM_ROUTE))
            );
          }
        },
        loginErrorHandler
      );
    }
  }, [history]);

  const loginErrorHandler = (headers, error, messageObj) => {
    //handle tenant specific login flow errors
    if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 422 //tenant not provisioned
    ) {
      if (error.response.data.data && !error.response.data.data.user_is_admin) {
        // authFPSHandler.logout();
        setShowNotAdminPage(true);
      } else {
        let signupInfoFromResponse: any = {};
        signupInfoFromResponse.headers = headers;
        let tenantsByOwnersArray =
          error.response.data.data.tenants_by_owner || [];
        let tenantsArray = error.response.data.data.tenants || [];
        setUserEmail(error.response.data.data.user_email);
        setTenantsByOwner(tenantsByOwnersArray);
        setTenants(tenantsArray);
        setSignupInfo(signupInfoFromResponse);
      }
    } else {
      setShowNotAdminPage(true);
      SwalForRegisteringError(messageObj)
        .fire()
        .then(e => {
          // authFPSHandler.logout();
        });
    }
  };

  const hideSelectTenantBox = () => {
    setShowSelectTenantBox(false);
  };

  const renderLoader = () => {
    return (
      <LoaderContainer>
        <LoaderImage src={FPSLoader} srcDark={FPSLoaderDark} alt="fps loader" />

        <LoaderTitle>
          {t([
            "fps_creating_tenant_title",
            "Please wait while we set up things for you."
          ])}
        </LoaderTitle>
        <LoaderSubtitle>
          {t([
            "fps_creating_tenant_subtitle",
            "This could take a few minutes. The page will get refreshed automatically once the platform is ready to use."
          ])}
        </LoaderSubtitle>
      </LoaderContainer>
    );
  };

  const renderRedirectPageMainContent = () => {
    return (
      <FpsLoginRedirectMainContent
        history={history}
        signupInfo={signupInfo}
        tenantsByOwner={tenantsByOwner}
        setShowSelectTenantBox={setShowSelectTenantBox}
        showSelectTenantBox={showSelectTenantBox}
        setShowLoader={setShowLoader}
      />
    );
  };

  const renderContentWithCorrectSignupInfo = () => {
    return (
      <React.Fragment>
        {!!showSelectTenantBox && !showLoader && (
          <FpsProvisionTenantSelector
            tenantsByOwner={tenantsByOwner}
            tenants={tenants}
            userEmail={userEmail}
            hideSelectTenantBox={hideSelectTenantBox}
            history={history}
            signupInfo={signupInfo}
            showLoader={setShowLoader}
          ></FpsProvisionTenantSelector>
        )}

        <NavLogin hideSignupButton={true} />

        {!!showLoader ? renderLoader() : renderRedirectPageMainContent()}
      </React.Fragment>
    );
  };

  const renderErrorScreen = () => {
    return (
      <React.Fragment>
        <NavLogin hideSignupButton={true} />

        {!!showNotAdminPage ? (
          <FpsLoginError />
        ) : (
          <LoginHero>
            {/* <LoginHero_img>
              <img src={heroGifImage} alt="Hero" />
            </LoginHero_img> */}
          </LoginHero>
        )}
      </React.Fragment>
    );
  };

  return signupInfo
    ? renderContentWithCorrectSignupInfo()
    : renderErrorScreen();
};
export default FpsLoginRedirectPage;
