import { Component } from "react";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { getKeyValue } from "../utils/objectParsers";
import {
  VALID_LANGUAGES,
  EN,
  LANGUAGE_COOKIE_NAME
} from "../constants/localizationConstants";
import { featureFlag } from "../helpers/FeatureFlag";
import {
  AUTOMATION_HUB_SERVICE,
  AUTOMATION_STORE_SERVICE
} from "../constants/urlConstants";
import {
  APOLLO_THEME_LIGHT_KEY,
  APOLLO_THEME_STORAGE_KEY
} from "../components/StyleThemeProvider/StyleThemeProvider";
var SHA256 = require("crypto-js/sha256");

class SessionHelper extends Component {
  static currentUser() {
    return SessionHelper.getValueForKey("user");
  }

  static clearCurrentUser() {
    return SessionHelper.clearValueForKey("user");
  }

  static isLoggedIn() {
    return !!SessionHelper.currentUser();
  }

  // does the window location matches FPS service URL schema?
  // fps includes automationhub_ and automationstore_
  static isFpsUri() {
    const currentFpsService = SessionHelper.currentFpsService();
    if (
      currentFpsService === AUTOMATION_STORE_SERVICE ||
      currentFpsService === AUTOMATION_HUB_SERVICE
    ) {
      return true;
    }
    return false;
  }

  static currentFpsService() {
    if (window && window.location) {
      if (window.location.pathname) {
        let pathName = window.location.pathname;
        if (pathName.length > 0) {
          if (pathName[0] === "/") {
            pathName = pathName.substr(1);
          }
        }
        let pathObjects = pathName.split("/");
        // FPS style tenantName: "cloud_account/cloud_tenant/automationhub_" OR
        // "cloud_account/cloud_tenant/automationstore_"
        if (
          pathObjects.length >= 3 &&
          pathObjects[2].toLowerCase() === AUTOMATION_STORE_SERVICE
        ) {
          return AUTOMATION_STORE_SERVICE;
        }

        if (
          pathObjects.length >= 3 &&
          pathObjects[2].toLowerCase() === AUTOMATION_HUB_SERVICE
        ) {
          return AUTOMATION_HUB_SERVICE;
        }
      }
    }
    return "";
  }

  static userLoggedIn(user_data, user_token) {
    SessionHelper.setUserObject(user_data);
    SessionHelper.setUserToken(user_token);
  }

  static setUserObject(user_data) {
    const dict = {};
    dict.user_id = user_data.user_id;
    dict.user_email = user_data.user_email;
    dict.user_profile_slug = user_data.user_profile_slug;
    dict.user_first_name = user_data.user_first_name;
    dict.user_last_name = user_data.user_last_name;
    dict.user_created_epoch = user_data.user_created_epoch;
    dict.user_profile_pic = user_data.user_profile_pic;
    dict.user_business_unit = user_data.user_business_unit;
    dict.user_roles = user_data.user_roles;
    dict.ah_permissions = user_data.ah_permissions;
    dict.categories = user_data.categories || [];

    SessionHelper.setDataForKey(dict, "user");
    SessionHelper.updateUserPilot({
      theme:
        SessionHelper.getValueForKeyWithoutPrefix(APOLLO_THEME_STORAGE_KEY) ||
        APOLLO_THEME_LIGHT_KEY
    });
  }

  static updateUserPilot({
    processPermissions = null,
    componentPermissions = null,
    theme
  } = {}) {
    let currentUser = SessionHelper.currentUser();
    if (!currentUser || !currentUser.user_id) {
      return;
    }

    try {
      let unique_user_id_string =
        currentUser.user_id + "_" + SessionHelper.getTenantIdUserToken();
      let unique_user_id_hash = SHA256(unique_user_id_string).toString();

      let user_created_epoch = currentUser.user_created_epoch
        ? currentUser.user_created_epoch
        : 0;
      let flattenedPermissionsDictionary = {};

      if (
        currentUser.ah_permissions &&
        typeof currentUser.ah_permissions === "object"
      ) {
        flattenedPermissionsDictionary = Object.assign(
          {},
          flattenedPermissionsDictionary,
          currentUser.ah_permissions
        );
      }
      if (processPermissions && typeof processPermissions === "object") {
        flattenedPermissionsDictionary = Object.assign(
          {},
          flattenedPermissionsDictionary,
          processPermissions
        );
      }
      if (componentPermissions && typeof componentPermissions === "object") {
        flattenedPermissionsDictionary = Object.assign(
          {},
          flattenedPermissionsDictionary,
          componentPermissions
        );
      }

      if (user_created_epoch) {
        const now_epoch = Date.now(); // Unix timestamp in milliseconds
        const delta = now_epoch - user_created_epoch;
        const age_days = delta / (60 * 60 * 24 * 1000);
        flattenedPermissionsDictionary["user_age_days"] = age_days;
      } else {
        flattenedPermissionsDictionary["user_age_days"] = 0;
      }

      const language = SessionHelper.getValueForKey("language") || EN;
      flattenedPermissionsDictionary["language"] = language;

      const fpsMode = SessionHelper.isFpsUri() ? 1 : 0;
      flattenedPermissionsDictionary["fps_mode"] = fpsMode;

      if (window.userpilot && !featureFlag.isUserPilotDisabled()) {
        if (
          theme &&
          window.userpilot.theme &&
          featureFlag.isUserPilotDarkThemeEnabled()
        ) {
          window.userpilot.theme(
            theme === APOLLO_THEME_LIGHT_KEY
              ? "Cloud Portal New UX Walkthrough"
              : "Dark Theme"
          );
        }
        window.userpilot.identify(
          unique_user_id_hash, // Used to identify users
          flattenedPermissionsDictionary
        );
        window.userpilot.reload();
      }
    } catch (e) {}
  }

  static getUserLevel() {
    return SessionHelper.getValueForKey("level_name");
  }

  static getUserLevelSlug() {
    return SessionHelper.getValueForKey("level_slug");
  }

  static setUserLevel(levelName, levelSlug) {
    SessionHelper.setDataForKey(levelName, "level_name");
    SessionHelper.setDataForKey(levelSlug, "level_slug");
  }

  static getUserStatusPoints() {
    return SessionHelper.getValueForKey("user_points");
  }

  static setUserStatusPoints(statusPoints) {
    SessionHelper.setDataForKey(statusPoints, "user_points");
  }

  static getUserExchangeablePoints() {
    return SessionHelper.getValueForKey("user_exchangeable_points");
  }

  static setUserExchangeablePoints(exchangeablePoints) {
    SessionHelper.setDataForKey(exchangeablePoints, "user_exchangeable_points");
  }

  static setUserToken(token) {
    return SessionHelper.setDataForKey(token, "user_token");
  }

  static getUserToken() {
    return SessionHelper.getValueForKey("user_token");
  }

  static setUserCloudIDToken(token) {
    return SessionHelper.setDataForKey(token, "user_cloud_id_token");
  }

  static getUserCloudIDToken() {
    return SessionHelper.getValueForKey("user_cloud_id_token");
  }

  static setUserCloudAccessToken(token) {
    return SessionHelper.setDataForKey(token, "user_cloud_access_token");
  }

  static clearUserCloudAccessToken() {
    return SessionHelper.clearValueForKey("user_cloud_access_token");
  }

  static getUserCloudAccessToken() {
    return SessionHelper.getValueForKey("user_cloud_access_token");
  }

  // This is the token that cloud platform sets - auth_time needs checking - RPANAV-9767
  static getCloudSetUserCloudAccessToken() {
    return localStorage.getItem("accessToken");
  }

  static getTenantIdUserToken() {
    let userData = SessionHelper.getDataFromUserToken();
    if (userData && userData.t_id) {
      return userData.t_id;
    }
    return "";
  }

  static getTenantNameUserToken() {
    let userData = SessionHelper.getDataFromUserToken();
    if (userData && userData.t_name) {
      return userData.t_name;
    }
    return "";
  }
  static getUserUuid() {
    let userData = SessionHelper.getDataFromUserToken();
    if (userData && userData.u_uuid) {
      return userData.u_uuid;
    }
    return "";
  }
  static getTenantUuid() {
    let userData = SessionHelper.getDataFromUserToken();
    if (userData && userData.t_id) {
      return userData.t_id;
    }
    return "";
  }

  static getDataFromUserToken() {
    let userToken = SessionHelper.getUserToken();
    if (userToken) {
      try {
        var decoded = jwtDecode(userToken);
        if (decoded && decoded.data) {
          return decoded.data;
        }
      } catch (token_error) {
        console.log(token_error);
      }
    }
    return {};
  }

  static tenantNameFromCurrentPath() {
    if (window && window.location) {
      if (window.location.pathname) {
        let pathName = window.location.pathname;
        if (pathName.length > 0) {
          if (pathName[0] === "/") {
            pathName = pathName.substr(1);
          }
        }
        let pathObjects = pathName.split("/");
        // if in fps mode whether store or hub use the
        // automationhub_ prefixed storage
        if (SessionHelper.isFpsUri()) {
          let tenantName =
            pathObjects[0] +
            "/" +
            pathObjects[1] +
            "/" +
            AUTOMATION_HUB_SERVICE;
          return tenantName;
        }
        if (pathObjects.length > 0) {
          let tenantName = pathObjects[0];
          return tenantName;
        }
      }
    }
    return "";
  }
  static fpsTenantFromCurrentPath() {
    if (window && window.location) {
      if (window.location.pathname) {
        let pathName = window.location.pathname;
        if (pathName.length > 0) {
          if (pathName[0] === "/") {
            pathName = pathName.substr(1);
          }
        }
        let pathObjects = pathName.split("/");
        // FPS style tenantName: "cloud_account/cloud_tenant/automationhub_" we want cloud_account
        if (pathObjects.length > 1) {
          return pathObjects[1];
        }
      }
    }
    return "";
  }
  static fpsAccountFromCurrentPath() {
    if (window && window.location) {
      if (window.location.pathname) {
        let pathName = window.location.pathname;
        if (pathName.length > 0) {
          if (pathName[0] === "/") {
            pathName = pathName.substr(1);
          }
        }
        let pathObjects = pathName.split("/");
        // FPS style tenantName: "cloud_account/cloud_tenant/automationhub_" we want cloud_account
        if (pathObjects.length > 0) {
          return pathObjects[0];
        }
      }
    }
    return "";
  }

  static qualifiedHostnameFromPath(includePortNumber = false) {
    if (!window || !window.location) {
      return ""; //SSR or testing
    }

    // get the port number if it's localhost
    const host = includePortNumber
      ? window.location.host
      : window.location.hostname;

    return window.location.protocol + "//" + host;
  }

  static setLanguage(lang = "") {
    if (!!lang && VALID_LANGUAGES.includes(lang)) {
      SessionHelper.setDataForKey(lang, "language");
    }
  }

  static setLoginLogoutTenantNameWithoutPrefix(tenantName) {
    // Need to set tenant name without prefix since
    // The prefix is made from the tenant name
    // This is needed for auth0 email verification and
    // is used as a fallback for login if the tenantName query param
    // is not on the login-redirect route
    localStorage.setItem(
      "tenant_name",
      JSON.stringify(
        tenantName !== null && tenantName !== undefined ? tenantName : ""
      )
    );

    // Must also set cookie for login to work in firefox incognito
    // Currently firefox incognito clears storage after visiting an
    // outside url
    var d = new Date();
    // Cookie expires in 1 day
    d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = "tenant_name=" + tenantName + ";" + expires + ";path=/";

    return tenantName;
  }

  static getLoginLogoutTenantNameWithoutPrefix() {
    // Need to get tenant name without prefix since
    // The prefix is made from the tenant name
    // This is needed for auth0 email verification and
    // is used as a fallback for login if the tenantName query param
    // is not on the login-redirect route
    try {
      let item = localStorage.getItem("tenant_name");
      if (item) {
        return JSON.parse(item);
      }
    } catch (exception) {}

    // Fallback to cookie needed for firefox incognito
    // Currently firefox incognito clears storage after visiting an
    // outside url
    var name = "tenant_name=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return "";
  }

  static localStoragePrefixForCurrentTenant() {
    const tenantName = SessionHelper.tenantNameFromCurrentPath();
    return `ah_${tenantName}_`.toLowerCase();
  }

  static getKeyNameWithCurrentTenantPrefix(key) {
    return SessionHelper.localStoragePrefixForCurrentTenant() + key;
  }

  static setDataForKey(data, key) {
    //cookie.save(key, data, { path: '/' });
    localStorage.setItem(
      SessionHelper.getKeyNameWithCurrentTenantPrefix(key),
      JSON.stringify(data !== null && data !== undefined ? data : "")
    );
  }

  static getValueForKey(key) {
    //const loadedObject = cookie.load(key);
    ///if(loadedObject) {
    //  return loadedObject;
    //}
    try {
      let item = localStorage.getItem(
        SessionHelper.getKeyNameWithCurrentTenantPrefix(key)
      );
      if (item) {
        return JSON.parse(item);
      }
    } catch (exception) {}
    return "";
  }

  static getValueForKeyWithoutPrefix(key) {
    let item = "";
    try {
      item = localStorage.getItem(key);
      if (item) {
        try {
          //some values are wrapped in quotes, need to parse them
          item = JSON.parse(item);
        } catch (e) {}
      }
    } catch (e) {}
    return item;
  }

  static getLanguage() {
    return this.getValueForKey("language") || EN;
  }

  //In Cloud platform the laugnage field in session doesen't have any prefix
  static getAndSetLanguageFromCloud(i18n) {
    let language = this.getValueForKeyWithoutPrefix("language") || EN;

    if (!VALID_LANGUAGES.includes(language)) {
      language = EN;
    }

    SessionHelper.setDataForKey(language, LANGUAGE_COOKIE_NAME);
    moment.locale(language);
    i18n.changeLanguage(language);
  }

  static getDateFormat() {
    let tenantSettingObj = this.getValueForKey("tenant_settings");
    let dateFormat = "";

    dateFormat = getKeyValue(
      tenantSettingObj,
      "date-format.setting_option_value"
    );
    return dateFormat;
  }

  static setLazyLoadRefreshEpoch() {
    this.setDataForKey(new Date().getTime(), "lazy_load_refresh_error_epoch");
  }

  static getLazyLoadRefreshEpoch() {
    return this.getValueForKey("lazy_load_refresh_error_epoch") || null;
  }

  static clearExcept(saveKeys = []) {
    if (Array.isArray(saveKeys)) {
      const recalledValues = [];
      saveKeys.forEach(key => {
        if (typeof key === "string") {
          const data = this.getValueForKey(key);
          recalledValues.push({
            data,
            key
          });
        }
      });
      this.clear();
      recalledValues.forEach(saveObj => {
        this.setDataForKey(saveObj.data, saveObj.key);
      });
    } else {
      this.clear();
    }
  }

  static clear() {
    //cookie.remove('auth_token', { path: '/' });
    //cookie.remove('user_token', { path: '/' });
    //cookie.remove('user', { path: '/' });
    localStorage.clear();
  }

  static clearValueForKey(key) {
    // cookie.remove(key, { path: '/' });
    localStorage.removeItem(
      SessionHelper.getKeyNameWithCurrentTenantPrefix(key)
    );
  }
}

export default connect()(SessionHelper);
