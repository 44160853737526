import React from "react";
import PropTypes from "prop-types";
import "./../../components/BrowserNotSupported/BrowserNotSupported.scss";
const BrowserNotSupported = props => {
  const { header, description, browsers } = props;
  const renderSupportedBrowsers = () => {
    if (browsers) {
      return (
        <ul>
          {browsers.map(item => {
            let browserIcon = require("./../../assets/images/browser-icons/" +
              item.iconName +
              ".png");
            return (
              <li key={item.name}>
                <a
                  href={item.downloadUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={browserIcon} alt={item.name} />
                  {item.name}
                </a>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  return (
    <div className="BrowserNotSupported" data-testid="BrowserNotSupported">
      <div className="BrowserNotSupported__main">
        <h1 className="BrowserNotSupported__header">{header}</h1>
        <div className="BroswerNotSupported__description">{description}</div>
        <div className="BroswerNotSupported__list">
          {renderSupportedBrowsers()}
        </div>
      </div>
    </div>
  );
};

BrowserNotSupported.defaultProps = {
  header: "Browser not supported",
  description:
    "For the best experience in using Automation Hub, we recommend you use the following browsers:",
  browsers: [
    {
      name: "Chrome",
      downloadUrl: "https://www.google.com/chrome/browser/",
      iconName: "chrome-icon"
    },
    {
      name: "Firefox",
      downloadUrl: "https://www.mozilla.org/firefox",
      iconName: "firefox-icon"
    },
    {
      name: "Opera",
      downloadUrl: "https://www.opera.com/download",
      iconName: "opera-icon"
    },
    {
      name: "Safari",
      downloadUrl: "https://support.apple.com/downloads/#safari",
      iconName: "safari-icon"
    }
  ]
};
BrowserNotSupported.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  browsers: PropTypes.array
};
export default BrowserNotSupported;
