import { COBase } from "../../../classes/co-base.class";
import {
  COErrorTypes,
  COTypes,
  COValidationType
} from "../../../constants/co-constants";
import {
  QUESTION_EQUATION_TRANSFORM_TARGET,
  QUESTION_VARIABLE_TRANSFORM_TARGET,
  QUESTION_VARIABLE_VALIDATE_TARGET
} from "../../../constants/co-equation.constants";
import { validateQuestionEquationDoesntReferenceQuestions } from "../../../helpers/co-calculation.helper";
import {
  addValidationErrors,
  validationContextIsTransform
} from "../../../helpers/co-context-validation.helper";
import {
  parseDisplayEquation,
  parseRawEquation,
  validateEquationFormat
} from "../../../helpers/co-equation.helper";
import {
  COValidationItemInterface,
  COValidationContext,
  COValidationError,
  COContextInterface,
  COQuestionInterface,
  COAssessmentInterface
} from "../../../interfaces/co-interfaces";
import { errorKeyFromPath } from "../../../utils/co-path.utils";
import { isNullOrUndefined } from "../../../utils/co-utils";

// we want validators to trigger when the transforms are called - so we get them in real time
// but the objects where we want the errors to show in the UI will have the validate target path

export const VARIABLE_MAX_LENGTH = 50;
export const variableValidator = (): COValidationItemInterface => {
  return {
    target: QUESTION_VARIABLE_TRANSFORM_TARGET,
    validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
    meta: {
      value: `Variables must only include letters and underscores and be less than ${VARIABLE_MAX_LENGTH} characters`
    },
    validation_context: {
      assessment_customize_transform_edit: 1,
      assessment_customize: 1
    },
    value: 1,
    validationFunction: ({
      context,
      validation_context,
      value,
      target,
      error
    }: {
      context: COContextInterface;
      validation_context: COValidationContext;
      value: any;
      target: any;
      error: COValidationError;
    }): COValidationError[] => {
      if (!isNullOrUndefined(target)) {
        let regexForCAPITALUNDERSCORES = /([^A-Z_])/g;
        let regexForSPACES = /([ ])/g;

        let transformedValue: string = (target || "")
          .toUpperCase()
          .replace(regexForSPACES, "_")
          .replace(regexForCAPITALUNDERSCORES, "");

        if (transformedValue && transformedValue.length > VARIABLE_MAX_LENGTH) {
          transformedValue = transformedValue.substring(
            0,
            VARIABLE_MAX_LENGTH - 1
          );
        }

        if (transformedValue !== target) {
          error.transformedValue = transformedValue;
          return [error];
        }
      }
      return [];
    }
  };
};

export const variableChangeValidator = (): COValidationItemInterface => {
  return {
    target: QUESTION_VARIABLE_TRANSFORM_TARGET,
    validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
    meta: {
      value: "Re-Render All Equations When a Variable Updates"
    },
    validation_context: {
      assessment_customize_transform_blur: 1
    },
    value: 1,
    validationFunction: ({
      context,
      validation_context,
      value,
      target,
      error
    }: {
      context: COContextInterface;
      validation_context: COValidationContext;
      value: any;
      target: any;
      error: COValidationError;
    }): COValidationError[] => {
      // I don't know if this is the best place to do this - but but we want to "poke" all the equations to re-render when a variable is updated
      if (context.assessment) {
        // basically I need all the equations in the assessment to be re-calculated
        for (const section of context.assessment.co_assessment_sections || []) {
          //kpi found to update
          let kpiFound = false;
          for (const question of section.co_questions || []) {
            // we could just update everything with an equation - if we ever expose question equation editing
            if (question.co_question_co_type === COTypes.KPI) {
              kpiFound = true;
              let questionCO: COQuestionInterface = question;
              questionCO.updateDisplayEquation?.({
                context: context.update?.({ question }) || {}
              });
            }
          }
          if (kpiFound) {
            COBase.incrementRenderCounters(
              context.update?.({ section }) || {},
              section
            );
          }
        }
      }

      return [];
    }
  };
};

export const variableDuplicateCheckValidator = (): COValidationItemInterface => {
  return {
    target: QUESTION_VARIABLE_TRANSFORM_TARGET,
    validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
    meta: {
      value: "Variables must be unique within an Assessment"
    },
    validation_context: {
      assessment_customize_transform_blur: 1,
      assessment_customize: 1
    },
    value: 1,
    validationFunction: ({
      context,
      validation_context,
      value,
      target,
      error
    }: {
      context: COContextInterface;
      validation_context: COValidationContext;
      value: any;
      target: any;
      error: COValidationError;
    }): COValidationError[] => {
      // need to make sure our variables are unique within the context
      if (!isNullOrUndefined(target) && target !== "") {
        let assessment: COAssessmentInterface | undefined = context.assessment;
        if (assessment && assessment.findObjectsWithPropertyOfValue) {
          let questionsWithVariable: COQuestionInterface[] = assessment.findObjectsWithPropertyOfValue(
            "co_variable_name",
            target
          );
          // handles the case if it's before or after we've set the value on the object
          if (
            questionsWithVariable.length > 1 ||
            (questionsWithVariable.length > 0 &&
              questionsWithVariable[0].co_question_ahid !==
                context.question?.co_question_ahid)
          ) {
            let validationError: COValidationError = {
              error_message: `Variable name is duplicated with the variable of question ${questionsWithVariable
                .map(
                  q =>
                    q.co_question_meta_json?.title?.value || q.co_question_ahid
                )
                .join(" and ")} `,
              // it's a different error key target - we want to key against the property but transform against the value for now
              error_key: errorKeyFromPath(
                context,
                QUESTION_VARIABLE_VALIDATE_TARGET
              ),
              error_type: COErrorTypes.IMPACTS_CALCULATION,
              problem_object: context.question,
              problem_property: context.question?.co_variable_name || target
            };

            if (validationContextIsTransform(validation_context)) {
              addValidationErrors(context, [validationError]);
            }

            return [validationError];
          }
        }
      }
      return [];
    }
  };
};

export const variableUpdateCalculatedValueKeyValidator = (): COValidationItemInterface => {
  return {
    target: QUESTION_VARIABLE_TRANSFORM_TARGET,
    validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
    meta: {
      value: "Update calculated value key for the question"
    },
    validation_context: {
      assessment_customize_transform_blur: 1,
      assessment_customize: 1
    },
    value: 1,
    validationFunction: ({
      context,
      validation_context,
      value,
      target,
      error
    }: {
      context: COContextInterface;
      validation_context: COValidationContext;
      value: any;
      target: any;
      error: COValidationError;
    }): COValidationError[] => {
      context.question?.updateCalculatedValueKey?.(context);
      return [];
    }
  };
};

export const equationTransformValidator = (): COValidationItemInterface => {
  return {
    target: QUESTION_EQUATION_TRANSFORM_TARGET,
    validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
    meta: {
      value: "EQUATION VALIDATION"
    },
    validation_context: {
      assessment_customize_transform_edit: 1,
      assessment_customize_transform_view: 1
    },
    value: 1,
    validationFunction: ({
      context,
      validation_context,
      value,
      target,
      error
    }: {
      context: COContextInterface;
      validation_context: COValidationContext;
      value: any;
      target: any;
      error: COValidationError;
    }): COValidationError[] => {
      // this is annoying
      error.transformedValue = target; // handle empty case
      // so I need to be able to parse the display_equation into the rawEquation if I haven't yet

      // setting the initial one
      if (validation_context.assessment_customize_transform_view) {
        let question: COQuestionInterface | undefined = context.question;
        if (question) {
          question.updateDisplayEquation?.({
            context,
            rawEquation: question.co_equation || ""
          });
          target = question.co_display_equation;
        }
      }
      // so we also want to see if the question variables have been updated
      // so we want to make sure we didn't break backwards like by removing a question with an ahid

      // validate display format - we want to do this for view and edit - but the first one only for view
      let parsedEquation = parseDisplayEquation({
        context,
        question: context.question,
        displayEquation: target
      });

      let validation_errors: COValidationError[] = [];
      if (parsedEquation) {
        error.transformedValue = parsedEquation.displayEquation;
        // update raw equation
        // this is a messy
        // save underlying value only on editing - and we need to do this
        // so we bind the underlying ahid variables incase they break the equation while editing
        if (validation_context.assessment_customize_transform_edit) {
          let question: COQuestionInterface | undefined = context.question;
          if (question) {
            question.updateRawEquation?.({
              context,
              displayEquation: target
            });

            let equationValidationErrors: COValidationError[] = validateEquationFormat(
              {
                context,
                question,
                equation: question.co_equation || ""
              }
            );
            validation_errors = [
              ...validation_errors,
              ...equationValidationErrors
            ];
          }
        }
        validation_errors = [
          ...validation_errors,
          ...parsedEquation.validation_errors
        ];
      }

      if (validationContextIsTransform(validation_context)) {
        if (validation_errors && validation_errors.length > 0) {
          addValidationErrors(context, validation_errors);
        }
      }

      // we also need to update the raw equation
      return [error];
    }
  };
};

export const equationReferenceValidator = (): COValidationItemInterface => {
  return {
    target: QUESTION_EQUATION_TRANSFORM_TARGET,
    validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
    meta: {
      value: "EQUATION VALIDATION"
    },
    validation_context: {
      assessment_customize_transform_edit: 1,
      assessment_customize_transform_view: 1,
      assessment_customize: 1
    },
    value: 1,
    validationFunction: ({
      context,
      validation_context,
      value,
      target,
      error
    }: {
      context: COContextInterface;
      validation_context: COValidationContext;
      value: any;
      target: any;
      error: COValidationError;
    }): COValidationError[] => {
      if (!context || !context.question || !context.question.co_equation) {
        return [];
      }
      let validation_errors = validateQuestionEquationDoesntReferenceQuestions({
        context,
        question: context.question,
        rawEquation: context.question.co_equation
      });
      if (validation_errors && validation_errors.length > 0) {
        if (validationContextIsTransform(validation_context)) {
          addValidationErrors(context, validation_errors);
        }
      }

      return validation_errors;
    }
  };
};

export const equationValidator = (): COValidationItemInterface => {
  return {
    target: QUESTION_EQUATION_TRANSFORM_TARGET,
    validationType: COValidationType.VALIDATION_TYPE_FUNCTION,
    meta: {
      value: "Final Equation Check"
    },
    validation_context: {
      assessment_customize: 1
    },
    value: 1,
    validationFunction: ({
      context,
      validation_context,
      value,
      target,
      error
    }: {
      context: COContextInterface;
      validation_context: COValidationContext;
      value: any;
      target: any;
      error: COValidationError;
    }): COValidationError[] => {
      let validation_errors: COValidationError[] = [];
      if (target && context.question) {
        let question = context.question;

        // need to be able to parse this and find all the questions
        let parsedRawEquation = parseRawEquation({
          context,
          question
        });
        validation_errors = [
          ...validation_errors,
          ...parsedRawEquation.validation_errors
        ];

        let parsedDisplayEquation = parseDisplayEquation({
          context,
          question
        });

        validation_errors = [
          ...validation_errors,
          ...parsedDisplayEquation.validation_errors
        ];

        let equationValidationErrors: COValidationError[] = validateEquationFormat(
          {
            context,
            question,
            equation: question.co_equation || ""
          }
        );
        validation_errors = [...validation_errors, ...equationValidationErrors];
      }
      return validation_errors;
    }
  };
};
