import {
  COAAddSectionInterface,
  COContextInterface,
  COMetadataInterface,
  COOptionsInterface,
  COPositionInterface,
  COSectionDBClass,
  COSectionInterface,
  COSectionTemplateInterface,
  COControlInterface,
  COQuestionInterface
} from "../interfaces/co-interfaces";
import { COBase } from "./co-base.class";
import { addQuestion, COQuestion } from "./co-question.class";
import { COProcessAnswer } from "./co-process-answer.class";
import { objectDeepCopy } from "../helpers/co-context.helper";
import {
  COContextObjectKey,
  CO_NON_TABULAR_DEFAULT_INDEX
} from "../constants/co-constants";
import { COBaseTemplate } from "./co-base-template.class";
import { updatePositionSortOrderForItems } from "../helpers/co-position.helper";
import { sectionBranchingControls } from "../templates/elements/controls/co-section-branching-controls.template";
import { questionsByTableRowIndex } from "../helpers/co-tabular-section.helper";

export interface COSection extends COSectionInterface {}
export class COSection extends COBase {
  static getClassName(): string {
    return "COSection";
  }
  constructor({
    propertiesFromJSON,
    context,
    template
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
    template?: COSectionTemplate;
  }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [
        {
          objectKey: "co_questions",
          objectClass: COQuestion
        }
      ],
      objects: [],
      dbClass: COSectionDBClass,
      contextKey: COContextObjectKey.SECTION,
      ahid_key: "co_assessment_section_ahid",
      objectClass: COSection
    };

    if (!this.co_questions) {
      this.co_questions = [];
    }

    //this means new item
    if (template) {
      this.template = template;
      this[this.objectClassMapping.ahid_key || ""] =
        (template && template.co_section_json?.co_assessment_section_ahid) ||
        COSection.generateAHID();

      let section_object_from_template = template.co_section_json;
      if (section_object_from_template) {
        let deepCopy = objectDeepCopy(section_object_from_template);
        let keys = Object.keys(deepCopy);
        // assign keys from template
        for (const key of keys) {
          this[key] = deepCopy[key];
        }
        //let's add questions if they're there
        this.co_questions = [];
        if (section_object_from_template.co_questions) {
          for (const question_raw of section_object_from_template.co_questions) {
            addQuestion({
              context: context || COSection.setContext(undefined, this),
              section: this,
              rawQuestionValues: question_raw
            });
          }
        }
      }
    }
    this.checkAndLoadTemplate?.();
  }

  templateSlug?() {
    return this.co_section_template_slug;
  }

  objectMeta?(): COMetadataInterface {
    return this.co_section_meta_json || {};
  }
  objectOptions?(): COOptionsInterface {
    return this.co_section_options_json || {};
  }
  objectPosition?(): COPositionInterface {
    return this.co_section_position_json || {};
  }

  functionNumVisibleQuestions?(context: COContextInterface) {
    let numVisible = 0;
    if (context.section) {
      numVisible = (context.section.co_questions || []).filter(q =>
        q.isVisibleInContext?.(context.update?.({ question: q }))
      ).length;
    }
    return numVisible;
  }

  functionSectionBranchingControls?(
    context: COContextInterface
  ): COControlInterface[] {
    return sectionBranchingControls(context);
  }

  //
  static prepareForCalculation(
    context: COContextInterface,
    section: COSection
  ) {
    super.prepareForCalculation(context, section);
    section.recalculateTabularIndexes?.({ context, section });
    // this is where I need to update all of the indexes for the tabular questions
  }

  //pass down to questions i should make this more generic using the objectClassMapping
  calculate?(
    context: COContextInterface,
    throwExceptionOnCalculationError = false
  ): any[] {
    context = COSection.setContext(context, this);

    let calculatedQuestions: any[] = [];
    for (const question of this.co_questions || []) {
      let q: COQuestion = question;
      if (q.calculate) {
        calculatedQuestions = [...calculatedQuestions, ...q.calculate(context)];
      }
    }
    return calculatedQuestions;
  }

  static prepForSubmissionViewOrEdit({
    context,
    section
  }: {
    context?: COContextInterface;
    section: COSection;
  }): COSection {
    section.recalculateTabularIndexes?.({ context: context || {}, section });

    for (const question of section.co_questions || []) {
      context = context?.update?.({ question });
      // need to add additional checks to see if they have null answers
      if (
        !question.co_process_answer ||
        question.co_process_answer.co_process_answer_selections?.length === 0
      ) {
        question.co_process_answer = new COProcessAnswer({
          context,
          question
        });
        // we want to add a process answer automatically
      }
      // this can do things like handle null selections
      const pa: COProcessAnswer = question.co_process_answer;
      if (pa && context) {
        pa.prepForSubmissionViewOrEdit?.({
          context:
            context?.update?.({
              section,
              question,
              process_answer: question.co_process_answer
            }) || context
        });
      }
    }
    return section;
  }

  addTabularSectionRow?({
    context,
    co_table_row_index
  }: {
    context: COContextInterface;
    co_table_row_index: number;
  }) {
    if (context.section) {
      const section: COSection = context.section;
      // check if we can - by options whatever
      // get questions - grouped by co_table_row_index

      // first recalc so indicides are all proper
      this.recalculateTabularIndexes?.({ context, section });

      const indexGroupedQuestions: {
        [key: number]: COQuestionInterface[];
      } = questionsByTableRowIndex({ section });

      let questions_to_duplicate: COQuestion[] =
        indexGroupedQuestions[co_table_row_index];
      if (questions_to_duplicate) {
        // quick JSON based copy here -

        const duplicated_questions = duplicateAndClearAnswerFromQuestions({
          questions_to_duplicate,
          co_table_row_index: parseFloat(co_table_row_index + "") + 0.5
        });

        // do we need to prepare for calculation or something?
        section.co_questions = [
          ...(section.co_questions || []),
          ...duplicated_questions
        ];

        // recalc to make things integers again
        COSection.prepForSubmissionViewOrEdit?.({ context, section });

        //update rendercounter
        COBase.incrementRenderCounters(context, section);
      }
    }
  }

  removeTabularSectionRow?({
    context,
    co_table_row_index
  }: {
    context: COContextInterface;
    co_table_row_index;
  }) {
    const section = context.section;
    if (section) {
      section.co_questions = section.co_questions?.filter(question => {
        return question.co_table_row_index != co_table_row_index;
      });

      COSection.prepForSubmissionViewOrEdit?.({ context, section });

      // need to somehow update rendercounter
      COBase.incrementRenderCounters(context, section);
    }
  }

  recalculateTabularIndexes?({
    context,
    section
  }: {
    context: COContextInterface;
    section: COSection;
  }) {
    if (section.co_questions) {
      // let's see if the section is tabular

      const questionsByTabularKey = questionsByTableRowIndex({ section });

      let tabularKeys = Object.keys(questionsByTabularKey).sort(
        (a, b) => parseFloat(a + "") - parseFloat(b + "")
      );

      // console.log("---------");
      // console.log("questionsByTabularKey");
      // console.log(questionsByTabularKey);
      // console.log("tabularKeys");
      // console.log(tabularKeys);

      if (section.co_section_options_json?.is_tabular) {
        // sort and re-calc indexes
        // let's make sure we don't have any -1 indexes if we're tabular
        if (questionsByTabularKey[CO_NON_TABULAR_DEFAULT_INDEX]) {
          // take lowest index that's > default index
          let lowestIndex =
            tabularKeys.length > 1 ? parseFloat(tabularKeys[1] + "") : 0;

          // console.log("lowestIndex");
          // console.log(lowestIndex);

          if (!questionsByTabularKey[lowestIndex]) {
            questionsByTabularKey[lowestIndex] = [];
            if (!tabularKeys.includes(lowestIndex + "")) {
              tabularKeys.push(lowestIndex + "");
            } // we have to add 0 here too
          }

          for (const questionToMerge of questionsByTabularKey[
            CO_NON_TABULAR_DEFAULT_INDEX
          ]) {
            if (
              !questionsByTabularKey[lowestIndex].find(
                q => q.co_question_ahid === questionToMerge.co_question_ahid
              )
            ) {
              questionsByTabularKey[lowestIndex].push(questionToMerge);
            }
          }

          if (lowestIndex !== CO_NON_TABULAR_DEFAULT_INDEX) {
            delete questionsByTabularKey[CO_NON_TABULAR_DEFAULT_INDEX]; // remove -1 because they're now in the lowest index
            tabularKeys = tabularKeys.filter(tk => {
              return parseInt(tk + "") != CO_NON_TABULAR_DEFAULT_INDEX;
            });
          }
        }

        // this makes sure that the lowest index 0 has all the questions
        // however we need to make sure each question is "full"
        let currentIndex = 0;
        const finalQuestions: COQuestion[] = [];

        // we want all the distinct questions to make sure each "row" is totally full
        // it will always return at least one of each question, just not all rows may be full
        const distinctQuestions: COQuestion[] = [];
        for (const question of section.co_questions || []) {
          if (
            !distinctQuestions.find(
              q => q.co_question_ahid === question.co_question_ahid
            )
          ) {
            distinctQuestions.push(question);
          }
        }

        for (const indexKey of tabularKeys) {
          const groupedQuestions = questionsByTabularKey[indexKey] || [];
          // // need to make sure we  have a full "set of questions"
          for (const q of distinctQuestions) {
            // make sure we have one for all of these
            if (
              !groupedQuestions.find(
                qq => qq.co_question_ahid === q.co_question_ahid
              )
            ) {
              // we need to add one to "fill the row"
              const duplicatedQuestion = duplicateAndClearAnswerFromQuestions({
                questions_to_duplicate: [q],
                co_table_row_index: currentIndex
              })?.[0];

              if (duplicatedQuestion) {
                groupedQuestions.push(duplicatedQuestion);
              }
            }
          }

          for (const q of groupedQuestions) {
            q.setQuestionTableRowIndex?.(currentIndex);
            finalQuestions.push(q);
          }

          currentIndex = currentIndex + 1;
        }
        // this deals with any newly added questions // need to r
        section.co_questions = finalQuestions.sort((a, b) => {
          return (a.sortOrder?.() || 0) - (b.sortOrder?.() || 0);
        });
      } else {
        // set the questions to -1
        // take first key, drop other questions (if we're converting between table and non table
        const finalQuestions: COQuestion[] = [];
        for (const question of section.co_questions || []) {
          if (
            !finalQuestions.find(
              q => q.co_question_ahid === question.co_question_ahid
            )
          ) {
            finalQuestions.push(question);
            question.setQuestionTableRowIndex?.(CO_NON_TABULAR_DEFAULT_INDEX);
          }
        }
        section.co_questions = finalQuestions;
      }
    }
  }
}

export const duplicateAndClearAnswerFromQuestions = ({
  questions_to_duplicate,
  co_table_row_index
}: {
  questions_to_duplicate: COQuestion[];
  co_table_row_index: number;
}): COQuestion[] => {
  let new_questions = JSON.parse(
    JSON.stringify(objectDeepCopy(questions_to_duplicate))
  );
  for (const question of new_questions) {
    delete question.co_process_calculated_value;
    delete question.co_process_answer;
  }
  let duplicated_questions: COQuestion[] = new_questions.map(
    // re-creates class object
    question => COQuestion.objectFromJSON(question) || {}
  );
  // sets it and updates it
  for (const question of duplicated_questions) {
    question.setQuestionTableRowIndex?.(co_table_row_index); // we want to insert
  }
  return duplicated_questions;
};
// Template has to be in the same file or impossible to not have circular references
export interface COSectionTemplate extends COSectionTemplateInterface {}
export class COSectionTemplate extends COBaseTemplate {
  constructor({ propertiesFromJSON }: { propertiesFromJSON?: any }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [],
      objects: [
        {
          objectKey: "co_section_json",
          objectClass: COSection
        }
      ],
      dbClass: COSectionDBClass,
      objectClass: COSectionTemplate
    };
  }
}

export const addSection = (
  params: COAAddSectionInterface
): COSectionInterface => {
  let context: any = params.context;
  let section = params.section;
  let template = params.rawValuesFromTemplate;
  let assessment = params.assessment;
  if (!section && !template) {
    throw new Error("Add Section Requires a Section Object or a Template");
  }
  if (!assessment.co_assessment_sections) {
    assessment.co_assessment_sections = [];
  }
  if (!section) {
    section = new COSection({ context, template });
  }
  assessment.co_assessment_sections?.push(section);
  updatePositionSortOrderForItems(assessment.co_assessment_sections);
  return section;
};
