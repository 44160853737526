import React from "react";
import moment from "moment";
import "./CellDateAssignedMyTasks.scss";
import { withTranslation } from "react-i18next";
import { showDateWithTenantDateFormat } from "../../helpers/timeDateHelper";

const CellDateAssignedMyTasks = props => {
  const { created_epoch, t } = props;

  return (
    <div
      className="CellDateAssignedMyTasks"
      data-testid="CellDateAssignedMyTasksContainer"
    >
      <span className="CellDateAssignedMyTasks__date-assigned">
        {showDateWithTenantDateFormat(
          created_epoch,
          t("general_data_format_slash", "L")
        )}
      </span>
    </div>
  );
};

export default withTranslation("common")(CellDateAssignedMyTasks);
