import uniqBy from "lodash/uniqBy";

import {
  FETCH_NOTIFICATIONS,
  FETCH_MORE_NOTIFICATIONS,
  FETCH_NOTIFICATION_TYPES,
  CLEAR_NOTIFICATIONS_REDUCER,
  MARK_NOTIFICATION_READ,
  UNMARK_NOTIFICATION_READ,
  SET_IS_LOADING_NOTIFICATIONS,
  SET_IS_LOADING_MORE_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_READ,
  IS_MARKING_ALL_NOTIFICATIONS_READ,
  HAS_MARKED_ALL_NOTIFICATIONS_READ
} from "../constants/actionTypes";

const INITIAL_STATE = {
  notifications: null,
  notificationTypes: null,
  hasMoreNotifications: true,
  isLoadingNotifications: false,
  isLoadingMoreNotifications: false,
  allNotificationsMarkedRead: false,
  isMarkingAllNotificationsRead: false,
  hasMarkedAllNotificationsRead: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS: {
      let hasMoreNotifications = true;
      if (Array.isArray(action.payload) && action.payload.length === 0) {
        hasMoreNotifications = false;
      }
      return {
        ...state,
        notifications: action.payload,
        isLoadingNotifications: false,
        hasMoreNotifications
      };
    }
    case FETCH_MORE_NOTIFICATIONS: {
      let hasMoreNotifications = true;
      if (
        !Array.isArray(state.notifications) &&
        !Array.isArray(action.payload)
      ) {
        return { ...state };
      }
      const allNotifications = uniqBy(
        [...state.notifications, ...action.payload],
        "user_notification_id"
      );
      if (allNotifications.length === state.notifications.length) {
        hasMoreNotifications = false;
      }
      if (state.allNotificationsMarkedRead) {
        allNotifications.forEach(notification => {
          notification.notification_has_viewed = 1;
        });
      }
      return {
        ...state,
        notifications: allNotifications,
        hasMoreNotifications,
        isLoadingMoreNotifications: false
      };
    }
    case SET_IS_LOADING_NOTIFICATIONS: {
      return {
        ...state,
        isLoadingNotifications: action.payload
      };
    }
    case SET_IS_LOADING_MORE_NOTIFICATIONS: {
      return {
        ...state,
        isLoadingMoreNotifications: action.payload
      };
    }
    case FETCH_NOTIFICATION_TYPES: {
      return {
        ...state,
        notificationTypes: action.payload
      };
    }
    case MARK_NOTIFICATION_READ: {
      const notifications = state.notifications.map(obj => {
        if (action.payload.includes(obj.user_notification_id)) {
          obj.notification_has_viewed = 1;
        }
        return obj;
      });
      return {
        ...state,
        notifications: notifications
      };
    }
    case UNMARK_NOTIFICATION_READ: {
      const notifications = state.notifications.map(obj => {
        if (action.payload.includes(obj.user_notification_id)) {
          obj.notification_has_viewed = 0;
        }
        return obj;
      });
      return {
        ...state,
        notifications: notifications
      };
    }
    case MARK_ALL_NOTIFICATIONS_READ: {
      const notifications = state.notifications.map(notification => {
        notification.notification_has_viewed = 1;
        return notification;
      });
      return {
        ...state,
        notifications: notifications,
        allNotificationsMarkedRead: true
      };
    }
    case IS_MARKING_ALL_NOTIFICATIONS_READ: {
      return {
        ...state,
        isMarkingAllNotificationsRead: action.payload
      };
    }
    case HAS_MARKED_ALL_NOTIFICATIONS_READ: {
      return {
        ...state,
        hasMarkedAllNotificationsRead: true
      };
    }
    case CLEAR_NOTIFICATIONS_REDUCER: {
      return {
        ...INITIAL_STATE
      };
    }
    default:
      return state;
  }
}
