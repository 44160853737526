import {
  COAssessmentDBClass,
  COAssessmentInterface,
  COAssessmentTemplateDBClass,
  COAssessmentTemplateInterface,
  COContextInterface,
  CODisplayContext,
  COMetadataInterface,
  COOptionsInterface,
  COPositionInterface,
  COQuestionInterface,
  COControlInterface,
  COProfileBannerInterface
} from "../interfaces/co-interfaces";
import { addSection, COSection } from "./co-assessment-section.class";
import { COBase } from "./co-base.class";
import { objectDeepCopy, optionsInContext } from "../helpers/co-context.helper";
import { COContextObjectKey } from "../constants/co-constants";
import { COBaseTemplate } from "./co-base-template.class";
import { COQuestion } from "./co-question.class";
import { doesExistPositiveContextMatch } from "../utils/co-utils";
import { assessmentBannerFromSlugPayload } from "../templates/elements/banners/co-assessment-banners";
import { isQuestionAnswered } from "../helpers/co-question.helper";

export interface COAssessment extends COAssessmentInterface {}

export class COAssessment extends COBase {
  static getClassName(): string {
    return "COAssessment";
  }
  constructor({
    propertiesFromJSON,
    context,
    template
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
    template?: COAssessmentTemplate;
  }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [
        {
          objectKey: "co_assessment_sections",
          objectClass: COSection
        }
      ],
      objects: [],
      dbClass: COAssessmentDBClass,
      contextKey: COContextObjectKey.ASSESSMENT,
      ahid_key: "co_assessment_ahid",
      objectClass: COAssessment
    };

    Object.defineProperty(this, "version_meta", {
      get() {
        return this.template?.co_version_meta_json || {};
      }
    });

    if (!this.co_assessment_sections) {
      this.co_assessment_sections = [];
    }
    //template means we're loading a new one - not from json
    if (template) {
      this.template = template;
      this[
        this.objectClassMapping.ahid_key || ""
      ] = COAssessment.generateAHID();

      if (template.co_assessment_json) {
        let deepCopy = objectDeepCopy(template.co_assessment_json);

        let keys = Object.keys(deepCopy);
        //assign keys from template
        for (const key of keys) {
          this[key] = deepCopy[key];
        }
      }

      this.co_assessment_sections = [];
      if (template.co_assessment_json?.co_assessment_sections) {
        for (const rawTemplateValues of template.co_assessment_json
          ?.co_assessment_sections) {
          addSection({
            context: COAssessment.setContext(undefined, this),
            assessment: this,
            rawValuesFromTemplate: rawTemplateValues
          });
        }
      }
    }

    this.checkAndLoadTemplate?.();

    // we have all the questions now so we can set up all the equations
    COAssessment.prepareAssessmentEquations(this);
  }

  templateSlug?() {
    return this.co_assessment_template_slug;
  }
  objectMeta?(): COMetadataInterface {
    return this.co_assessment_meta_json || {};
  }
  objectOptions?(): COOptionsInterface {
    return this.co_assessment_options_json || {};
  }
  objectPosition?(): COPositionInterface {
    return {};
  }
  // on assessment this isn't goddamn recursive so we need a way to elemnate the keys we don't want
  // if we send down a template with a function it gets super messy
  // also big payload we don't need
  toJSON?(): any {
    let jsonObject = Object.assign({}, this, {
      // convert fields that need converting
    });
    jsonObject = this.prepareForJSON?.(jsonObject);
    for (const section of jsonObject.co_assessment_sections || []) {
      this.prepareForJSON?.(section);
      for (const question of section.co_questions || []) {
        this.prepareForJSON?.(question);
        if (question.co_process_answer) {
          this.prepareForJSON?.(question.co_process_answer);
          for (const answerSelection of question.co_process_answer
            .co_process_answer_selections || []) {
            this.prepareForJSON?.(answerSelection);
            if (answerSelection.co_question_answer_option) {
              this.prepareForJSON?.(answerSelection.co_question_answer_option);
            }
          }
        }
        if (question.co_process_calculated_value) {
          this.prepareForJSON?.(question.co_process_calculated_value);
        }
        for (const answerOption of question.co_question_answer_options || []) {
          this.prepareForJSON?.(answerOption);
        }
      }
    }
    return jsonObject;
  }

  functionAllQuestions?(context: COContextInterface): COQuestionInterface[] {
    let questionsInContext: COQuestionInterface[] = [];
    for (const section of context.assessment?.co_assessment_sections || []) {
      for (const question of section.co_questions || []) {
        questionsInContext.push(question);
      }
    }
    return questionsInContext;
  }

  functionNumVisibleSections?(context: COContextInterface) {
    let numVisible = 0;
    if (context.assessment) {
      numVisible = (context.assessment.co_assessment_sections || []).filter(s =>
        s.isVisibleInContext?.(context.update?.({ section: s }))
      ).length;
    }
    return numVisible;
  }

  functionSelectedAnswerOptionAhidForQuestion?(
    context: COContextInterface,
    params: { co_question_ahid: string }
  ) {
    if (context.assessment?.findAHId) {
      const question = context.assessment.findAHId(
        params.co_question_ahid
      ) as COQuestionInterface;
      if (
        question &&
        question.co_process_answer?.co_process_answer_selections &&
        question.co_process_answer.co_process_answer_selections.length > 0
      ) {
        const ahids = question.co_process_answer.co_process_answer_selections
          .map(selection => {
            return (
              selection.co_question_answer_option
                ?.co_question_answer_option_ahid ?? ""
            );
          })
          .filter(ahid => ahid !== "");
        if (ahids.length > 0) {
          return ahids.join(",");
        }
      }
    }
    return "";
  }

  functionAreAllRequiredQuestionsAnswered?(
    context: COContextInterface
  ): boolean {
    if (context.assessment) {
      if (context.assessment.co_assessment_sections) {
        for (const section of context.assessment.co_assessment_sections) {
          if (section.co_questions) {
            for (const question of section.co_questions) {
              let options = optionsInContext({
                context,
                options: question.options || {},
                should_resolve: true
              });
              if (
                options.optional_for_submission === 0 &&
                isQuestionAnswered({ context, question }) !== true
              ) {
                // there is an unanswered required question
                return false;
              }
            }
          }
        }
      }
    }
    // all questions answered
    return true;
  }

  functionAreAnyQuestionsAnswered?(context: COContextInterface): boolean {
    if (context.assessment) {
      if (context.assessment.co_assessment_sections) {
        for (const section of context.assessment.co_assessment_sections) {
          if (section.co_questions) {
            for (const question of section.co_questions) {
              if (isQuestionAnswered({ context, question }) === true) {
                // a question is answered
                return true;
              }
            }
          }
        }
      }
    }
    // no questions answered
    return false;
  }

  functionAllVisibleBanners?(
    context: COContextInterface
  ): COProfileBannerInterface[] {
    let banners: COProfileBannerInterface[] = [];
    if (context.assessment?.options?.co_profile_banners) {
      for (const bannerSlug of context.assessment.options.co_profile_banners) {
        const banner = assessmentBannerFromSlugPayload(bannerSlug);
        const bannerOptions = optionsInContext({
          context,
          options: banner.options || {},
          should_resolve: true
        });
        if (bannerOptions.is_hidden !== 1) {
          banners.push(banner);
        }
      }
    }
    return banners;
  }

  //pass down to sections
  calculate?(
    context: COContextInterface,
    throwExceptionOnCalculationError = false
  ): any[] {
    context = COAssessment.setContext(context, this);
    let calculatedQuestions: any[] = [];
    for (const section of this.co_assessment_sections || []) {
      let s: COSection = section;
      if (s.calculate) {
        calculatedQuestions = [...calculatedQuestions, ...s.calculate(context)];
      }
    }
    return calculatedQuestions;
  }

  static calculate({
    context,
    throwExceptionOnCalculationError = false
  }: {
    context?: COContextInterface;
    throwExceptionOnCalculationError?: boolean;
  }): COContextInterface | undefined {
    if (
      !doesExistPositiveContextMatch(context?.display_context || {}, {
        assessment_view: 1,
        assessment_view_sidebar: 1,
        all_pipelines: 1
      } as CODisplayContext)
    ) {
      let assessment: COAssessment | undefined = context?.assessment;
      if (context && assessment) {
        this.prepareForCalculation(context, assessment);
        assessment.calculate?.(context, throwExceptionOnCalculationError);
      }
    } else {
      // console.log(
      //   "can can not calculated in a view context - we may not have all questions due to permission"
      // );
    }
    return context;
  }

  static prepareForCalculation(
    context: COContextInterface,
    assessment?: COAssessment
  ) {
    this.prepareAssessmentEquations(assessment || context.assessment || {});
    super.prepareForCalculation(
      context,
      assessment || context.assessment || {}
    );
  }

  static prepForSubmissionViewOrEdit({
    context,
    assessment
  }: {
    context?: COContextInterface;
    assessment: COAssessment;
  }): COAssessment {
    this.prepareAssessmentEquations(assessment);
    if (!context) {
      context = this.setContext(context, assessment); // sets  new context if undefined
    }
    context = context.update?.({ assessment });
    for (const section of assessment.co_assessment_sections || []) {
      context = context?.update?.({ section });
      COSection.prepForSubmissionViewOrEdit({ context, section });
    }
    return assessment;
  }

  // we need something that runs after all questions are in to do some syncing
  static prepareAssessmentEquations(assessment: COAssessment): COAssessment {
    for (const section of assessment.co_assessment_sections || []) {
      for (const question of section.co_questions || []) {
        let questionObj: COQuestion = question;
        if (
          questionObj.co_display_equation &&
          questionObj.co_display_equation.length > 0
        ) {
          questionObj.updateRawEquation?.({
            context: this.setContext(undefined, assessment),
            displayEquation: questionObj.co_display_equation
          });
        } else if (
          questionObj.co_equation &&
          questionObj.co_equation.length > 0
        ) {
          questionObj.updateDisplayEquation?.({
            context: this.setContext(undefined, assessment),
            rawEquation: questionObj.co_equation
          });
        }
      }
    }
    return assessment;
  }
}

export interface COAssessmentTemplate extends COAssessmentTemplateInterface {}
export class COAssessmentTemplate extends COBaseTemplate {
  static getClassName(): string {
    return "COAssessmentTemplate";
  }
  constructor({
    propertiesFromJSON,
    context
  }: {
    propertiesFromJSON?: any;
    context?: COContextInterface;
  }) {
    super({
      propertiesFromJSON
    });
    this.objectClassMapping = {
      arrays: [],
      objects: [
        {
          objectKey: "co_assessment_json",
          objectClass: COAssessment
        }
      ],
      dbClass: COAssessmentTemplateDBClass,
      objectClass: COAssessmentTemplate
    };
  }
}
