import React, { Component } from "react";
import styled from "styled-components/macro";
import { withTranslation } from "react-i18next";
import "./CellCollaborators.scss";
import unassignedCollaborator from "../../assets/images/ic_collaborator_unassigned.svg";
import Avatar from "../Avatar/Avatar";
import DropdownTethered from "../DropdownTethered/DropdownTethered";
import DropdownCollaborators from "../DropdownCollaborators/DropdownCollaborators";
import IconApollo from "../IconApollo/IconApollo";
import { Tooltip } from "@material-ui/core";
import { getLocalizedRoleName } from "../../utils/userRoleLocalizationUtils";

export const CellCollaboratorsWidth = 180;

const DropdownTetheredWithStyles = styled(DropdownTethered)`
  width: 32px;
  height: 32px;
  display: flex;
`;

class CellCollaborators extends Component {
  generateCard = (person, idx) => {
    if (!person) {
      return (
        <Tooltip
          title={this.props.t([
            "assign_process_owner_unassigned_tooltip",
            "Unassigned Process Owner"
          ])}
          placement="top"
        >
          <div className="CellCollaborators__card" key={`Unassigned`}>
            <div className="CellCollaborators__profile-pic-container">
              <Avatar image={unassignedCollaborator} />
            </div>
          </div>
        </Tooltip>
      );
    }

    const { user_profile_slug, user_first_name = "", user_last_name = "" } =
      person || {};

    const profilePicture = <Avatar user={person} />;

    const fullName = `${user_first_name}${
      user_last_name ? " " + user_last_name : user_last_name
    }`;

    const role = (person.user_roles || []).map(role => {
      if (role.role_is_visible && role.process_id > 0) {
        //don't show generic assignable roles if they're coming down
        return " " + getLocalizedRoleName(this.props.t, role, role.role_name);
      } else {
        return "";
      }
    });

    let roleString = "";
    for (const roleName of role) {
      if (roleName) {
        if (roleString.length > 0) {
          roleString += ",";
        }
        roleString += roleName;
      }
    }

    const roleAndName =
      role.length !== 0 ? fullName + "," + roleString : fullName;

    return (
      <Tooltip
        title={roleAndName}
        placement="top"
        key={`${user_profile_slug}-${idx}`}
      >
        <div className="CellCollaborators__card">
          <div className="CellCollaborators__profile-pic-container">
            {profilePicture}
          </div>
        </div>
      </Tooltip>
    );
  };

  generateCards = props => {
    const {
      process_num_collaborators,
      collaborators = [],
      getAutomationCollaborators,
      process_slug,
      collaboratorsShowLimit = 2,
      collaboratorsForDropdown
    } = props || {};

    let cards = [];
    if (this.props.process_owner === null) {
      cards.push(this.generateCard(null, -1));
    }

    if (Array.isArray(collaborators)) {
      for (let idx = 0; idx < collaborators.length; idx++) {
        const person = collaborators[idx];
        if (idx === collaboratorsShowLimit) {
          // exit if collaboratorsShowLimit reaches.
          break;
        }
        cards.push(this.generateCard(person, idx));
      }
    }

    if (process_num_collaborators > 2) {
      cards.push(
        <DropdownTetheredWithStyles
          closeOnOutsideClickOnly
          baseComponent={
            <button
              className="CellCollaborators__card CellCollaborators__card--more-users"
              onClick={e => getAutomationCollaborators(e, process_slug)}
            >
              <IconApollo
                className="CellCollaborators__img"
                icon="people"
                srText={this.props.t([
                  "cell_collaborators_more_collaborators_icon_sr_text",
                  "More Collaborators"
                ])}
                outlined
              />
            </button>
          }
          key="user-last"
        >
          <DropdownCollaborators collaborators={collaboratorsForDropdown} />
        </DropdownTetheredWithStyles>
      );
    }

    return cards;
  };

  render() {
    return (
      <div
        className="CellCollaborators"
        data-testid={"CellCollaboratorsContainer"}
      >
        <div className="CellCollaborators__main">
          {this.generateCards(this.props)}
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(CellCollaborators);
