import { COSection } from "../classes/co-assessment-section.class";
import { COAssessment } from "../classes/co-assessment.class";
import { COContext } from "../classes/co-context.class";
import { COProcessAnswerSelection } from "../classes/co-process-answer-selection.class";
import { COQuestion } from "../classes/co-question.class";
import { CO_NON_TABULAR_DEFAULT_INDEX } from "../constants/co-constants";
import {
  COProcessAnswerSelectionInterface,
  COQuestionAnswerOptionInterface
} from "../interfaces/co-interfaces";
import { getDefaultAssessmentJSON } from "../templates/co-template-loader";
import { isNullOrUndefined } from "../utils/co-utils";
import {
  allAnsweredQuestionsFromAssessments,
  allQuestions
} from "./co-assessment.helper";
import { objectDeepCopy } from "./co-context.helper";

// do we match against any answered question ever - like 4 versions back? ya?

export const testMatchingSystem = () => {
  let assessment: COAssessment | null = COAssessment.objectFromJSON(
    getDefaultAssessmentJSON()
  );

  let secondAssessment: COAssessment | null = COAssessment.prepForSubmissionViewOrEdit(
    {
      assessment: COAssessment.objectFromJSON(getDefaultAssessmentJSON()) || {}
    }
  );

  if (assessment && secondAssessment) {
    let previouslyAnsweredQuestions: COQuestion[] = allAnsweredQuestionsFromAssessments(
      [secondAssessment]
    );
    let results = answerAssessmentFromExistingQuestions({
      assessment,
      previouslyAnsweredQuestions
    });
    console.log(results);
  }
};

export const answerAssessmentFromExistingQuestions = (
  {
    assessment,
    previouslyAnsweredQuestions
  }: { assessment: COAssessment; previouslyAnsweredQuestions: COQuestion[] } // will get from backend but frontend could load as well
): {
  assessment: COAssessment;
  answeredQuestions: COQuestion[];
  unAnsweredQuestions: COQuestion[];
} => {
  // so basically we're filling out the assessment with previous Questions that have been answered from other assessments
  // need to deal with the "latest answer" - "close enough" - answer options changing etc
  // tools - the Variable Name Match - the ahids could be the same -
  COAssessment.prepForSubmissionViewOrEdit({ assessment });

  let context = new COContext({ assessment });

  // first let's prepare the assessment
  let answeredQuestions: COQuestion[] = [];
  let unAnsweredQuestions: COQuestion[] = [];
  let allQuestionsToAnswer = allQuestions(assessment);

  // allQuestionsToAnswer - this doesn't include indexed section stuff
  // previouslyAnsweredQuestions this includes the table index stuff
  // so the section really matters here - we need to know if it's tabular or not

  for (const question of allQuestionsToAnswer) {
    // console.log(
    //   `Looking For Match for Question ${question.co_question_meta_json?.title?.value}`
    // );

    // need to see if this answer is in tabular format
    let is_tabular_question = false;
    let sectionForQuestion: COSection | null | undefined | "" =
      question.co_question_parent_ahid &&
      assessment.findAHId?.(question.co_question_parent_ahid);
    if (sectionForQuestion) {
      is_tabular_question = sectionForQuestion.co_section_options_json
        ?.is_tabular
        ? true
        : false;
    }

    // first we need all of the distinct questions so we get the top row (tabular or not tabular)
    const distinctPreviouslyAnsweredQuestions = previouslyAnsweredQuestions.filter(
      q => [0, -1].includes(parseInt(q.co_table_row_index + ""))
    );
    // first we find the best match - ignoring the tabular index - looking for 0 or -1
    let bestMatch = bestQuestionAnswerMatchFromExistingQuestions({
      question,
      previouslyAnsweredQuestions: distinctPreviouslyAnsweredQuestions
    });

    if (bestMatch) {
      if (bestMatch.bestQuestionMatch) {
        // console.log(
        //   `Match Found for Question ${question.co_question_meta_json?.title?.value} - ${bestMatch.bestQuestionMatch.co_question_meta_json?.title?.value} `
        // );

        //if it's tabular
        if (is_tabular_question) {
          // get max index to see how many answers we need to look for
          let max_tabular_index_for_question = 0;
          for (const qu of previouslyAnsweredQuestions.filter(
            q =>
              q.co_question_ahid ===
              bestMatch.bestQuestionMatch?.co_question_ahid
          )) {
            const idx = parseInt(qu.co_table_row_index + "");
            if (idx > max_tabular_index_for_question) {
              max_tabular_index_for_question = idx;
            }
          }

          for (
            let index = 0;
            index < max_tabular_index_for_question + 1;
            index++
          ) {
            // find the best match but on the tabular index we want

            let tabular_index_array: number[] = [index];
            if (index === 0) {
              tabular_index_array.push(CO_NON_TABULAR_DEFAULT_INDEX); // to allow non tabular to tabular
            }

            const tabularBestMatch = bestQuestionAnswerMatchFromExistingQuestions(
              {
                question,
                previouslyAnsweredQuestions: previouslyAnsweredQuestions.filter(
                  q =>
                    tabular_index_array.includes(
                      parseInt(q.co_table_row_index + "")
                    )
                )
              }
            );

            // we want to add the question regardless if we find a best match - this is for if you're adding a new question that's not in the previous assessment, we don't want to have the columns to not exist

            let questionCopy: COQuestion | null = COQuestion.objectFromJSON(
              JSON.parse(JSON.stringify(question))
            );
            if (questionCopy) {
              if (tabularBestMatch && tabularBestMatch.bestQuestionMatch) {
                tabularBestMatch.bestQuestionMatch.co_table_row_index = index;
                answerQuestionWithQuestion({
                  context,
                  question: questionCopy,
                  answerQuestion: tabularBestMatch.bestQuestionMatch
                });
                questionCopy.setQuestionTableRowIndex?.(index);
              } else {
                questionCopy.setQuestionTableRowIndex?.(index);
              }
              // need to insert this into the proper section
              if (sectionForQuestion) {
                if (index === 0) {
                  // remove all questions of this ahid
                  sectionForQuestion.co_questions = (
                    sectionForQuestion.co_questions || []
                  ).filter(
                    q => q.co_question_ahid !== questionCopy?.co_question_ahid
                  );
                }
                sectionForQuestion.co_questions?.push(questionCopy);
              }

              answeredQuestions.push(questionCopy);
            }
          }
        } else {
          bestMatch.bestQuestionMatch.co_table_row_index = CO_NON_TABULAR_DEFAULT_INDEX;
          answerQuestionWithQuestion({
            context,
            question,
            answerQuestion: bestMatch.bestQuestionMatch
          });
          answeredQuestions.push(question);
        }
      } else {
        unAnsweredQuestions.push(question);
      }
    }
  }

  return { assessment, answeredQuestions, unAnsweredQuestions };
};

const bestQuestionAnswerMatchFromExistingQuestions = ({
  question,
  previouslyAnsweredQuestions
}: {
  question: COQuestion;
  previouslyAnsweredQuestions: COQuestion[];
}): {
  bestQuestionMatch: COQuestion | null;
  comparisonResult: COQuestionComparisonResult | null;
} => {
  // computer
  let allComparisonResults: COQuestionComparisonResult[] = [];
  // let's only use answered ones
  for (const prevQuestion of previouslyAnsweredQuestions) {
    let comparisonResult = compareQuestions(question, prevQuestion);
    if (comparisonResult.question_is_answered) {
      allComparisonResults.push(comparisonResult);
    }
  }
  allComparisonResults = allComparisonResults.sort((a, b) => {
    return a.question_answered_epoch - b.question_answered_epoch;
  });

  // ok we have all of these comparison things
  // do we make a scoring system???
  // do we care about the most recent answer first
  // let's start by just finding the questions with the same variables and then make more elaborate later
  let with_variable_match = allComparisonResults.filter(
    cr =>
      cr.question_variable &&
      cr.question_template &&
      cr.anySelectedBAnswersExistInAByValue
  );
  if (with_variable_match.length > 0) {
    let match = with_variable_match[0];
    return {
      bestQuestionMatch: match.comparison_question,
      comparisonResult: match
    };
  }

  // ok next up would be the ahid - we can see if the ahid is the same
  let with_ahid_match = allComparisonResults.filter(
    cr =>
      cr.question_ahid &&
      cr.question_template &&
      cr.anySelectedBAnswersExistInAByValue
  );
  if (with_ahid_match.length > 0) {
    let match = with_ahid_match[0];
    return {
      bestQuestionMatch: match.comparison_question,
      comparisonResult: match
    };
  }

  return { bestQuestionMatch: null, comparisonResult: null };
};

interface COQuestionComparisonResult {
  original_question: COQuestion;
  comparison_question: COQuestion;
  question_ahid: boolean;
  assessment_id: boolean;
  question_equation: boolean;
  question_variable: boolean;
  question_template: boolean;
  question_unit: boolean;
  question_is_answered: boolean;
  question_options_count: boolean;
  question_answered_epoch: number;
  allSelectedBAnswersExistInAByAHID: boolean;
  anySelectedBAnswersExistInAByAHID: boolean;
  allSelectedBAnswersExistInAByValue: boolean;
  anySelectedBAnswersExistInAByValue: boolean;
}

const compareQuestions = (
  a: COQuestion,
  b: COQuestion
): COQuestionComparisonResult => {
  let cr: COQuestionComparisonResult = {
    original_question: a,
    comparison_question: b,
    question_ahid: false,
    assessment_id: false,
    question_equation: false,
    question_variable: false,
    question_template: false,
    question_unit: false,
    question_is_answered: false,
    question_options_count: false,
    question_answered_epoch: 0,
    allSelectedBAnswersExistInAByAHID: false,
    anySelectedBAnswersExistInAByAHID: false,
    allSelectedBAnswersExistInAByValue: false,
    anySelectedBAnswersExistInAByValue: false
  };

  cr.question_ahid = a.co_question_ahid === b.co_question_ahid;
  cr.assessment_id = a.co_assessment_id === b.co_assessment_id;
  cr.question_equation = a.co_equation === b.co_equation;
  cr.question_template =
    a.co_question_template_slug === b.co_question_template_slug;
  cr.question_variable = a.co_variable_name === b.co_variable_name;
  cr.question_unit =
    a.co_question_options_json?.unit === b.co_question_options_json?.unit;

  let processAnswer = b.co_process_answer;
  let processAnswerSelections =
    processAnswer?.co_process_answer_selections || [];

  cr.question_is_answered =
    processAnswerSelections.length > 0 &&
    !isNullOrUndefined(
      processAnswerSelections[0].co_question_answer_option_value
    );
  if (cr.question_is_answered) {
    cr.question_answered_epoch = processAnswer?.created_epoch || 0;
  }

  cr.question_options_count =
    a.co_question_answer_options?.length ===
    b.co_question_answer_options?.length;

  let selectedAnswerOptionMatchesByAHID: COProcessAnswerSelection[] = [];
  for (const selectedAnswer of processAnswerSelections) {
    let foundMatch = a.co_question_answer_options?.find(
      a =>
        a.co_question_answer_option_ahid ===
        selectedAnswer.co_question_answer_option_ahid
    );
    if (foundMatch) {
      selectedAnswerOptionMatchesByAHID.push(foundMatch);
    }
  }
  let selectedAnswerOptionMatchesByAnswerValue: COProcessAnswerSelection[] = [];
  for (const selectedAnswer of processAnswerSelections) {
    let foundAnswerOptionMatch = findAnswerSelectionInOptions(
      a.co_question_answer_options || [],
      selectedAnswer
    );
    if (foundAnswerOptionMatch) {
      selectedAnswerOptionMatchesByAnswerValue.push(foundAnswerOptionMatch);
    }
  }

  cr.allSelectedBAnswersExistInAByAHID =
    selectedAnswerOptionMatchesByAHID.length === processAnswerSelections.length;
  cr.anySelectedBAnswersExistInAByAHID =
    selectedAnswerOptionMatchesByAHID.length > 0;
  cr.allSelectedBAnswersExistInAByValue =
    selectedAnswerOptionMatchesByAnswerValue.length ===
    processAnswerSelections.length;
  cr.anySelectedBAnswersExistInAByValue =
    selectedAnswerOptionMatchesByAnswerValue.length > 0;

  return cr;
};

export const answerQuestionWithQuestion = ({
  context,
  question,
  answerQuestion
}: {
  context: COContext;
  question: COQuestion;
  answerQuestion: COQuestion;
}) => {
  let answerQuestionProcessAnswer = answerQuestion.co_process_answer;
  let answerQuestionProcessAnswerSelections =
    answerQuestionProcessAnswer?.co_process_answer_selections;
  if (answerQuestionProcessAnswerSelections?.length === 0) {
    throw new Error(
      `NO PROCESS ANSWER SELECTIONS FOUND FOR ANSWER TO Question ${question.co_question_meta_json?.title?.value}`
    );
  }

  if (!question.co_process_answer) {
    throw new Error(
      `WARNING NO PROCESS ANSWER FOUND FOR QUESTION TO ANSWER ${question.co_question_meta_json?.title?.value}`
    );
  }

  let questionNewProcessAnswerSelections: COProcessAnswerSelection[] = [];
  let questionAnswerOptions = question.co_question_answer_options || [];

  for (const answerSelection of answerQuestionProcessAnswerSelections || []) {
    let foundAnswerOption:
      | COQuestionAnswerOptionInterface
      | undefined = undefined;
    let answerOptionToFind = answerSelection.co_question_answer_option;
    if (answerOptionToFind) {
      foundAnswerOption = findAnswerSelectionInOptions(
        questionAnswerOptions,
        answerSelection,
        questionNewProcessAnswerSelections
      );
    }
    if (foundAnswerOption) {
      let newProcessAnswerSelection = new COProcessAnswerSelection({
        context,
        processAnswer: question.co_process_answer,
        answerOption: foundAnswerOption
      });

      newProcessAnswerSelection.co_question_answer_option_value =
        answerSelection.co_question_answer_option_value;
      newProcessAnswerSelection.co_process_answer_selection_input =
        answerSelection.co_process_answer_selection_input;
      if (answerSelection.co_process_answer_selection_info_json) {
        newProcessAnswerSelection.co_process_answer_selection_info_json =
          answerSelection.co_process_answer_selection_info_json;
      }

      questionNewProcessAnswerSelections.push(newProcessAnswerSelection);
    }
  }

  // we only want to use this answer if we have a selected option
  if (questionNewProcessAnswerSelections.length > 0) {
    question.co_process_answer.co_process_answer_selections = questionNewProcessAnswerSelections;
    question.co_process_answer.user_id = answerQuestionProcessAnswer?.user_id;
    question.co_process_answer.created_epoch =
      answerQuestionProcessAnswer?.created_epoch;
    if (question.co_process_answer.co_process_answer_meta_json) {
      question.co_process_answer.co_process_answer_meta_json.comment =
        answerQuestionProcessAnswer?.co_process_answer_meta_json?.comment;
    }
  }

  // also update table row index
  question.setQuestionTableRowIndex?.(answerQuestion.co_table_row_index);
};

export const findAnswerSelectionInOptions = (
  co_question_answer_options: COQuestionAnswerOptionInterface[],
  selectedAnswer: COProcessAnswerSelectionInterface,
  alreadyProcessedAnswerSelections?: COProcessAnswerSelectionInterface[]
) => {
  let foundAnswerOptionMatch:
    | COQuestionAnswerOptionInterface
    | undefined = undefined;
  let strongMatchFound = false;
  for (const answerOption of co_question_answer_options || []) {
    if (answerOption.calculation_options?.input_is_value) {
      if (
        selectedAnswer.co_question_answer_option?.calculation_options
          ?.input_is_value
      ) {
        if (
          answerOption.co_question_answer_option_template_slug ===
          selectedAnswer.co_question_answer_option
            .co_question_answer_option_template_slug
        ) {
          foundAnswerOptionMatch = answerOption;
        }
      }
    } else {
      let valueMatch = false;
      let titleMatch = false;

      let selectedAnswerTitle =
        selectedAnswer.co_question_answer_option
          ?.co_question_answer_option_meta_json?.title?.value;
      let answerOptionTitle =
        answerOption?.co_question_answer_option_meta_json?.title?.value;

      if (
        selectedAnswerTitle &&
        answerOptionTitle &&
        // we don't want to compare co_question_answer_option_value only
        // we also need to compare titles as well
        selectedAnswerTitle == answerOptionTitle
      ) {
        // mult choice - but based on title
        titleMatch = true;
      }

      let answerOptionValue = answerOption.co_question_answer_option_value;
      let selectedAnswerValue =
        selectedAnswer.co_question_answer_option
          ?.co_question_answer_option_value;

      if (answerOptionValue == selectedAnswerValue) {
        // selection option choice
        // make sure not falsy - but zero might be an answer value
        valueMatch = true;
      }

      if (valueMatch && titleMatch) {
        strongMatchFound = true;
        foundAnswerOptionMatch = answerOption;
      } else if (
        (titleMatch || valueMatch) &&
        (!strongMatchFound || !foundAnswerOptionMatch)
      ) {
        foundAnswerOptionMatch = answerOption;
      }
    }
    // but the values might all be 0 or something
    if (
      foundAnswerOptionMatch &&
      (alreadyProcessedAnswerSelections || []).find(
        as => as.co_question_answer_option === foundAnswerOptionMatch
      )
    ) {
      // we don't want to select the same answer option 2x
      // so continue the hunt
      foundAnswerOptionMatch = undefined;
    }
  }
  return foundAnswerOptionMatch;
};
