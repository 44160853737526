import React, { ReactNode } from "react";
import styled, { css } from "styled-components/macro";
import SessionHelper from "../../helpers/SessionHelper";
import permissionErrorImage from "../../assets/images/page-errors/permission-error.svg";
import permissionErrorDarkImage from "../../assets/images/page-errors/permission-error--dark.svg";
import { useTranslation } from "react-i18next";
import ButtonApolloPrimary from "../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import ImageThemed from "../ImageThemed/ImageThemed";

const NotAdminPageContainer = styled.div(
  props => css`
    display: flex;
    width: 1240px;
    padding: 0 ${props.theme.sizes["container-side-padding"]};
    margin: 50px 0;
  `
);

const NotAdminPageContainerLeft = styled.div`
  width: 50%;
  margin-top: 300px;
`;

const NotAdminPageTitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
`;

const NotAdminPageSubtitle = styled.p(
  props => css`
    font-size: 14px;
    line-height: 20px;
    color: var(--color-foreground-de-emp, ${props.theme.colors.ink550});
    margin-top: 20px;
  `
);

const ButtonConfirm = styled(ButtonApolloPrimary)`
  &&&& {
    min-height: 32px;
    margin-top: 20px;
    min-width: 200px;
  }
`;

const FpsLoginError = ({
  errorHeader,
  errorBody
}: {
  errorHeader?: string | ReactNode;
  errorBody?: string | ReactNode;
}) => {
  const { t } = useTranslation("common");

  const goToCloudHome = () => {
    const p = window.location.pathname;
    const currentFpsService = SessionHelper.currentFpsService();
    const pathWithoutService = p.replace(
      new RegExp(`${currentFpsService}.*$`, "i"),
      ""
    );

    const accountName =
      pathWithoutService
        .split("/")
        //filter out white space chars
        .filter(path => !!path)?.[0] || "";
    const finalUrl = `/${accountName}/portal_/home`;
    window.location.assign(finalUrl);
  };

  let errorHeaderContent =
    errorHeader ||
    t(["fps_login_error_not_on_list", "Oops! You are not in my list"]);
  let errorBodyContent =
    errorBody ||
    t([
      "fps_login_error_no_permission_to_view_page",
      "You don't have permission to view this page on this tenant. Please contact your administrator or try switching to another tenant from top left for your needs."
    ]);

  return (
    <NotAdminPageContainer data-testid="FpsLoginError">
      <NotAdminPageContainerLeft>
        <NotAdminPageTitle>{errorHeaderContent}</NotAdminPageTitle>
        <NotAdminPageSubtitle>{errorBodyContent}</NotAdminPageSubtitle>
        <div>
          <ButtonConfirm onClick={goToCloudHome}>
            {t(["fps_login_error_go_home_button", "Go to Home Page"])}
          </ButtonConfirm>
        </div>
      </NotAdminPageContainerLeft>
      <div>
        <ImageThemed
          src={permissionErrorImage}
          srcDark={permissionErrorDarkImage}
          alt=""
        />
      </div>
    </NotAdminPageContainer>
  );
};

export default FpsLoginError;
