import { COPhaseStatusKeys } from "./co-phase-status.constants";

export enum COAssessmentTypes {
  OVERVIEW_ASSESSMENT_TYPE = "OVR",
  HIGH_LEVEL_ASSESSMENT_TYPE = "Q1",
  DETAILED_ASSESSMENT_TYPE = "Q2",
  CITIZEN_DEVELOPER_ASSESSMENT_TYPE = "Q3",
  COST_BENEFIT_ANALYSIS_ASSESSMENT_TYPE = "CBA",
  MISC_ASSESSMENT_TYPE = "MISC",
  STATS_ASSESSMENT_TYPE = "STATS",
  CHANGE_REQUEST_ASSESSMENT_TYPE = "CR"
}

export const defaultAssessmentTypes: COAssessmentTypes[] = [
  COAssessmentTypes.OVERVIEW_ASSESSMENT_TYPE,
  COAssessmentTypes.HIGH_LEVEL_ASSESSMENT_TYPE,
  COAssessmentTypes.DETAILED_ASSESSMENT_TYPE,
  COAssessmentTypes.CITIZEN_DEVELOPER_ASSESSMENT_TYPE,
  COAssessmentTypes.COST_BENEFIT_ANALYSIS_ASSESSMENT_TYPE,
  COAssessmentTypes.MISC_ASSESSMENT_TYPE,
  COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE,
  COAssessmentTypes.STATS_ASSESSMENT_TYPE
];

export const COAssessmentTypeInfo: {
  [key: string]: {
    title: string;
    localization_key: string;
    order: number;
    is_customizable: number;
    assessment_version_pipeline_column?: string;
    is_unable_to_start_fresh?: boolean;
    is_unable_to_start_from_upload?: boolean;
    assessment_preview_default_phase_status_key?: COPhaseStatusKeys;
  };
} = {
  [COAssessmentTypes.OVERVIEW_ASSESSMENT_TYPE]: {
    title: "Overview Assessment",
    localization_key: "co_assessment_type_overview",
    order: 0,
    is_customizable: 1,
    assessment_version_pipeline_column: "overview_assessment_version",
    is_unable_to_start_fresh: true,
    is_unable_to_start_from_upload: true,
    assessment_preview_default_phase_status_key:
      COPhaseStatusKeys.INITIAL_SUBMISSION
  },
  [COAssessmentTypes.HIGH_LEVEL_ASSESSMENT_TYPE]: {
    title: "High Level Assessment",
    localization_key: "co_assessment_type_high_level",
    order: 2,
    is_customizable: 1,
    assessment_version_pipeline_column: "high_level_assessment_version",
    assessment_preview_default_phase_status_key:
      COPhaseStatusKeys.INITIAL_SUBMISSION
  },
  [COAssessmentTypes.DETAILED_ASSESSMENT_TYPE]: {
    title: "Detailed Assessment",
    localization_key: "co_assessment_type_detailed",
    order: 3,
    is_customizable: 1,
    assessment_version_pipeline_column: "detailed_assessment_version",
    assessment_preview_default_phase_status_key: COPhaseStatusKeys.LIVE_ONGOING
  },
  [COAssessmentTypes.CITIZEN_DEVELOPER_ASSESSMENT_TYPE]: {
    title: "Citizen Developer Assessment",
    localization_key: "co_assessment_type_citizen_developer",
    order: 4,
    is_customizable: 1,
    assessment_version_pipeline_column: "automation_assessment_version",
    assessment_preview_default_phase_status_key:
      COPhaseStatusKeys.INITIAL_SUBMISSION
  },
  [COAssessmentTypes.COST_BENEFIT_ANALYSIS_ASSESSMENT_TYPE]: {
    title: "CBA Assessment",
    localization_key: "co_assessment_type_cba",
    order: -1,
    is_customizable: 0
  },
  [COAssessmentTypes.MISC_ASSESSMENT_TYPE]: {
    title: "Misc Assessment",
    localization_key: "co_assessment_type_misc",
    order: 5,
    is_customizable: 1,
    assessment_version_pipeline_column: "misc_assessment_version",
    assessment_preview_default_phase_status_key: COPhaseStatusKeys.LIVE_ONGOING
  },
  [COAssessmentTypes.STATS_ASSESSMENT_TYPE]: {
    title: "Stats Assessment",
    localization_key: "co_assessment_type_stats",
    order: -1,
    is_customizable: 0
  },
  [COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE]: {
    title: "Change Request Assessment",
    localization_key: "co_assessment_type_change_request",
    order: 1,
    is_customizable: 1,
    assessment_version_pipeline_column: "change_request_assessment_version",
    assessment_preview_default_phase_status_key:
      COPhaseStatusKeys.INITIAL_SUBMISSION
  }
};

export const COTypes = {
  QUESTION: "question",
  KPI: "kpi",
  CHART: "chart",
  REFERENCE: "reference" // this is used for stats - things that dont' use equations to calculate their values
};

export const COErrorTypes = {
  ERROR: "error",
  WARNING: "warning",
  IMPACTS_CALCULATION: "impacts-calculation"
};

export enum COValidationType {
  VALIDATION_TYPE_MAX_NUMERICAL_VALUE = "max_numerical_value",
  VALIDATION_TYPE_MIN_NUMERICAL_VALUE = "min_numerical_value",
  VALIDATION_TYPE_MAX_STRING_LENGTH = "MAX_STRING_LENGTH",
  VALIDATION_TYPE_MIN_STRING_LENGTH = "MIN_STRING_LENGTH",
  VALIDATION_TYPE_MAX_ARRAY_LENGTH = "MAX_ITEM_QUANTITY",
  VALIDATION_TYPE_MIN_ARRAY_LENGTH = "MIN_ITEM_QUANTITY",
  VALIDATION_TYPE_EQUALS = "VALIDATION_TYPE_EQUALS",
  VALIDATION_TYPE_ROUND_TO_DECIMAL = "round_to_decimal",
  VALIDATION_TYPE_UPPERCASE = "uppercase",
  VALIDATION_TYPE_NUMBER = "number",
  VALIDATION_TYPE_FUNCTION = "function",
  VALIDATION_TYPE_IS_NUMERICAL = "is_numerical",
  VALIDATION_TYPE_IS_POSITIVE_NUMERICAL = "is_positive_numerical",
  VALIDATION_TYPE_ERROR_FOR_DECIMAL = "error_num_decimal_places"
}

export enum COComponentType {
  META_TEXT = "META_TEXT",
  META_LABEL_TEXT = "META_LABEL_TEXT",
  META_H1_TEXT = "META_H1_TEXT",
  META_H2_TEXT = "META_H2_TEXT",
  META_H3_TEXT = "META_H3_TEXT",
  META_H4_TEXT = "META_H4_TEXT",
  META_H5_TEXT = "META_H5_TEXT",
  META_H6_TEXT = "META_H6_TEXT",
  META_PARAGRAPH_TEXT = "META_PARAGRAPH_TEXT",
  META_EDITABLE_TEXT = "META_EDITABLE_TEXT",
  META_EDITABLE_H1_TEXT = "META_EDITABLE_H1_TEXT",
  META_EDITABLE_H2_TEXT = "META_EDITABLE_H2_TEXT",
  META_EDITABLE_H3_TEXT = "META_EDITABLE_H3_TEXT",
  META_EDITABLE_PARAGRAPH_TEXT = "META_EDITABLE_PARAGRAPH_TEXT",
  META_EPOCH_DATE_INPUT = "META_EPOCH_DATE_INPUT",
  META_EPOCH_DATE_VIEW = "META_EPOCH_DATE_VIEW",
  META_PROCESS_LINK_VIEW = "META_PROCESS_LINK_VIEW",
  META_PROCESS_LINK_EDIT = "META_PROCESS_LINK_EDIT",
  META_ORCHESTRATOR_PACKAGE_MAPPING_INPUT = "META_ORCHESTRATOR_PACKAGE_MAPPING_INPUT",
  META_ORCHESTRATOR_PACKAGE_MAPPING_VIEW = "META_ORCHESTRATOR_PACKAGE_MAPPING_VIEW",
  ANSWER_OPTION_TEXT_INPUT = "ANSWER_OPTION_TEXT_INPUT",
  ANSWER_OPTION_MULTIPLE_CHOICE = "ANSWER_OPTION_MULTIPLE_CHOICE",
  ANSWER_OPTION_SINGLE_CHOICE = "ANSWER_OPTION_SINGLE_CHOICE",
  ANSWER_OPTION_USER = "ANSWER_OPTION_USER",
  ANSWER_OPTION_DOCUMENT = "ANSWER_OPTION_DOCUMENT",
  ANSWER_OPTION_DATE_INPUT = "ANSWER_OPTION_DATE_INPUT",
  ANSWER_OPTION_PROCESS_LINK = "ANSWER_OPTION_PROCESS_LINK",
  ANSWER_OPTION_ORCHESTRATOR_PACKAGE_MAPPING = "ANSWER_OPTION_ORCHESTRATOR_PACKAGE_MAPPING",
  QUESTION_TABLE_ROW = "QUESTION_TABLE_ROW",
  QUESTION_STANDALONE = "QUESTION_STANDALONE",
  QUESTION_APPLICATIONS = "QUESTION_APPLICATIONS",
  QUESTION_USER_PICKER = "QUESTION_USER_PICKER",
  QUESTION_MEDIA = "QUESTION_MEDIA",
  QUESTION_AUTOMATIONS = "QUESTION_AUTOMATIONS",
  QUESTION_COMPONENTS = "QUESTION_COMPONENTS",
  QUESTION_CATEGORIES = "QUESTION_CATEGORIES",
  QUESTION_TAGS = "QUESTION_TAGS",
  QUESTION_PROCESS_PROPERTY = "QUESTION_PROCESS_PROPERTY",
  QUESTION_COLLABORATORS = "QUESTION_COLLABORATORS",
  LINK = "LINK",
  KPI_TABLE_ROW = "KPI_TABLE_ROW",
  KPI_CHART = "KPI_CHART",
  SECTION_TABLE = "SECTION_TABLE",
  META_CONTROL = "META_CONTROL",
  COMPOSITE_CONTROL = "COMPOSITE_CONTROL",
  CONTROL_PICKER = "CONTROL_PICKER",
  CONTROL_BUTTON = "CONTROL_BUTTON",
  CONTROL_TOGGLE = "CONTROL_TOGGLE",
  CONTROL_TEXT = "CONTROL_TEXT",
  META_VARIABLE_CONFIG = "META_VARIABLE_CONFIG",
  CONTROL_BANNER = "CONTROL_BANNER"
}

export enum COComponentStyles {
  DEFAULT = "DEFAULT",
  PILL = "PILL"
}

export enum CORichTextOptions {
  default = "DEFAULT",
  none = "NONE"
}

// export interface COExternalAnswerSourceInterface {
//   path?: string;
//   item_key?: string;
//   save_on_calc?: boolean;
// }

export enum COExternalAnswerSources {
  STATS = "STATS",
  APPLICATIONS = "context.process_external_data.application_data.applications", //TODO migration to update this on existing Q2s
  MEDIA = "context.process_external_data.media_data.media",
  PROCESS_OWNER = "context.process_external_data.collaborator_data.process_owner",
  PROCESS_SUBMITTER = "context.process_external_data.collaborator_data.process_submitter",
  SIMILAR_AUTOMATIONS = "context.process_external_data.similar_automations.processes",
  AUTO_GENERATED_ID = "context.process_external_data.process_id",
  PHASE_STATUS_KEY = "context.process_external_data.process.phase_status_key",
  PROCESS_PRIORITY = "context.process_external_data.process.priority",
  PROCESS_DEVELOPMENT_TYPE = "context.process_external_data.process.process_development_type",
  COMPONENTS = "context.process_external_data.component_data.components",
  TAGS = "context.process_external_data.tag_data.tags",
  CATEGORIES = "context.process_external_data.category_data",
  COLLABORATORS = "context.process_external_data.collaborator_data.collaborators",
  PROCESS_SUBMISSION_TYPE = "context.process_external_data.process.process_submission_type"
}
export const COExternalAnswerSourceIDColumnForSource = {
  [COExternalAnswerSources.APPLICATIONS]: "application_id", //TODO migration to update this on existing Q2s
  [COExternalAnswerSources.MEDIA]: "file.file_id",
  [COExternalAnswerSources.PROCESS_OWNER]: "user_id",
  [COExternalAnswerSources.PROCESS_SUBMITTER]: "user_id",
  [COExternalAnswerSources.COMPONENTS]: "component_id",
  [COExternalAnswerSources.TAGS]: "tag_id",
  [COExternalAnswerSources.CATEGORIES]: "category_id",
  [COExternalAnswerSources.COLLABORATORS]: "user_id", // !!!! not really sure what this is
  [COExternalAnswerSources.SIMILAR_AUTOMATIONS]: "process_id" // last because that key could exist first on some table
};

export const CO_DISPLAY_VALUE_SUFFIX = "__display";

export const PIPELINE_COLUMN_NAME = {
  PROCESS_NAME: "process_name",
  DESCRIPTION: "description",
  DEPARTMENT: "department",
  BUSINESS_UNIT: "business_unit",
  PROCESS_GOAL: "process_goal",
  SOFTWARE_VENDOR: "software_vendor",
  LICENSE_TYPE: "license_type",
  PROCESS_TYPE: "process_type",
  PHASE: "phase",
  PHASE_STATUS: "phase_status",
  COLLABORATORS: "collaborators",
  NUMBER_OF_VOTES: "number_of_votes",
  IDEA_SCORE: "idea_score",
  AUTOMATION_POTENTIAL: "automation_potential",
  PROCESS_EASE_OF_IMPLEMENTATION: "process_ease_of_implementation_score",
  PROCESS_EASE_OF_IMPLEMENTATION_LEVEL: "process_ease_of_implementation_level",
  ESTIMATED_BENEFIT_HOURS: "estimated_benefit_hours",
  ESTIMATED_BENEFIT_FTE: "estimated_benefit_fte",
  NUMBER_OF_ROBOTS: "number_of_robots",
  ESTIMATED_BENEFIT_CURRENCY: "estimated_benefit_currency",
  ESTIMATED_IMPLEMENTATION_COSTS: "estimated_implementation_costs",
  ESTIMATED_RUNNING_COSTS: "estimated_running_costs",
  ESTIMATED_NET_BENEFIT_YEAR_ONE: "estimated_net_benefit_year_one",
  ESTIMATED_NET_BENEFIT_YEAR_TWO: "estimated_net_benefit_year_two",
  PDD_LINK: "pdd_link",
  IDEA_PHASE_APPROVER: "idea_phase_approver",
  LAUNCH_ASSESSMENT_APPROVER: "launch_assessment_approver",
  QUALIFICATION_PHASE_APPROVER: "qualification_phase_approver",
  ORCHESTRATOR_AUTOMATION_UUID: "orchestrator_automation_uuid",
  LAUNCH_DATE: "launch_date",
  ANALYSIS_BASELINE: "analysis_baseline",
  ANALYSIS_ACTUAL: "analysis_actual",
  ANALYSIS_DELTA: "analysis_delta",
  SOLUTION_DESIGN_BASELINE: "solution_design_baseline",
  SOLUTION_DESIGN_ACTUAL: "solution_design_actual",
  SOLUTION_DESIGN_DELTA: "solution_design_delta",
  DEVELOPMENT_BASELINE: "development_baseline",
  DEVELOPMENT_ACTUAL: "development_actual",
  DEVELOPMENT_DELTA: "development_delta",
  TESTING_BASELINE: "testing_baseline",
  TESTING_ACTUAL: "testing_actual",
  TESTING_DELTA: "testing_delta",
  ROBOT_WORKING_HOURS_PER_DAY: "robot_working_hours_per_day",
  ROBOT_WORKING_DAYS_PER_YEAR: "robot_working_days_per_year",
  AHT_EMPLOYEE_PER_TRANSACTION_MINUTES: "aht_employee_per_transaction_minutes",
  ROBOT_SPEED_MULTIPLIER: "robot_speed_multiplier",
  AHT_ROBOT_PER_TRANSACTION_MINUTES: "aht_robot_per_transaction_minutes",
  AUTOMATION_POTENTIAL_FINAL: "automation_potential_final",
  VOLUMES_PER_YEAR_TOTAL: "volumes_per_year_total",
  VOLUMES_PER_YEAR_HUMAN_AFTER_AUTOMATION:
    "volumes_per_year_human_after_automation",
  VOLUMES_PER_YEAR_ROBOT_FINAL_AFTER_AUTOMATION:
    "volumes_per_year_robot_final_after_automation",
  HUMAN_COST_PER_YEAR: "human_cost_per_year",
  RUNNING_COST_PER_TRANSACTION_HUMAN: "running_cost_per_transaction_human",
  FTR_OVERRIDDEN: "ftr_overridden",
  IMPLEMENTATION_PEOPLE_COSTS: "implementation_people_costs",
  RPA_SOFTWARE_COSTS_PER_YEAR: "rpa_software_costs_per_year",
  OTHER_SOFTWARE_COSTS_PER_YEAR: "other_software_costs_per_year",
  SUPPORT_TEAM_COSTS_PER_YEAR: "support_team_costs_per_year",
  INFRASTRUCTURE_COSTS_PER_YEAR: "infrastructure_costs_per_year",
  OTHER_COSTS_PER_YEAR: "other_costs_per_year",
  TOTAL_RUNNING_COSTS_PER_YEAR_ROBOT: "total_running_costs_per_year_robot",
  RUNNING_COST_PER_TRANSACTION_ROBOT: "running_cost_per_transaction_robot",
  TOTAL_YEAR_ONE: "total_year_one",
  TOTAL_YEAR_TWO: "total_year_two",
  PROCESS_PRIORITY: "process_priority",
  PROCESS_DEVELOPMENT_TYPE: "process_development_type",
  AUTOMATION_POTENTIAL_BENEFIT_ACROSS_COMPANY:
    "automation_potential_benefit_across_company",
  PROCESS_NUM_REQUESTS: "process_num_requests",
  NUMBER_OF_EMPLOYEES: "number_of_employees",
  TOTAL_PROCESSING_TIME: "total_processing_time",
  TOTAL_REWORK_TIME: "total_rework_time",
  TOTAL_REVIEW_AUDIT_TIME: "total_review_audit_time",
  TOTAL_TIME_NEEDED_TO_PERFORM_WORK: "total_time_needed_to_perform_work",
  ESTIMATED_BENEFIT_HOURS_PER_YEAR: "estimated_benefit_hours_per_employee",
  ESTIMATED_COST_BENEFIT_PER_EMPLOYEE: "estimated_cost_benefit_per_employee",
  ESTIMATED_BENEFIT_FTES_PER_EMPLOYEE: "estimated_benefit_ftes_per_employee",
  AVG_PROCESS_TIME_TRANSACTION: "process_average_handling_time",
  PROCESS_VOLUME: "process_volume",
  PROCESS_DIGITAL_INPUT_PERCENT: "process_digital_input_percent",
  PROCESS_COST_PER_YEAR: "process_cost_per_year",
  NUMBER_OF_APPLICATIONS: "process_num_applications",
  BENEFIT_PER_COMPANY_CURRENCY:
    "process_benefit_expressed_in_currency_per_year",
  BENEFIT_PER_COMPANY_HOURS: "process_estimated_benefit_score",
  PROCESS_AUTOMATION_SCORE: "process_automation_score"
};

export const PROCESS_TABLE_PROPERTIES = [
  "process_id",
  "process_slug",
  "process_submitter_user_id",
  "process_name",
  "process_description",
  "process_rules_id",
  "process_input_type_id",
  "process_stability_id",
  "process_input_structure_id",
  "process_documentation_availability_ids",
  "process_owner_user_id",
  "process_created_epoch",
  "process_created_at",
  "process_updated_epoch",
  "process_updated_at",
  "process_is_deleted",
  "process_updated_user_id",
  "process_phase_id",
  "process_phase_status_id",
  "process_challenges_encountered",
  "process_challenges_expected",
  "process_task_stability_id",
  "process_application_stability_id",
  "process_frequency_id",
  "process_peak_id",
  "process_length_id",
  "process_decision_complexity_id",
  "process_scanned_input_use_id",
  "process_structured_input_id",
  "process_lessons_learned",
  "process_benefits_expected",
  "process_benefits_actual",
  "process_suitability_score",
  "process_readiness_score",
  "process_overall_gauge_score",
  "process_uuid",
  "process_num_followers",
  "process_num_votes",
  "process_num_media",
  "process_num_documents",
  "process_num_collaborators",
  "process_num_components",
  "process_priority",
  "process_num_comments",
  "process_num_applications",
  "process_automation_score",
  "process_ease_of_implementation_score",
  "process_estimated_benefit_score",
  "process_benefit_expressed_in_currency_per_year",
  "process_goal",
  "process_ease_of_implementation_level",
  "process_cost_per_year",
  "process_digital_input_percent",
  "process_volume",
  "process_average_handling_time",
  "process_east_of_baseline_score",
  "process_num_similar_automations",
  "process_is_potential_duplicate",
  "process_submitted_at",
  "process_submitted_epoch",
  "process_history_version",
  "project_manager_user_id",
  "process_archived_reason",
  "process_rejected_reason",
  "process_pain_points",
  "process_negative_impact",
  "process_submission_type",
  "process_volumes_per_year",
  "process_AHT_employee_per_transaction"
];

export const PROCESS_OBJECT_LEGACY_PROPERTIES = [
  PIPELINE_COLUMN_NAME.PROCESS_AUTOMATION_SCORE,
  PIPELINE_COLUMN_NAME.PROCESS_EASE_OF_IMPLEMENTATION,
  PIPELINE_COLUMN_NAME.BENEFIT_PER_COMPANY_HOURS,
  PIPELINE_COLUMN_NAME.BENEFIT_PER_COMPANY_CURRENCY,
  PIPELINE_COLUMN_NAME.PROCESS_GOAL,
  PIPELINE_COLUMN_NAME.NUMBER_OF_APPLICATIONS,
  PIPELINE_COLUMN_NAME.PROCESS_EASE_OF_IMPLEMENTATION_LEVEL,
  PIPELINE_COLUMN_NAME.PROCESS_COST_PER_YEAR,
  PIPELINE_COLUMN_NAME.PROCESS_DIGITAL_INPUT_PERCENT,
  PIPELINE_COLUMN_NAME.PROCESS_VOLUME,
  PIPELINE_COLUMN_NAME.AVG_PROCESS_TIME_TRANSACTION
];

export enum COProcessAutomationType {
  NONE = 0,
  ATTENDED = 1,
  UNATTENDED = 2
}

export const COProcessAutomationTypeInfo: {
  [key: number]: {
    title: string;
    process_type_id: number;
    localization_key: string;
    key: string;
    order: number;
  };
} = {
  [COProcessAutomationType.NONE]: {
    title: "None",
    localization_key: "co_automation_type_none",
    key: "",
    process_type_id: COProcessAutomationType.NONE,
    order: 0
  },
  [COProcessAutomationType.ATTENDED]: {
    title: "Attended",
    localization_key: "co_automation_type_attended",
    key: "attended",
    process_type_id: COProcessAutomationType.ATTENDED,
    order: 0
  },
  [COProcessAutomationType.UNATTENDED]: {
    title: "Unattended",
    localization_key: "co_automation_type_unattended",
    key: "unattended",
    process_type_id: COProcessAutomationType.UNATTENDED,
    order: 0
  }
};

export enum COProcessSubmissionType {
  EMPLOYEE_DRIVEN = 1,
  TOP_DOWN_SUBMISSION = 2,
  CITIZEN_DEVELOPMENT_AUTOMATION = 3,
  PROCESS_MINING = 4,
  TASK_MINING = 5,
  CHANGE_REQUEST = 6
}

export enum COProcessSubmissionTypeSlug {
  EMPLOYEE_DRIVEN = "idea",
  TOP_DOWN_SUBMISSION = "top-down",
  CITIZEN_DEVELOPMENT_AUTOMATION = "automation",
  PROCESS_MINING = "process-mining",
  TASK_MINING = "task-mining",
  CHANGE_REQUEST = "change-request"
}

export const COProcessSubmissionTypeSlugForType = {
  [COProcessSubmissionType.EMPLOYEE_DRIVEN]:
    COProcessSubmissionTypeSlug.EMPLOYEE_DRIVEN,
  [COProcessSubmissionType.TOP_DOWN_SUBMISSION]:
    COProcessSubmissionTypeSlug.TOP_DOWN_SUBMISSION,
  [COProcessSubmissionType.CITIZEN_DEVELOPMENT_AUTOMATION]:
    COProcessSubmissionTypeSlug.CITIZEN_DEVELOPMENT_AUTOMATION,
  [COProcessSubmissionType.PROCESS_MINING]:
    COProcessSubmissionTypeSlug.PROCESS_MINING,
  [COProcessSubmissionType.TASK_MINING]:
    COProcessSubmissionTypeSlug.TASK_MINING,
  [COProcessSubmissionType.CHANGE_REQUEST]:
    COProcessSubmissionTypeSlug.CHANGE_REQUEST
};

export const COProcessSubmissionTypeForSlug = {
  [COProcessSubmissionTypeSlug.EMPLOYEE_DRIVEN]:
    COProcessSubmissionType.EMPLOYEE_DRIVEN,
  [COProcessSubmissionTypeSlug.TOP_DOWN_SUBMISSION]:
    COProcessSubmissionType.TOP_DOWN_SUBMISSION,
  [COProcessSubmissionTypeSlug.CITIZEN_DEVELOPMENT_AUTOMATION]:
    COProcessSubmissionType.CITIZEN_DEVELOPMENT_AUTOMATION,
  [COProcessSubmissionTypeSlug.PROCESS_MINING]:
    COProcessSubmissionType.PROCESS_MINING,
  [COProcessSubmissionTypeSlug.TASK_MINING]:
    COProcessSubmissionType.TASK_MINING,
  [COProcessSubmissionTypeSlug.CHANGE_REQUEST]:
    COProcessSubmissionType.CHANGE_REQUEST
};

export interface COProcessSubmissionTypeInfoData {
  title: string;
  localization_key: string;
  submission_success_route?: string;
  draft_type?: string;
  order: number;
  review_warning_message?: string;
  review_warning_message_localization_key?: string;
  process_owner_required_warning?: boolean;
  hidden_profile_tabs?: {
    cost_benefit_analysis_tab?: boolean;
    documentation_tab?: boolean;
    components_tab?: boolean;
    collaborators_tab?: boolean;
    change_requests_tab?: boolean;
  };
  is_publishable?: boolean;
  can_be_change_request_parent?: boolean;
}

export const COProcessSubmissionTypeInfo: {
  [key: number]: COProcessSubmissionTypeInfoData;
} = {
  [COProcessSubmissionType.EMPLOYEE_DRIVEN]: {
    title: "Employee-driven",
    localization_key: "submission_type_basic_submission",
    submission_success_route: "/submit-idea-success/:slug",
    draft_type: "EMPLOYEE_DRIVEN",
    process_owner_required_warning: true,
    order: 0,
    can_be_change_request_parent: true
  },
  [COProcessSubmissionType.TOP_DOWN_SUBMISSION]: {
    title: "CoE-driven",
    localization_key: "submission_type_coe_submission",
    submission_success_route: "/automation-profile/:slug",
    process_owner_required_warning: true,
    draft_type: "COE",
    order: 1,
    can_be_change_request_parent: true
  },
  [COProcessSubmissionType.CITIZEN_DEVELOPMENT_AUTOMATION]: {
    title: "Citizen Developer",
    localization_key: "submission_type_automation_submission",
    submission_success_route: "/submit-automation/success/:slug",
    draft_type: "CD_AUTOMATION",
    process_owner_required_warning: false,
    order: 2,
    review_warning_message:
      "Are you certain that you want to submit your automation for Business review?",
    review_warning_message_localization_key:
      "citizen_developer_submission_warning_message",
    can_be_change_request_parent: true
  },
  [COProcessSubmissionType.PROCESS_MINING]: {
    title: "Process Mining",
    localization_key: "submission_type_process_mining",
    submission_success_route: "/automation-profile/:slug",
    process_owner_required_warning: true,
    order: 3,
    can_be_change_request_parent: true
  },
  [COProcessSubmissionType.TASK_MINING]: {
    title: "Task Mining",
    localization_key: "submission_type_task_mining",
    submission_success_route: "/automation-profile/:slug",
    process_owner_required_warning: true,
    order: 4,
    can_be_change_request_parent: true
  },
  [COProcessSubmissionType.CHANGE_REQUEST]: {
    title: "Change Request",
    localization_key: "submission_type_change_request",
    submission_success_route: "/automation-profile/:slug",
    draft_type: "CHANGE_REQUEST",
    process_owner_required_warning: false,
    order: 5,
    hidden_profile_tabs: {
      change_requests_tab: true
    },
    review_warning_message:
      "Are you certain that you want to submit your change request for review?",
    review_warning_message_localization_key:
      "change_request_submission_warning_message",
    is_publishable: true
  }
};

// export const DRAFT_TYPES_TO_USER_PERMISSIONS = {
//   [COE_DRAFT_TYPE]: TOP_DOWN_PERMISSION_PERMISSION,
//   [CD_AUTOMATION_DRAFT_TYPE]: SUBMIT_AUTOMATION_PERMISSION,
//   [EMPLOYEE_DRIVEN_DRAFT_TYPE]: EMPLOYEE_DRIVEN_SUBMISSION_PERMISSION
// };

// this is in the filetypes database table
export enum CODocumentTypes {
  PROCESS_DEFINITION_DOCUMENT = 1,
  SOLUTION_DESIGN_DOCUMENT = 2,
  DEVELOPMENT_SPECIFICATION_DOCUMENT = 3,
  STANDARD_OPERATING_PROCEDURE = 4,
  DETAILED_WORK_INSTRUCTIONS = 5,
  PROCESS_MAP = 6,
  INPUT_FILE = 7,
  OUTPUT_FILE = 8,
  MISC = 9,
  TASK_CAPTURE_DOCUMENT = 10,
  AUTOMATION_SOURCE_CODE = 11,
  PROCESS_MINING_DASHBOARD = 12,
  AUTOMATION_NUGET_PACKAGE = 13
}

export const CODocumentTypeInfo: {
  [key: number]: {
    title: string;
    localization_key: string;
    fileTypeKey: string;
  };
} = {
  [CODocumentTypes.PROCESS_DEFINITION_DOCUMENT]: {
    title: "Process Definition Document",
    localization_key: "document_type_pdd",
    fileTypeKey: "PDD"
  },
  [CODocumentTypes.SOLUTION_DESIGN_DOCUMENT]: {
    title: "Solution Design Document",
    localization_key: "document_type_sdd",
    fileTypeKey: "SDD"
  },
  [CODocumentTypes.DEVELOPMENT_SPECIFICATION_DOCUMENT]: {
    title: "Development Specification Document",
    localization_key: "dsd",
    fileTypeKey: "DSD"
  },
  [CODocumentTypes.STANDARD_OPERATING_PROCEDURE]: {
    title: "Standard Operating Procedure",
    localization_key: "document_type_sop",
    fileTypeKey: "SOP"
  },
  [CODocumentTypes.DETAILED_WORK_INSTRUCTIONS]: {
    title: "Detailed Work Instructions",
    localization_key: "document_type_dwi",
    fileTypeKey: "DWI"
  },
  [CODocumentTypes.PROCESS_MAP]: {
    title: "Process Map",
    localization_key: "document_type_pm",
    fileTypeKey: "PM"
  },
  [CODocumentTypes.INPUT_FILE]: {
    title: "Input File",
    localization_key: "document_type_if",
    fileTypeKey: "IF"
  },
  [CODocumentTypes.OUTPUT_FILE]: {
    title: "Output File",
    localization_key: "document_type_of",
    fileTypeKey: "OF"
  },
  [CODocumentTypes.MISC]: {
    title: "Misc.",
    localization_key: "document_type_misc",
    fileTypeKey: "MISC"
  },
  [CODocumentTypes.TASK_CAPTURE_DOCUMENT]: {
    title: "Task Capture Document",
    localization_key: "document_type_tcd",
    fileTypeKey: "TCD"
  },
  [CODocumentTypes.AUTOMATION_SOURCE_CODE]: {
    title: "Automation Source Code",
    localization_key: "document_type_asc",
    fileTypeKey: "ASC"
  },
  [CODocumentTypes.PROCESS_MINING_DASHBOARD]: {
    title: "Process Mining Dashboard",
    localization_key: "document_type_mining",
    fileTypeKey: "MINING"
  },
  [CODocumentTypes.AUTOMATION_NUGET_PACKAGE]: {
    title: "Automation Nuget Package",
    localization_key: "document_type_package",
    fileTypeKey: "PACKAGE"
  }
};

export enum COProcessSaveAction {
  NEW_PROCESS = "NEW_PROCESS",
  SAVE_PROCESS = "SAVE_PROCESS",
  SUBMIT_DETAILED_ASSESSMENT = "SUBMIT_DETAILED_ASSESSMENT"
}

export enum CODevelopmentTypes {
  NONE = "development-type-none",
  COE = "coe",
  CD_POWER_USER = "cd-power-user",
  CD_SELF_USER = "cd-self-user"
}

export const CODevelopmentTypeOptions = [
  {
    development_type_slug: "development-type-none",
    development_type_name: "No Development Type"
  },
  {
    development_type_slug: "coe",
    development_type_name: "CoE"
  },
  {
    development_type_slug: "cd-power-user",
    development_type_name: "Citizen Developer Power User"
  },
  {
    development_type_slug: "cd-self-user",
    development_type_name: "Citizen Developer Self User"
  }
];

export enum COCalculatedValueTypes {
  NUMBER = "NUMBER", // like volumes per year
  IDENTIFIER = "IDENTIFIER", // like process_submission_type or phase_status_key
  TEXT = "TEXT",
  RICH_TEXT = "RICH_TEXT", //draft.js format
  EPOCH = "EPOCH",
  PERCENT = "PERCENT",
  DATE_TIME = "DATE_TIME",
  URL = "URL",
  ORCHESTRATOR_PACKAGE = "ORCHESTRATOR_PACKAGE",
  APPLICATION = "applications.application_id",
  CATEGORY = "categories.category_id",
  COMPONENT = "components.component_id",
  USER = "users.user_id",
  PROCESS = "processes.process_id",
  FILE = "files.file_id",
  TAG = "tags.tag_id",
  ASSESSMENT = "co_assessments.co_assessment_id",

  NUMBER_ARRAY = "NUMBER[]", // like volumes per year
  IDENTIFIER_ARRAY = "IDENTIFIER[]", // like process_submission_type or phase_status_key
  TEXT_ARRAY = "TEXT[]",
  RICH_TEXT_ARRAY = "RICH_TEXT[]", //draft.js format
  EPOCH_ARRAY = "EPOCH[]",
  PERCENT_ARRAY = "PERCENT[]",
  DATE_TIME_ARRAY = "DATE_TIME[]",
  URL_ARRAY = "URL[]",
  APPLICATION_ARRAY = "applications.application_id[]",
  CATEGORY_ARRAY = "categories.category_id[]",
  COMPONENT_ARRAY = "components.component_id[]",
  USER_ARRAY = "users.user_id[]",
  PROCESS_ARRAY = "processes.process_id[]",
  FILE_ARRAY = "files.file_id[]",
  TAG_ARRAY = "tags.tag_id[]",
  ASSESSMENTS = "co_assessments.co_assessment_id[]"
}
export const COCalculatedValueTypeIsNumber = calculated_value_type => {
  switch (calculated_value_type) {
    case COCalculatedValueTypes.NUMBER:
    case COCalculatedValueTypes.PERCENT: {
      return true;
    }
  }
  return false;
};
export const COCalculatedValueTypeIsInteger = calculated_value_type => {
  switch (calculated_value_type) {
    case COCalculatedValueTypes.EPOCH:
    case COCalculatedValueTypes.IDENTIFIER:
    case COCalculatedValueTypes.APPLICATION:
    case COCalculatedValueTypes.CATEGORY:
    case COCalculatedValueTypes.COMPONENT:
    case COCalculatedValueTypes.USER:
    case COCalculatedValueTypes.PROCESS:
    case COCalculatedValueTypes.CATEGORY:
    case COCalculatedValueTypes.FILE:
    case COCalculatedValueTypes.TAG:
    case COCalculatedValueTypes.ASSESSMENT: {
      return true;
    }
  }
  return false;
};
export const CO_NON_ASSESSMENT_CALCULATED_VALUE_QUESTION_ID = -1;

export const CO_VALUE_DELIMITER = ",";

// the context object keys used by the pathing system
export enum COContextObjectKey {
  ASSESSMENT = "assessment",
  SECTION = "section",
  QUESTION = "question",
  PROCESS_ANSWER = "process_answer",
  ANSWER_OPTION = "answer_option",
  PROCESS_ANSWER_SELECTION = "process_answer_selection",
  TEMPLATES = "templates",
  PROCESS_EXTERNAL_DATA = "process_external_data"
}

export const enum COPathPartType {
  PATH_PART_CONTEXT = "context",
  PATH_PART_CONTEXT_ELEMENT = "context_element",
  PATH_PART_PROPERTY = "property",
  PATH_PART_ARRAY = "array"
}

export enum COPermissionSlugs {
  GENERAL_PERMISSIONS = "s_general_assessments_sensitive_information",
  Q2_PERMISSIONS = "s_q2_sensitive_information",
  Q3_PERMISSIONS = "s_citizen_developer_assessment_sensitive_information",
  CBA_PERMISSIONS = "s_cba_sensitive_information",
  CHANGE_REQUEST_PERMISSIONS = "s_change_request_assessment_sensitive_information"
}

export enum COConditionSlugs {
  HIDDEN = "hidden",
  HIDE_FROM_PROFILE = "hide_from_view", //
  QUESTION_HIDDEN_FOR_SUBMIT = "question_hidden_for_submit",
  SECTION_HIDDEN_FOR_SUBMIT = "section_hidden_for_submit",
  SUBMISSION_FOR_TASK_AND_PROCESS_MINING_ONLY = "submission_for_task_and_process_mining_only",
  HIDE_FOR_EDIT = "hide_from_edit",
  SIDEBAR = "show_in_sidebar",
  QUESTION_SHOWN_AFTER_LIVE = "question_shown_after_live",
  SECTION_SHOWN_AFTER_LIVE = "section_shown_after_live",
  HIDE_FROM_PIPELINE = "hide_from_pipeline",
  OVERRIDEN_BY_ANOTHER_COLUMN_IN_PIPELINE = "overriden_by_another_column_in_pipeline", // The question that has this condition has another column in the pipeline that displays it's value better, and so this column should never be shown so that the other column can take it's spot
  DISABLE_IF_LEGACY_ANSWER_SELECTED = "disable_if_legacy_answer_selected",
  DISABLE_IF_LEGACY_ANSWER_SELECTED_EXCEPT = "disable_if_legacy_answer_selected_except",
  Q1_VISIBILITY = "q1_visibility",
  Q2_VISIBILITY = "q2_visibility",
  Q2_REQUIRED_OVERRIDES = "q2_required_overrides",
  Q3_VISIBILITY = "q3_visibility",
  CR_VISIBILITY = "cr_visibility",
  DONT_SHOW_IN_SIDEBAR = "dont_show_in_sidebar",
  HIDE_QUESTION_IF_NOT_ANSWERED_IN_VIEW_CONTEXT = "hide_question_if_not_answered",
  HIDE_KPI_IF_NOT_CALCULATED_IN_VIEW_CONTEXT = "hide_kpi_if_not_calculated",
  POST_ACTION_PHASE_STATUS_KEY_EQUALS = "post_action_phase_status_key_equals",
  HIDE_IF_PATH_ROUTE = "hide_if_path_route", // was for tenant settings, but is useful in general
  HIDE_IF_NO_QUESTIONS_VISIBLE_IN_SECTION = "hide_if_no_questions_visibile_in_section",
  HIDE_IF_NO_QUESTIONS_VISIBLE_IN_ASSESSMENT = "hide_if_no_questions_visible_in_assessment",
  DONT_HIDE_IF_NO_QUESTIONS_VISIBLE_IN_ASSESSMENT = "override_hide_if_no_questions_visible_in_assessment",
  REQUIRED_FOR_PHASE_STATUS_CHANGE = "required_for_phase_change",
  REQUIRED_FOR_NEXT_PHASE_STATUS_CHANGE = "required_for_next_phase",
  EDITABLE_PRE_PUBLISH = "editable_publishing",
  SHOW_QUESTION_IN_INLINE_APPEARANCE = "show_question_in_inline_appearance",
  SHOW_QUESTION_IN_TABLE_APPEARANCE = "show_question_in_table_appearance",
  QUESTION_NOT_REQUIRED = "question_not_required",
  QUESTION_IS_REQUIRED = "question_is_required",
  QUESTION_DOES_NOT_IMPACT_KPI = "question_does_not_impact_kpi",
  QUESTION_CAN_IMPACT_KPI = "question_can_impact_kpi",
  QUESTION_HIDES_COMMENTS_SECTION = "question_hides_comments_section",
  QUESTION_SHOWS_COMMENTS_SECTION = "question_shows_comments_section",
  QUESTION_HAS_PREFILL_ANSWERS = "question_has_prefill_answers",
  ASSESSMENT_NOT_OPTIONAL_DURING_INITIAL_PHASE = "assessment_not_optional_during_initial_phase",
  ASSESSMENT_NOT_OPTIONAL = "assessment_not_optional",
  ASSESSMENT_OPTIONAL_WITH_WARNING_WHEN_SAVING = "assessment_optional_with_waarning_when_saving",
  ASSESSMENT_OPTIONAL_WITHOUT_WARNING_BEFORE_QUALIFICATION = "assessment_optional_without_warning_before_qualification",
  ASSESSMENT_NOT_OPTIONAL_DURING_MARK_AS_COMPLETE = "assessment_not_optional_during_mark_as_complete",
  ASSESSMENT_NOT_OPTIONAL_DURING_IDEA_APPROVAL = "assessment_not_optional_during_idea_approval",
  BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_SPECIFIC_OPTION = "b_hide_if_q_not_answered_with_specific_option",
  BRANCH_HIDE_IF_QUESTION_NOT_ANSWERED_WITH_ONE_OF_PROVIDED_OPTIONS = "b_hide_if_q_not_answered_with_one_of_provided_options",
  HIDE_ASSESSMENT_DURING_CHANGE_REQUEST = "hide_assessment_during_change_request",
  SHOW_DURING_CR_BASED_ON_Q1_ASSESSMENT_VISIBILITY_QUESTION = "show_during_cr_based_on_q1_assessment_visibility_question",
  SHOW_DURING_CR_BASED_ON_Q2_ASSESSMENT_VISIBILITY_QUESTION = "show_during_cr_based_on_q2_assessment_visibility_question",
  SHOW_DURING_CR_BASED_ON_Q3_ASSESSMENT_VISIBILITY_QUESTION = "show_during_cr_based_on_q3_assessment_visibility_question",
  SHOW_DURING_CR_BASED_ON_MISC_ASSESSMENT_VISIBILITY_QUESTION = "show_during_cr_based_on_misc_assessment_visibility_question",
  CR_Q1_BUTTON_VISIBILITY = "cr_q1_button_visibility",
  CR_Q3_BUTTON_VISIBILITY = "cr_q3_button_visibility",
  SHOW_WHEN_ANY_REQUIRED_QUESTIONS_UNANSWERED = "show_when_any_required_questions_unanswered",
  HIDE_IF_USER_CANT_EDIT_PROCESS = "hide_if_user_cant_edit_process",
  HIDE_IF_IN_TABULAR_SECTION = "hide_if_in_tabular_section",
  USE_QUESTION_TABLE_ROW_COMPONENT_IF_IN_TABLE_SECTION = "user_question_table_row_component_if_in_table_section"
}

export enum COModalType {
  EDIT_KPI = "EDIT_KPI",
  ANSWER_OPTION_BRANCHING = "ANSWER_OPTION_BRANCHING"
}

// massive number to represent a role for a process being sent up with the initial submission
export const INITIAL_COLLABORATORS_PICKER_NEW_PROCESS_ID_PLACEHOLDER = 999999999999999;

export const CO_NON_TABULAR_DEFAULT_INDEX = -1;
export const CO_TABULAR_AGGREGATION_OPERATORS = {
  SUM: "sum",
  MIN: "min",
  MAX: "max"
};
export enum COProfileBannerSlugs {
  UNANSWERED_REQUIRED_CR_QUESTIONS = "unanswered_required_cr_questions"
}

export enum COHorizontalAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right"
}

export enum COVerticalAlignment {
  UP = "up",
  CENTER = "center",
  DOWN = "down"
}
