import React from "react";

export const getTaskIcon = (typeKey: string, isHover = false) => {
  const backgroundColor = isHover
    ? "var(--color-primary, #0067df)"
    : "var(--color-background-secondary, #f1f6f8)";

  const iconColor = isHover
    ? "var(--color-background-secondary, #f1f6f8)"
    : "var(--color-primary, #0067df)";

  switch (typeKey) {
    case "approve_content":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <g fill="none" fill-rule="evenodd">
            <rect width="32" height="32" fill={backgroundColor} rx="2" />
            <path
              fill={iconColor}
              fill-rule="nonzero"
              d="M13.068 21.016l-3.363-3.55a1.082 1.082 0 0 1 0-1.489.972.972 0 0 1 1.411 0l2.46 2.59a.583.583 0 0 0 .847-.001l6.46-6.819a.971.971 0 0 1 1.412.003 1.09 1.09 0 0 1-.003 1.496l-7.36 7.77a1.283 1.283 0 0 1-1.864 0z"
            />
          </g>
        </svg>
      );

    case "generate_content":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <g fill="none" fill-rule="evenodd">
            <path fill={backgroundColor} d="M0 0h32v32H0z" />
            <path
              stroke={iconColor}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.273"
              d="M16.564 12.319l3.133 3.133-6.557 6.578a.377.377 0 0 1-.1.072 8.464 8.464 0 0 1-3.24.893.903.903 0 0 1-.62-.16c-.558-.56.317-3.139.749-3.817a.371.371 0 0 1 .08-.117l6.555-6.582zm1.797-1.803l1.108-1.112c.259-.261.612-.407.98-.404.365-.001.716.144.973.404l1.176 1.182a1.381 1.381 0 0 1 0 1.947l-1.107 1.113-3.13-3.13z"
            />
          </g>
        </svg>
      );

    case "general_task":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <g fill="none" fill-rule="evenodd">
            <rect width="32" height="32" fill={backgroundColor} rx="2" />
            <path
              stroke={iconColor}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.538"
              d="M21 11.385h-9M21 16h-5.5M21 20.615h-5.5M12.5 16H12M12.5 20.615H12"
            />
            <path
              stroke={iconColor}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M24 12.923v11.539c0 .85-.672 1.538-1.5 1.538h-12c-.828 0-1.5-.689-1.5-1.538V9.077c0-.85.672-1.539 1.5-1.539h3.75V6.77c0-.513.25-.769.75-.769h3c.5 0 .75.256.75.77v.768h3.75c.828 0 1.5.69 1.5 1.539v.77"
            />
          </g>
        </svg>
      );

    case "curate":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <g fill="none" fill-rule="evenodd">
            <rect width="32" height="32" fill={backgroundColor} rx="2" />
            <g transform="translate(8 5)">
              <path
                fill={iconColor}
                fill-rule="nonzero"
                d="M15.94 8.36a.648.648 0 0 0-.586-.36H.646c-.251 0-.48.14-.586.36-.106.22-.07.48.093.665l5.73 6.529v4.406a.62.62 0 0 0 .357.557l2.942 1.417a.666.666 0 0 0 .629-.027.618.618 0 0 0 .306-.53v-5.823l5.73-6.53a.605.605 0 0 0 .093-.663zm-6.963 6.564a.61.61 0 0 0-.153.402v5.044l-1.648-.795v-4.249a.61.61 0 0 0-.153-.402L2.039 9.246h11.922l-4.984 5.678z"
              />
              <circle cx="3.5" cy="5.5" r="1.5" stroke={iconColor} />
              <path stroke={iconColor} d="M12.56 8.56a1.5 1.5 0 1 0-2.12 0" />
              <circle cx="8.5" cy="1.5" r="1.5" stroke={iconColor} />
            </g>
          </g>
        </svg>
      );

    case "support":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <defs>
            <path
              id="a"
              d="M9 14.143a.643.643 0 1 1 0-1.286.643.643 0 0 1 0 1.286z"
            />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <rect width="32" height="32" fill={backgroundColor} rx="2" />
            <g transform="translate(7 7)">
              <path
                stroke={iconColor}
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.286"
                d="M15.694 3.995a8.357 8.357 0 1 1-1.838-1.798M6.429 7.071c0-2.571 5.142-2.571 5.142 0C11.571 9.106 9 9.643 9 10.93"
              />
              <use fill={iconColor} />
            </g>
          </g>
        </svg>
      );

    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <g fill="none" fill-rule="evenodd">
            <path fill={backgroundColor} d="M0 0h32v32H0z" />
            <path
              stroke={iconColor}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.273"
              d="M16.564 12.319l3.133 3.133-6.557 6.578a.377.377 0 0 1-.1.072 8.464 8.464 0 0 1-3.24.893.903.903 0 0 1-.62-.16c-.558-.56.317-3.139.749-3.817a.371.371 0 0 1 .08-.117l6.555-6.582zm1.797-1.803l1.108-1.112c.259-.261.612-.407.98-.404.365-.001.716.144.973.404l1.176 1.182a1.381 1.381 0 0 1 0 1.947l-1.107 1.113-3.13-3.13z"
            />
          </g>
        </svg>
      );
  }
};
