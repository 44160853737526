import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { LocalizeTranslationKey } from "../../../utils/localizedAlertMessages";
import {
  clearCurrentAutomation,
  setStatus
} from "../../../actions/automationPipelineActions";
import { sweetAlertLoader } from "../../../utils/sweetAlertLoader";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import { ASSESSMENT_NOT_STARTED } from "../../../constants/automationStatusTypes";

import "./ModalApproveIdea.scss";
import {
  APPROVE_IDEA_SUCCESS_EVENT_NAME,
  triggerAnalyticsWithDefaultData
} from "../../../utils/analyticsUtils";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import IconApollo from "../../IconApollo/IconApollo";

const Message = props => {
  const { text } = props;

  return (
    <div className="ModalApproveIdea__message">
      <div className="ModalApproveIdea__message-image-container">
        <IconApollo
          className="ModalApproveIdea__message-check"
          icon="check"
          fontSize="small"
        />
      </div>
      <span className="ModalApproveIdea__message-text">{text}</span>
    </div>
  );
};

class ModalApproveIdea extends Component {
  closeModal = e => {
    const { clearCurrentAutomation } = this.props;

    clearCurrentAutomation();
    this.props.closeModal();
  };

  findIdForStatus = status => {
    const { tableAutomations } = this.props;
    const statuses = tableAutomations.automationStatuses;
    const statusObj = statuses.find(currStatus => {
      return currStatus.phase_status_key === status;
    });

    return statusObj.phase_status_id;
  };

  approveIdea = () => {
    const { setStatus, automationPipeline } = this.props;
    let { allPhasesAndStatuses, currentAutomation } = automationPipeline;

    const statusChangeId = allPhasesAndStatuses[ASSESSMENT_NOT_STARTED];

    setStatus(
      {
        slug: currentAutomation.process_slug,
        statusChangeId
      },
      this.afterApproveIdea
    );
    sweetAlertLoader({
      text: (
        <LocalizeTranslationKey localeKey="component_actions_updating_text" />
      )
    });
  };

  afterApproveIdea = data => {
    triggerAnalyticsWithDefaultData(APPROVE_IDEA_SUCCESS_EVENT_NAME);
    if (
      this.props.automationPipeline &&
      typeof this.props.automationPipeline.statusChangeCallback === "function"
    ) {
      this.props.automationPipeline.statusChangeCallback(data);
    }
  };

  render() {
    const contentStyle = {
      borderRadius: "4px",
      boxShadow: "-8px 0 16px 0 rgba(0, 0, 0, 0.1)"
    };

    const headerStyle = {
      borderBottom: "0px",
      paddingBottom: "0px"
    };

    return (
      <Modal onClose={this.closeModal} contentStyle={contentStyle}>
        <ModalTemplateOne
          heading=""
          onClose={this.closeModal}
          headerStyle={headerStyle}
        >
          <div className="ModalApproveIdea" data-testid="ModalApproveIdea">
            <div className="ModalApproveIdea__header">
              <IconApollo
                className="ModalApproveIdea__main-icon"
                icon="check_circle"
              />
              <span className="ModalApproveIdea__title">
                {this.props.t([
                  "modal_approve_idea_approve_idea",
                  "Approve Idea"
                ])}
              </span>
            </div>
            <div className="ModalApproveIdea__main">
              <span>
                {this.props.t([
                  "modal_approve_idea_by_approving",
                  "By approving this idea you are confirming that it is..."
                ])}
              </span>
              <div className="ModalApproveIdea__msg-container">
                <Message
                  text={this.props.t([
                    "modal_approve_idea_not_duplicate",
                    "Not a duplicate of another automation"
                  ])}
                />
                <Message
                  text={this.props.t([
                    "modal_approve_idea_adequate",
                    "Adequate to be considered for automation"
                  ])}
                />
                <Message
                  text={this.props.t([
                    "modal_approve_idea_verified",
                    "Verified and the details included are reliable"
                  ])}
                />
              </div>
            </div>
            <div className="ModalApproveIdea__footer">
              <ButtonApolloSecondary
                className="ModalApproveIdea__btn"
                onClick={this.closeModal}
              >
                {this.props.t(["modal_approve_idea_cancel", "Cancel"])}
              </ButtonApolloSecondary>
              <ButtonApolloPrimary
                className="ModalApproveIdea__btn"
                onClick={this.approveIdea}
              >
                {this.props.t(["modal_approve_idea_approve", "Approve"])}
              </ButtonApolloPrimary>
            </div>
          </div>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapStateToProps = ({ automationPipeline, tableAutomations }) => ({
  automationPipeline,
  tableAutomations
});

const mapDispatchToProps = {
  clearCurrentAutomation,
  setStatus
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(ModalApproveIdea)
);
