import {
  COConditionOverrideSlugPayload,
  CODocumentOptionsInterface,
  COOptionsInterface,
  COQuestionAnswerOptionInterface,
  COQuestionInterface
} from "../interfaces/co-interfaces";
import { isNullOrUndefined } from "../utils/co-utils";
import { updateMetaDataValues } from "../utils/co-template.utils";
import { kpiTemplateForSlug } from "./co-kpis.template";
import { questionAnswerOptionTemplateForSlug } from "./co-question-answer-options.template";
import { questionTemplateForSlug } from "./co-questions.template";
import { LegacySlugForCOQuestion } from "../constants/co-migration-constants";

export const customQuestionWithInfo = ({
  slug,
  standard_question_template_slug,
  standard_kpi_template_slug,
  title,
  description,
  help_text,
  note,
  question_overrides,
  answer_option_info,
  options_meta_overrides,
  condition_overrides_to_remove
}: {
  slug?: string;
  standard_question_template_slug?: string;
  standard_kpi_template_slug?: string;
  title?: string;
  description?: string;
  help_text?: string;
  note?: string;
  question_overrides?: COQuestionInterface;
  answer_option_info?: {
    answer_option_slug: string;
    title?: string;
    value?: string;
    legacy_slug?: string;
    title_is_editable?: boolean;
    prevent_deleting?: number;
    document_options?: CODocumentOptionsInterface;
    condition_overrides?: COConditionOverrideSlugPayload[];
  }[];
  options_meta_overrides?: COOptionsInterface;
  condition_overrides_to_remove?: COConditionOverrideSlugPayload[];
}) => {
  let questionTemplate;

  if (standard_question_template_slug) {
    questionTemplate = questionTemplateForSlug(standard_question_template_slug);
  } else if (standard_kpi_template_slug) {
    questionTemplate = kpiTemplateForSlug(standard_kpi_template_slug);
  }

  let question = questionTemplate?.co_question_json;
  if (question?.co_question_meta_json) {
    updateMetaDataValues({
      metadata: question?.co_question_meta_json,
      title: title,
      description: description,
      help_text: help_text,
      note: note
    });
  }

  if (question && question_overrides) {
    for (const key of Object.keys(question_overrides || {})) {
      if (key === "co_question_meta_json") {
        // merge meta json in
        question[key] = {
          ...(question[key] || {}),
          ...question_overrides[key]
        };
      } else {
        question[key] = question_overrides[key];
      }
    }
  }

  if (question && question.co_question_options_json && options_meta_overrides) {
    // merge these arrays
    options_meta_overrides.condition_overrides = [
      ...(question.co_question_options_json.condition_overrides || []),
      ...(options_meta_overrides.condition_overrides || [])
    ];
    // then merge keys
    for (const key of Object.keys(options_meta_overrides || {})) {
      let existing = question.co_question_options_json[key];
      let newItem = options_meta_overrides[key];
      if (
        typeof existing === "object" &&
        !Array.isArray(existing) &&
        existing !== null
      ) {
        if (
          typeof newItem === "object" &&
          !Array.isArray(newItem) &&
          newItem !== null
        ) {
          // merge object
          newItem = {
            ...existing,
            ...newItem
          };
        }
      }
      question.co_question_options_json[key] = newItem;
    }
  }

  // remove all condition_overrides_to_remove if we have any
  if (
    condition_overrides_to_remove &&
    question.co_question_options_json?.condition_overrides
  ) {
    question.co_question_options_json.condition_overrides = question.co_question_options_json.condition_overrides.filter(
      condition_override => {
        return !condition_overrides_to_remove.some(
          condition_override_to_remove =>
            condition_override.slug === condition_override_to_remove.slug
        );
      }
    );
  }

  // legacy slugs are just to match up legacy questions for backwards compat open api
  let legacy_slug = LegacySlugForCOQuestion(slug || "");
  if (legacy_slug) {
    question.co_question_options_json.legacy_slug = legacy_slug;
    //console.log(`setting legacy slug ${legacy_slug} for question slug ${slug}`);
  } else {
    //console.log(`no legacy slug found ${slug}  ${legacy_slug}`);
  }

  if (question?.co_question_answer_options) {
    if (answer_option_info && answer_option_info.length > 0) {
      question.co_question_answer_options = [];
      for (const info of answer_option_info) {
        addCustomQuestionAnswerOption({
          answer_option_array: question.co_question_answer_options,
          title: info.title,
          answer_option_slug: info.answer_option_slug,
          answer_value: info.value,
          legacy_slug: info.legacy_slug,
          title_is_editable: info.title_is_editable,
          prevent_deleting: info.prevent_deleting,
          document_options: info.document_options,
          condition_overrides: info.condition_overrides
        });
      }
    }
  }
  return questionTemplate;
};

export const addCustomQuestionAnswerOption = ({
  answer_option_array,
  answer_option_slug,
  title,
  description,
  answer_value,
  legacy_slug,
  title_is_editable,
  prevent_deleting,
  document_options,
  condition_overrides
}: {
  answer_option_array: COQuestionAnswerOptionInterface[];
  answer_option_slug: string;
  title?: string;
  description?: string;
  answer_value?: string;
  legacy_slug?: string;
  title_is_editable?: boolean;
  prevent_deleting?: number;
  document_options?: CODocumentOptionsInterface;
  condition_overrides?: COConditionOverrideSlugPayload[];
}) => {
  let answer_option = questionAnswerOptionTemplateForSlug(answer_option_slug)
    ?.co_question_answer_option_json;
  if (answer_option) {
    if (!isNullOrUndefined(answer_value)) {
      answer_option.co_question_answer_option_value = answer_value;
    }
    updateMetaDataValues({
      metadata: answer_option.co_question_answer_option_meta_json,
      title: title,
      description: description
    });
    if (title_is_editable !== undefined) {
      if (answer_option.co_question_answer_option_meta_json) {
        if (answer_option.co_question_answer_option_meta_json.title) {
          answer_option.co_question_answer_option_meta_json.title.display_context_overrides = {
            assessment_customize: {
              is_editable: title_is_editable
            }
          };
        }
      }
    }
    if (answer_option.co_question_answer_option_options_json) {
      answer_option.co_question_answer_option_options_json.legacy_slug = legacy_slug;
      answer_option.co_question_answer_option_options_json.document_options = document_options;
      answer_option.co_question_answer_option_options_json.prevent_deleting = prevent_deleting;
      answer_option.co_question_answer_option_options_json.condition_overrides = [
        ...(answer_option.co_question_answer_option_options_json
          .condition_overrides || []),
        ...(condition_overrides || [])
      ];
    }
    answer_option_array.push(answer_option);
  } else {
    console.log("unable to make custom question answer option");
  }
};
