import { CO_CHART_RANGES } from "../../../constants/co-chart.constants";
import {
  COComponentType,
  COConditionSlugs,
  COModalType
} from "../../../constants/co-constants";
import {
  COAssessmentInterface,
  COConditionOverrideSlugPayload,
  COContextInterface,
  COControlFunction,
  COControlInterface,
  COControlInterfaceKeys,
  COControlOptionListItem,
  COMetadataInterface,
  COOptionsInterface,
  COPositionInterface,
  COQuestionInterface
} from "../../../interfaces/co-interfaces";
import { getValue } from "../../../utils/co-path.utils";
import {
  conditionTemplateOptions,
  conditionsSingleChoiceControl,
  conditionsMultiChoiceControl
} from "./co-conditions-dropdown.template";
import { permissionsInterfaces } from "./co-permissions.template";
import { CODefaultEquations } from "../../../constants/co-calculation.constants";
import { updateQuestionTypeAKATemplate } from "../../../helpers/co-question-template.helper";
import {
  PATH,
  PATH_ROUTE,
  PATH_OPERATORS
} from "../../../constants/co-path.constants";
import { COBase } from "../../../classes/co-base.class";
import {
  addEmptyAdditionalAnswerSelection,
  removeAnswerSelection
} from "../../../helpers/co-process-answer.helper";

export const settingsOptionsControls = ({
  is_disabled = 0,
  includeRequiredField = true,
  includeCanImpactKPIField = false,
  includeCommentsField = true,
  includePrefillQuestionField = true
}: {
  is_disabled?: number;
  includeRequiredField?: boolean;
  includeCanImpactKPIField?: boolean;
  includeCommentsField?: boolean;
  includePrefillQuestionField?: boolean;
} = {}): COControlInterface[] => {
  var conditions: COConditionSlugs[] = [];
  if (includeRequiredField) {
    conditions.push(COConditionSlugs.QUESTION_IS_REQUIRED);
  }
  if (includeCanImpactKPIField) {
    conditions.push(COConditionSlugs.QUESTION_CAN_IMPACT_KPI);
  }
  if (includeCommentsField) {
    conditions.push(COConditionSlugs.QUESTION_SHOWS_COMMENTS_SECTION);
  }
  if (includePrefillQuestionField) {
    conditions.push(COConditionSlugs.QUESTION_HAS_PREFILL_ANSWERS);
  }

  if (conditions.length === 0) {
    return [];
  }

  return [
    conditionsMultiChoiceControl({
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON
            .CONDITION_OVERRIDES
      }),
      meta: {
        title: {
          value: "Settings",
          value_localization_key: "co_control_settings_header_title"
        }
      },
      items: conditionTemplateOptions(conditions),
      is_disabled: is_disabled
    })
  ];
};

export const sectionVisibilityControls = ({
  is_disabled
}: {
  is_disabled?: number | string;
} = {}): COControlInterface[] => {
  return visibilityControl({
    target: PATH({
      route:
        PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.CONDITION_OVERRIDES
    }),
    is_disabled: is_disabled,
    items: conditionTemplateOptions([
      COConditionSlugs.HIDDEN,
      COConditionSlugs.SECTION_HIDDEN_FOR_SUBMIT,
      COConditionSlugs.SIDEBAR,
      COConditionSlugs.SECTION_SHOWN_AFTER_LIVE,
      COConditionSlugs.EDITABLE_PRE_PUBLISH
    ])
  });
};

export const sectionTabularControls = (): COControlInterface[] => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_TABULAR
      }),
      position: {
        sort_order: -900
      },
      meta: {
        title: {
          value: "Display Questions as Table",
          value_localization_key:
            "admin_assessment_customize_display_section_questions_as_table_label",
          tooltip:
            "Allows answering questions multiple times in a table format",
          tooltip_localization_key:
            "admin_assessment_customize_display_section_questions_as_table_description"
        }
      },
      options: {
        display_context_overrides: {
          assessment_customize: {
            is_hidden: 1
          },
          assessment_customize_advanced: {
            is_hidden: 0
          }
        },
        component_type: COComponentType.CONTROL_TOGGLE
      }
    }
  ];
};

export const questionVisibilityControls = ({
  is_disabled = undefined,
  includeHideBeforeLiveVisibilityOption = true,
  includeHideFromPipelineVisibilityOption = true
}: {
  is_disabled?: number | string;
  includeHideBeforeLiveVisibilityOption?: boolean;
  includeHideFromPipelineVisibilityOption?: boolean;
} = {}): COControlInterface[] => {
  var conditions: COConditionSlugs[] = [
    COConditionSlugs.HIDDEN,
    COConditionSlugs.QUESTION_HIDDEN_FOR_SUBMIT,
    COConditionSlugs.HIDE_FROM_PROFILE
  ];
  if (includeHideBeforeLiveVisibilityOption) {
    conditions.push(COConditionSlugs.QUESTION_SHOWN_AFTER_LIVE);
  }
  if (includeHideFromPipelineVisibilityOption) {
    conditions.push(COConditionSlugs.HIDE_FROM_PIPELINE);
  }

  return visibilityControl({
    target: PATH({
      route:
        PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON.CONDITION_OVERRIDES
    }),
    is_disabled: is_disabled,
    items: conditionTemplateOptions(conditions)
  });
};

export const kpiVisibilityControls = (): COControlInterface[] => {
  return visibilityControl({
    target: PATH({
      route:
        PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON.CONDITION_OVERRIDES
    }),
    items: conditionTemplateOptions([
      COConditionSlugs.HIDDEN,
      COConditionSlugs.QUESTION_HIDDEN_FOR_SUBMIT,
      COConditionSlugs.HIDE_FROM_PROFILE,
      COConditionSlugs.QUESTION_SHOWN_AFTER_LIVE,
      COConditionSlugs.HIDE_FROM_PIPELINE
    ])
  });
};

export const visibilityControl = ({
  target,
  is_disabled,
  items = []
}: {
  target?: string;
  is_disabled?: number | string;
  items?: COConditionOverrideSlugPayload[];
}): COControlInterface[] => {
  return [
    conditionsMultiChoiceControl({
      key: COControlInterfaceKeys.CONTROL_VISIBILITY,
      target,
      is_disabled,
      meta: {
        title: {
          value: "Visibility",
          value_localization_key: "co_control_view_settings_header_title"
        }
      },
      items
    })
  ];
};

export const sensitiveInformationDropdownControls = ({
  is_disabled = 0
}: {
  is_disabled?: number;
} = {}) => {
  let controls: COControlInterface[] = [
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON
            .VISIBILITY_PERMISSIONS
      }),
      controlFunction: ({
        context,
        control,
        option
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
      }) => {
        let options: any[] = option;

        if (context.question) {
          if (Array.isArray(options)) {
            if (context.question.co_question_options_json) {
              context.question.co_question_options_json.visibility_permissions = options.map(
                option => {
                  return option.value;
                }
              );
            }
          } else {
            if (context.question.co_question_options_json) {
              context.question.co_question_options_json.visibility_permissions = [];
            }
          }
        }
      },
      meta: {
        title: {
          value: "Sensitive information",
          value_localization_key: "co_control_sensitive_information_title",
          tooltip:
            "Field will be visible only to users with the selected permissions.",
          tooltip_localization_key: "co_control_sensitive_information_tooltip"
        }
      },
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        max_selectable_answers: 10000,
        is_disabled: is_disabled
      },
      items: permissionsInterfaces.map(permissionsInterface => {
        return {
          key: permissionsInterface.slug,
          value: permissionsInterface.slug,
          meta: permissionsInterface.meta
        };
      })
    }
  ];
  return controls;
};

export const kpuUsedInQuestionControl = () => {
  let controls: COControlInterface[] = [
    {
      optionIsSelectedFunction: ({
        context,
        control,
        option
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
      }) => {
        return option.checked; // because set if it's checked in the function that generates the options
      },
      controlFunction: ({
        context,
        control,
        option
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
      }) => {
        // because this is now "multi selected" - clicking an option is going to "deselect it"
        let selectedKPIOption: COControlOptionListItem | null = null;
        if (Array.isArray(option)) {
          // ok we need to find the one we just selected or deselected
          let allOptions = getValue(context, control.items); //it's probably a path
          for (const opt of allOptions) {
            // I guess its whatever doesn't match
            let matchedOption = option.find(
              selectedOption =>
                selectedOption?.value?.co_question_ahid === opt?.key
            );

            if (
              (opt.checked && !matchedOption) ||
              (opt.checked && !matchedOption.checked) ||
              (!opt.checked && matchedOption)
            ) {
              // it's been selected or deselected - this if very specific to this control
              selectedKPIOption = opt;
              break;
            }
          }
        } else {
          selectedKPIOption = option;
        }
        if (selectedKPIOption && context.function_hooks?.showModal) {
          let questionClassObject: COQuestionInterface | undefined =
            context.question;
          let kpiClassObject:
            | COQuestionInterface
            | undefined
            | null = undefined;
          // so the KPI loses it's class somehow need to find in the context
          if (selectedKPIOption.key) {
            let ahid = selectedKPIOption.key;
            if (ahid) {
              let assessment: COAssessmentInterface | undefined =
                context.assessment;
              if (assessment) {
                kpiClassObject = assessment?.findAHId?.(ahid);
              }
            }
          }
          context.function_hooks.showModal({
            context,
            modalType: COModalType.EDIT_KPI,
            payload: {
              question: questionClassObject || {},
              kpi: kpiClassObject || {}
            }
          });
        }
      },
      meta: {
        title: {
          value: "KPIs impacted",
          value_localization_key: "co_control_kpi_impacts_title",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      position: {
        sort_order: -898
      },
      options: {
        is_hidden: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.CAN_IMPACT_KPI,
          operator: PATH_OPERATORS.INVERSE
        }),
        component_type: COComponentType.CONTROL_PICKER,
        max_selectable_answers: 10000
      },
      items: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.FUNCTION_KPIS_REFERENCED_CONTROL_OPTIONS,
        operator: PATH_OPERATORS.FUNCTION
      })
    }
  ];
  return controls;
};

export const minimumCategoriesControl = () => {
  let controls: COControlInterface[] = [
    {
      meta: {
        title: {
          value: "Minimum categories required",
          value_localization_key:
            "co_control_minimum_categories_required_title",
          tooltip:
            "When employees try to submit an idea or automation, this is the minimum number of categories they will be required to select",
          tooltip_localization_key:
            "co_control_minimum_categories_required_tooltip"
        }
      },
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON
            .MIN_SELECTABLE_ANSWERS
      }),
      options: {
        component_type: COComponentType.CONTROL_PICKER
      },
      items: [
        {
          title: "One category level (e.g. Legal)",
          localization_key: "co_control_minimum_categories_one_title",
          value: 1
        },
        {
          title: "Two category levels (e.g. Compliance)",
          localization_key: "co_control_minimum_categories_two_title",
          value: 2
        },
        {
          title: "Three category levels (e.g. Compliance Intake)",
          localization_key: "co_control_minimum_categories_three_title",
          value: 3
        },
        {
          title: "Four category levels",
          localization_key: "co_control_minimum_categories_four_title",
          value: 4
        },
        {
          title: "Five category levels",
          localization_key: "co_control_minimum_categories_five_title",
          value: 5
        }
      ].map(item => {
        return {
          key: "minCategories_" + item.value,
          value: item.value,
          hasCheckboxes: false,
          meta: {
            value: item.title,
            value_localization_key: item.localization_key
          }
        };
      })
    }
  ];
  return controls;
};

export const templateQuestionControl = ({
  advanced_editor_only = false,
  is_disabled = undefined
}: {
  advanced_editor_only?: boolean;
  is_disabled?: number | string;
} = {}) => {
  let controls: COControlInterface[] = [
    // Standard question control
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_TEMPLATE_SLUG
      }),
      controlFunction: ({
        context,
        control,
        option
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
      }) => {
        let question: COQuestionInterface | undefined = context.question;
        if (question) {
          updateQuestionTypeAKATemplate({
            context,
            question,
            questionTemplateSlug: option.value
          });
          COBase.incrementRenderCounters(context, context.assessment || {});
        }
      },
      meta: {
        title: {
          value: "Question type",
          value_localization_key: "co_control_template_question_title",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      position: {
        sort_order: -10000
      },
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        is_hidden: advanced_editor_only
          ? 1
          : 0 ||
            PATH({
              route: PATH_ROUTE.CONTEXT.SECTION.OPTIONS.IS_TABULAR
            }),
        is_disabled: is_disabled,
        display_context_overrides: {
          assessment_customize_advanced: {
            is_hidden: 1
          }
        }
      },
      items: PATH({
        route: PATH_ROUTE.CONTEXT.TEMPLATES.QUESTION_TYPE_CONTROL_OPTIONS
      })
    },
    // Advanced question control
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_TEMPLATE_SLUG
      }),
      controlFunction: ({
        context,
        control,
        option
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
      }) => {
        let question: COQuestionInterface | undefined = context.question;
        if (question) {
          updateQuestionTypeAKATemplate({
            context,
            question,
            questionTemplateSlug: option.value
          });
          COBase.incrementRenderCounters(context, context.assessment || {});
        }
      },
      meta: {
        title: {
          value: "Question Type (Advanced)",
          value_localization_key: "co_control_template_question_advanced_title",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      position: {
        sort_order: -10000
      },
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        is_hidden: 1,
        is_disabled: is_disabled,
        display_context_overrides: {
          assessment_customize_advanced: {
            is_hidden: PATH({
              route: PATH_ROUTE.CONTEXT.SECTION.OPTIONS.IS_TABULAR
            })
          }
        }
      },
      items: PATH({
        route:
          PATH_ROUTE.CONTEXT.TEMPLATES.QUESTION_TYPE_ADVANCED_CONTROL_OPTIONS
      })
    },
    // Tabular question control
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_TEMPLATE_SLUG
      }),
      controlFunction: ({
        context,
        control,
        option
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
      }) => {
        let question: COQuestionInterface | undefined = context.question;
        if (question) {
          updateQuestionTypeAKATemplate({
            context,
            question,
            questionTemplateSlug: option.value
          });
          COBase.incrementRenderCounters(context, context.assessment || {});
        }
      },
      meta: {
        title: {
          value: "Column type",
          value_localization_key: "co_control_template_tabular_question_title",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      position: {
        sort_order: -10000
      },
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        is_hidden: PATH({
          route: PATH_ROUTE.CONTEXT.SECTION.OPTIONS.IS_TABULAR,
          operator: PATH_OPERATORS.INVERSE
        }),
        is_disabled: is_disabled
      },
      items: PATH({
        route: PATH_ROUTE.CONTEXT.TEMPLATES.QUESTION_TYPE_CONTROL_OPTIONS
      })
    }
  ];
  return controls;
};

export const templateKPIControl = ({
  is_disabled = undefined
}: {
  is_disabled?: number | string;
} = {}) => {
  let controls: COControlInterface[] = [
    {
      key: COControlInterfaceKeys.CONTROL_TEMPLATE,
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_TEMPLATE_SLUG
      }),
      controlFunction: ({
        context,
        control,
        option
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
      }) => {
        let question: COQuestionInterface | undefined = context.question;
        if (question) {
          updateQuestionTypeAKATemplate({
            context,
            question,
            questionTemplateSlug: option.value
          });
          COBase.incrementRenderCounters(context, context.assessment || {});
        }
      },
      meta: {
        title: {
          value: "KPI type",
          value_localization_key: "co_control_kpi_type_title",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      position: {
        sort_order: -1000
      },
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        is_disabled: is_disabled
      },
      items: PATH({
        route: PATH_ROUTE.CONTEXT.TEMPLATES.KPI_TYPE_CONTROL_OPTIONS
      })
    }
  ];
  return controls;
};

export const decimalNumberQuestionControls = ({
  is_disabled = undefined
}: {
  is_disabled?: number | string;
} = {}) => {
  return [
    {
      key: COControlInterfaceKeys.CONTROL_ROUNDING,
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON.ROUNDING_DECIMALS
      }),
      position: {
        sort_order: -900
      },
      meta: {
        title: {
          value: "Number formatting",
          value_localization_key: "co_control_rounding_decimal_title",
          tooltip: "Number of decimals to round to",
          tooltip_localization_key: "co_control_rounding_decimal_tooltip"
        }
      },
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        is_disabled: is_disabled
      },
      items: [0, 1, 2].map(num => ({
        key: `${num}`,
        value: `${num}`,
        meta: {
          value: `${num} Decimal points`,
          value_localization_key: `co_number_formatting_decimal_points_${num}`
        }
      }))
    }
  ];
};

export const maxSelectableAnswersQuestionControls = () => {
  return [
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON
            .MAX_SELECTABLE_ANSWERS
      }),
      meta: {
        title: {
          value: "Max selectable answers",
          value_localization_key: "co_control_max_selectable_answers_title",
          tooltip:
            "Choose the maximum number of answers that can be selected when filling in the assessment",
          tooltip_localization_key: "co_control_max_selectable_answers_tooltip"
        }
      },
      options: {
        component_type: COComponentType.CONTROL_PICKER
      },
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(number => ({
        key: `${number}-options`,
        value: number,
        meta: {
          value: `${number} Options`,
          value_localization_key: `co_control_max_selectable_answers_option_${number}`
        }
      }))
    }
  ];
};

export const equationTypeQuestionControls = () => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_DISPLAY_EQUATION
      }), // this is because the display equation is converted to the co_equation
      meta: {
        title: {
          value: "Equation type",
          value_localization_key: "co_control_equation_type_title",
          tooltip:
            "Choose which rule will be applied for the selected answers when using this question in a KPI formula",
          tooltip_localization_key: "co_control_equation_type_tooltip"
        }
      },
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        display_context_overrides: {
          assessment_customize: {
            is_hidden: 0
          }
        }
      },
      items: [
        {
          key: `max-answer`,
          value: CODefaultEquations.MAX_OF_ANSWERS,
          meta: {
            value: `Maximum answer score`,
            value_localization_key: "co_control_equation_type_choice_max"
          }
        },
        {
          key: `min-answer`,
          value: CODefaultEquations.MIN_OF_ANSWERS,
          meta: {
            value: `Minimum answer score`,
            value_localization_key: "co_control_equation_type_choice_min"
          }
        },
        {
          key: `sum-answers`,
          value: CODefaultEquations.SUM_OF_ANSWERS,
          meta: {
            value: `Sum of answer scores`,
            value_localization_key: "co_control_equation_type_choice_sum"
          }
        },
        {
          key: `average-answers`,
          value: CODefaultEquations.AVERAGE_OF_ANSWERS,
          meta: {
            value: `Average of answer scores`,
            value_localization_key: "co_control_equation_type_choice_average"
          }
        }
      ]
    }
  ];
};
export const defaultQuestionEquationAnswerControl = () => {
  return [
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_DEFAULT_OPTIONS_JSON
            .DEFAULT_EQUATION_VALUE
      }), // this is because the display equation is converted to the co_equation
      meta: {
        title: {
          value: "Default value",
          value_localization_key: "co_control_equation_value_title",
          tooltip:
            "This will be used in calculations if the question answer is undefined",
          tooltip_localization_key: "co_control_equation_value_tooltip"
        }
      },
      options: {
        display_context_overrides: {
          assessment_customize: {
            is_hidden: 1
          },
          assessment_customize_advanced: {
            is_hidden: 0
          }
        },
        component_type: COComponentType.CONTROL_TEXT
      }
    }
  ];
};

export const applicationEquationQuestionControls = () => {
  return [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.QUESTION.CO_DISPLAY_EQUATION
      }), // this is because the display equation is converted to the co_equation
      meta: {
        title: {
          value: "Calculation Style",
          value_localization_key: "co_control_display_equation_title",
          tooltip:
            "This determines how the variable for this question will be calculated.  Number of Applications is a simple count of the applications selected.  Number of Thin Client Applications is a count of only the applications with the Thin Client box checked.",
          tooltip_localization_key: "co_control_display_equation_tooltip"
        }
      },
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        display_context_overrides: {}
      },
      items: [
        {
          key: `count-applications`,
          value: CODefaultEquations.COUNT_APPLICATIONS,
          meta: {
            value: `Number of applications`,
            value_localization_key:
              "co_control_display_equation_choice_count_application"
          }
        },
        {
          key: `count-thin-applications`,
          value: CODefaultEquations.COUNT_THIN_APPLICATIONS,
          meta: {
            value: `Number of Thin Client applications`,
            value_localization_key:
              "co_control_display_equation_choice_count_thin_application"
          }
        }
      ]
    }
  ];
};
export const kpiChartControls = ({
  is_disabled = undefined
}: {
  is_disabled?: number | string;
}) => {
  return [
    ...questionPickerControl({
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON.CHART.X_AXIS_AHID
      }),
      meta: {
        title: {
          value: "X Axis",
          value_localization_key: "co_control_x_axis_title",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      position: {
        sort_order: 10
      },
      options: {
        is_disabled: is_disabled
      }
    }),
    ...chartRangePickerControl({
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON.CHART
            .X_AXIS_RANGE
      }),
      meta: {
        title: {
          value: "X Axis Range",
          value_localization_key: "co_control_x_axis_range_title",
          tooltip: "format is min:max",
          tooltip_localization_key: "co_control_x_axis_range_tooltip"
        }
      },
      position: {
        sort_order: 11
      },
      options: {
        is_disabled: is_disabled
      }
    }),
    ...questionPickerControl({
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON.CHART.Y_AXIS_AHID
      }),
      meta: {
        title: {
          value: "Y Axis",
          value_localization_key: "co_control_y_axis_title",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      position: {
        sort_order: 12
      },
      options: {
        is_disabled: is_disabled
      }
    }),
    ...chartRangePickerControl({
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON.CHART
            .Y_AXIS_RANGE
      }),
      meta: {
        title: {
          value: "Y Axis Range",
          value_localization_key: "co_control_y_axis_range_title",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      position: {
        sort_order: 13
      },
      options: {
        is_disabled: is_disabled
      }
    }),
    ...questionPickerControl({
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON.CHART.BUBBLE_AHID
      }),
      meta: {
        title: {
          value: "Intersection Value",
          value_localization_key: "co_control_chart_bubble_ahid_title",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      position: {
        sort_order: 14
      },
      options: {
        is_disabled: is_disabled
      }
    }),
    ...chartRangePickerControl({
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON.CHART
            .BUBBLE_RANGE
      }),
      meta: {
        title: {
          value: "Intersection Range",
          value_localization_key: "co_control_chart_bubble_range_title",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      position: {
        sort_order: 15
      },
      options: {
        is_disabled: is_disabled
      }
    })
  ];
};

export const answerPickerControl = ({
  target,
  control,
  meta,
  position,
  advanced_editor_only = false,
  options = {}
}: {
  target: string;
  control?: COControlFunction;
  meta: COMetadataInterface;
  position: COPositionInterface;
  advanced_editor_only?: boolean;
  options?: COOptionsInterface;
}) => {
  let controls: COControlInterface[] = [
    {
      controlFunction: control,
      target: target,
      meta: meta,
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        display_context_overrides: advanced_editor_only
          ? {
              assessment_view: {
                component_type: COComponentType.META_TEXT
              }
            }
          : {},
        ...options
      },
      position: position,
      items: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.FUNCTION_QUESTION_ANSWERS_CONTROL_OPTIONS,
        operator: PATH_OPERATORS.FUNCTION
      })
    }
  ];
  return controls;
};

export const questionPickerControl = ({
  target,
  meta,
  position,
  advanced_editor_only = false,
  options = {}
}: {
  target: string;
  meta: COMetadataInterface;
  position: COPositionInterface;
  advanced_editor_only?: boolean;
  options?: COOptionsInterface;
}) => {
  let controls: COControlInterface[] = [
    {
      target: target,
      meta: meta,
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        display_context_overrides: advanced_editor_only
          ? {
              assessment_view: {
                component_type: COComponentType.META_TEXT
              },
              assessment_customize: {
                is_disabled: 1
              },
              assessment_customize_advanced: {
                is_disabled: 0
              }
            }
          : {},
        ...options
      },
      position: position,
      items: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION
            .FUNCTION_QUESTIONS_THAT_CAN_IMPACT_KPIS_CONTROL_OPTIONS,
        operator: PATH_OPERATORS.FUNCTION
      })
    }
  ];
  return controls;
};

export const chartRangePickerControl = ({
  target,
  meta,
  position,
  advanced_editor_only = false,
  options = {}
}: {
  target: string;
  meta: COMetadataInterface;
  position: COPositionInterface;
  advanced_editor_only?: boolean;
  options?: COOptionsInterface;
}) => {
  let controls: COControlInterface[] = [
    {
      target: target,
      meta: meta,
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        display_context_overrides: advanced_editor_only
          ? {
              assessment_view: {
                component_type: COComponentType.META_TEXT
              },
              assessment_customize: {
                is_disabled: 1
              },
              assessment_customize_advanced: {
                is_disabled: 0
              }
            }
          : {},
        ...options
      },
      position: position,
      items: Object.keys(CO_CHART_RANGES).map(key => {
        return {
          key: CO_CHART_RANGES[key],
          value: CO_CHART_RANGES[key],
          meta: {
            value: CO_CHART_RANGES[key]
          }
        };
      })
    }
  ];
  return controls;
};

export const sectionControls = ({
  is_disabled = undefined
}: {
  is_disabled?: number | string;
} = {}) => {
  return [...sectionVisibilityControls({ is_disabled: is_disabled })];
};

export const sideBarOnlySectionControls = () => {
  return sectionVisibilityControls();
};

export const sectionApplicationCanAddApplicationsControls = () => {
  let controls: COControlInterface[] = [
    {
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON
            .DISABLE_ADDING_APPLICATIONS,
        operator: PATH_OPERATORS.INVERSE
      }),
      meta: {
        title: {
          options: { component_type: COComponentType.META_TEXT },
          value: "Allow users to add new Applications",
          value_localization_key: "co_control_disable_adding_application_title",
          tooltip:
            "Users will be unable to add new applications if this setting is disabled.",
          tooltip_localization_key:
            "co_control_disable_adding_application_tooltip"
        }
      },
      options: {
        component_type: COComponentType.CONTROL_TOGGLE
      }
    }
  ];
  return controls;
};

export const questionAnswerQuestionPickerControl = ({
  advanced_editor_only = false
}: {
  advanced_editor_only: boolean;
}) => {
  let controls: COControlInterface[] = [
    {
      target: PATH({
        route: PATH_ROUTE.CONTEXT.ANSWER_OPTION.META.ANSWER_VALUE.VALUE
      }),
      meta: {}, // no meta - inline
      options: {
        component_type: COComponentType.CONTROL_PICKER,
        display_context_overrides: advanced_editor_only
          ? {
              assessment_view: {
                component_type: COComponentType.META_TEXT
              },
              assessment_customize: {
                is_disabled: 1
              },
              assessment_customize_advanced: {
                is_disabled: 0
              }
            }
          : {}
      },
      items: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.FUNCTION_OTHER_QUESTIONS_CONTROL_OPTIONS,
        operator: PATH_OPERATORS.FUNCTION
      })
    }
  ];
  return controls;
};

// this basically works - but I'm having an issue with the blur I think - if you add a new one before the blur "saves" your typed text - then it might wipe it out

export const addMultipleAnswersControls = () => {
  let controls: COControlInterface[] = [
    {
      meta: {
        title: {
          value: "+",
          value_localization_key: "",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      controlFunction: ({
        context,
        control,
        option
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
      }) => {
        // basically we need to trigger a function to get a document
        if (context.question && context.answer_option) {
          addEmptyAdditionalAnswerSelection({
            context,
            question: context.question,
            questionAnswerOption: context.answer_option
          });
        }
      },
      options: {
        is_hidden: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_SELECTABLE_ANSWERS,
          operator: PATH_OPERATORS.IS_NOT_GREATER_THAN_ONE
        }),
        component_type: COComponentType.CONTROL_BUTTON
      }
    },
    {
      meta: {
        title: {
          value: "-",
          value_localization_key: "",
          tooltip: "",
          tooltip_localization_key: ""
        }
      },
      controlFunction: ({
        context,
        control,
        option
      }: {
        context: COContextInterface;
        control: COControlInterface;
        option: any;
      }) => {
        // basically we need to trigger a function to get a document
        if (
          context.question &&
          context.process_answer &&
          context.process_answer_selection
        ) {
          removeAnswerSelection({
            context,
            process_answer: context.process_answer,
            process_answer_selection: context.process_answer_selection
          });
        }
      },
      options: {
        is_hidden: PATH({
          route: PATH_ROUTE.CONTEXT.QUESTION.OPTIONS.MAX_SELECTABLE_ANSWERS,
          operator: PATH_OPERATORS.IS_NOT_GREATER_THAN_ONE
        }),
        component_type: COComponentType.CONTROL_BUTTON
      }
    }
  ];
  return controls;
};

export const showInTableFormatControls = ({
  is_disabled = 0
}: {
  is_disabled?: number;
} = {}): COControlInterface[] => {
  return [
    conditionsSingleChoiceControl({
      key: COControlInterfaceKeys.CONTROL_VISIBILITY,
      target: PATH({
        route:
          PATH_ROUTE.CONTEXT.QUESTION.CO_QUESTION_OPTIONS_JSON
            .CONDITION_OVERRIDES
      }),
      meta: {
        title: {
          value: "Appearance",
          value_localization_key: "co_control_appearence"
        }
      },
      is_hidden: PATH({
        route: PATH_ROUTE.CONTEXT.SECTION.CO_SECTION_OPTIONS_JSON.IS_TABULAR
      }),
      items: conditionTemplateOptions([
        COConditionSlugs.SHOW_QUESTION_IN_TABLE_APPEARANCE,
        COConditionSlugs.SHOW_QUESTION_IN_INLINE_APPEARANCE
      ]),
      is_disabled: is_disabled
    })
  ];
};
