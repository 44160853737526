import { COAssessmentTypes } from "../constants/co-constants";
import {
  COAssessmentTemplateInterface,
  COBaseTemplateInterface
} from "../interfaces/co-interfaces";
import {
  AHDefaultQ2AssessmentJSON,
  AHFreshQ2AssessmentJSON,
  defaultQ2AssessmentTemplate
} from "./default_q2_assessment/co-default-q2-assessment.template";
import {
  AHDefaultCBAAssessmentJSON,
  cbaAssessmentTemplate
} from "./default_cba_assessment/co-default-cba-assessment.template";
import {
  AHDefaultQ1AssessmentJSON,
  AHFreshQ1AssessmentJSON,
  defaultQ1AssessmentTemplate
} from "./default_q1_assessment/co-default-q1-assessment.template";
import {
  AHDefaultOVRAssessmentJSON,
  AHFreshOVRAssessmentJSON,
  defaultOVRAssessmentTemplate
} from "./default_ovr_assessment/co-default-ovr-assessment.template";
import {
  AHDefaultMiscAssessmentJSON,
  AHFreshMiscAssessmentJSON,
  defaultMISCAssessmentTemplate
} from "./default_misc_assessment/co-default-misc-assessment.template";
import {
  AHDefaultQ3AssessmentJSON,
  AHFreshQ3AssessmentJSON,
  defaultQ3AssessmentTemplate
} from "./default_q3_assessment/co-default-q3-assessment.template";
import {
  AHDefaultStatsAssessmentJSON,
  defaultStatsAssessmentTemplate
} from "./default_stats_assessment/co-default-stats-assessment.template";
import { sectionTemplateForSlug } from "./co-section.template";
import { questionAnswerOptionTemplateForSlug } from "./co-question-answer-options.template";
import { questionTemplateForSlug } from "./co-questions.template";
import { kpiTemplateForSlug } from "./co-kpis.template";
import { processAnswerTemplateForSlug } from "./co-process-answer.template";
import {
  StandardQuestionAnswerOptionSlugs,
  NonStandardQuestionAnswerOptionSlugs,
  StandardProcessAnswerSlugs,
  StandardQuestionSlugs,
  NonStandardQuestionSlugs,
  StandardKPISlugs,
  NonStandardKPISlugs
} from "../constants/co-question.constants";
import {
  CONonStandardSectionTemplateSlugs,
  COSectionTemplateSlugs
} from "../constants/co-section.constants";
import { COAssessmentTemplateSlugs } from "../constants/co-assessment.constants";
import { inEnum } from "../utils/co-utils";
import {
  AHDefaultCRAssessmentJSON,
  AHFreshCRAssessmentJSON,
  defaultCRAssessmentTemplate
} from "./default_cr_assessment/co-default-cr-assessment.template";

export const getDefaultAssessmentJSON = (
  type: COAssessmentTypes = COAssessmentTypes.DETAILED_ASSESSMENT_TYPE,
  isDefault: boolean = true
) => {
  switch (type) {
    case COAssessmentTypes.OVERVIEW_ASSESSMENT_TYPE:
      return isDefault
        ? AHDefaultOVRAssessmentJSON()
        : AHFreshOVRAssessmentJSON();
    case COAssessmentTypes.HIGH_LEVEL_ASSESSMENT_TYPE:
      return isDefault
        ? AHDefaultQ1AssessmentJSON()
        : AHFreshQ1AssessmentJSON();
    case COAssessmentTypes.DETAILED_ASSESSMENT_TYPE:
      return isDefault
        ? AHDefaultQ2AssessmentJSON()
        : AHFreshQ2AssessmentJSON();
    case COAssessmentTypes.COST_BENEFIT_ANALYSIS_ASSESSMENT_TYPE:
      return AHDefaultCBAAssessmentJSON();
    case COAssessmentTypes.CITIZEN_DEVELOPER_ASSESSMENT_TYPE:
      return isDefault
        ? AHDefaultQ3AssessmentJSON()
        : AHFreshQ3AssessmentJSON();
    case COAssessmentTypes.MISC_ASSESSMENT_TYPE:
      return isDefault
        ? AHDefaultMiscAssessmentJSON()
        : AHFreshMiscAssessmentJSON();
    case COAssessmentTypes.STATS_ASSESSMENT_TYPE:
      return AHDefaultStatsAssessmentJSON();
    case COAssessmentTypes.CHANGE_REQUEST_ASSESSMENT_TYPE:
      return isDefault
        ? AHDefaultCRAssessmentJSON()
        : AHFreshCRAssessmentJSON();
  }
};

export const assessmentTemplateForSlug = template_slug => {
  return assessmentTemplates().find(
    element => element.co_assessment_template_slug === template_slug
  );
};

export const assessmentTemplates = (): COAssessmentTemplateInterface[] => {
  return [
    defaultOVRAssessmentTemplate(),
    defaultQ1AssessmentTemplate(),
    defaultQ2AssessmentTemplate(),
    cbaAssessmentTemplate(),
    defaultQ3AssessmentTemplate(),
    defaultMISCAssessmentTemplate(),
    defaultStatsAssessmentTemplate(),
    defaultCRAssessmentTemplate()
  ];
};

// centralized - need to ensure no conflicts within the slugs
export const loadTemplateForSlug = (
  template_slug: string
): COBaseTemplateInterface | undefined => {
  // check the slugs and load assessment question section template whatever
  let loadedTemplate: COBaseTemplateInterface | undefined = undefined;
  if (
    !loadedTemplate &&
    (inEnum(StandardQuestionAnswerOptionSlugs, template_slug) ||
      inEnum(NonStandardQuestionAnswerOptionSlugs, template_slug))
  ) {
    loadedTemplate = questionAnswerOptionTemplateForSlug(template_slug);
  }
  if (!loadedTemplate && inEnum(StandardProcessAnswerSlugs, template_slug)) {
    loadedTemplate = processAnswerTemplateForSlug(template_slug);
  }
  if (
    !loadedTemplate &&
    (inEnum(StandardQuestionSlugs, template_slug) ||
      inEnum(NonStandardQuestionSlugs, template_slug))
  ) {
    loadedTemplate = questionTemplateForSlug(template_slug);
  }
  if (
    !loadedTemplate &&
    (inEnum(StandardKPISlugs, template_slug) ||
      inEnum(NonStandardKPISlugs, template_slug))
  ) {
    loadedTemplate = kpiTemplateForSlug(template_slug);
  }
  if (
    !loadedTemplate &&
    (inEnum(COSectionTemplateSlugs, template_slug) ||
      inEnum(CONonStandardSectionTemplateSlugs, template_slug))
  ) {
    loadedTemplate = sectionTemplateForSlug(template_slug);
  }
  if (!loadedTemplate && inEnum(COAssessmentTemplateSlugs, template_slug)) {
    loadedTemplate = assessmentTemplateForSlug(template_slug);
  }
  if (!loadedTemplate) {
    console.log(`Warning! Unable to load template for slug!! ${template_slug}`);
  }
  return loadedTemplate;
};
