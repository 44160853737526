import {
  APPROVE_IDEAS,
  REJECT_IDEAS,
  STATUS_PIPELINE_GENERIC_WITH_CHECKBOX_LIST_MODAL,
  STATUS_PIPELINE_GENERIC_WITH_RADIO_BUTTONS_MODAL,
  STATUS_PIPELINE_GENERIC_MODAL
} from "./modalTypes";

import {
  AUTOMATION_BUSINESS_REVIEW_APPROVE,
  AUTOMATION_BUSINESS_REVIEW_REJECT,
  AUTOMATION_TECHNICAL_REVIEW_APPROVE,
  AUTOMATION_TECHNICAL_REVIEW_MOVE_TO_TECHNICAL_REVIEW,
  AUTOMATION_TECHNICAL_REVIEW_REWORK_REQUIRED,
  MARK_DUPLICATE_IDEA_SUCCESS_EVENT_NAME,
  REJECT_ASSESSED_IDEA_SUCCESS_EVENT_NAME
} from "../utils/analyticsUtils";
import {
  DEVELOPMENT_TYPE_CD_POWER_USER_SLUG,
  DEVELOPMENT_TYPE_CD_SELF_USER_SLUG,
  DEVELOPMENT_TYPE_COE_USER_SLUG
} from "./profileConstants";

// duplicated for now in co-phase-status.constants
export const APPROVE = "APPROVE";
export const REJECT = "REJECT";
export const ARCHIVE = "ARCHIVE";
export const MARK_AS_COMPLETE = "MARK_AS_COMPLETE";
export const MARK_AS_DUPLICATE = "MARK_AS_DUPLICATE";
export const PUT_ON_HOLD = "PUT_ON_HOLD";
export const MOVE_TO = "MOVE_TO";
export const REWORK = "REWORK";
export const APPROVE_COE = "APPROVE_COE";
export const APPROVE_CD = "APPROVE_CD";
export const START_DEVELOPMENT = "START_DEVELOPMENT";
export const START_COE_DEVELOPMENT = "START_COE_DEVELOPMENT";
export const DELETE = "DELETE";
export const START_DEVELOPMENT_IN_STUDIO = "START_DEVELOPMENT_IN_STUDIO";

export const QUALIFICATION = "QUALIFICATION";

export const IDEA_DRAFT = "IDEA_DRAFT";
export const IDEA_AWAITING_REVIEW = "IDEA_AWAITING_REVIEW";
export const IDEA_APPROVED = "IDEA_APPROVED";
export const IDEA_REJECTED = "IDEA_REJECTED";
export const IDEA_DUPLICATE = "IDEA_DUPLICATE";
export const IDEA_ARCHIVED = "IDEA_ARCHIVED";
export const ASSESSMENT_NOT_STARTED = "ASSESSMENT_NOT_STARTED";
export const ASSESSMENT_ON_HOLD = "ASSESSMENT_ON_HOLD";
export const ASSESSMENT_IN_PROGRESS = "ASSESSMENT_IN_PROGRESS";
export const ASSESSMENT_AWAITING_REVIEW = "ASSESSMENT_AWAITING_REVIEW";
export const ASSESSMENT_ARCHIVED = "ASSESSMENT_ARCHIVED";
export const ASSESSMENT_REJECTED = "ASSESSMENT_REJECTED";
export const ASSESSMENT_POSTPONED = "ASSESSMENT_POSTPONED";
export const QUALIFICATION_ON_HOLD = "QUALIFICATION_ON_HOLD";
export const QUALIFICATION_AWAITING_REVIEW = "QUALIFICATION_AWAITING_REVIEW";
export const QUALIFICATION_APPROVED = "QUALIFICATION_APPROVED";
export const QUALIFICATION_REJECTED = "QUALIFICATION_REJECTED";
export const QUALIFICATION_ARCHIVED = "QUALIFICATION_ARCHIVED";
export const ANALYSIS_NOT_STARTED = "ANALYSIS_NOT_STARTED";
export const ANALYSIS_ON_TRACK = "ANALYSIS_ON_TRACK";
export const ANALYSIS_CANCELLED = "ANALYSIS_CANCELLED";
export const ANALYSIS_ON_HOLD = "ANALYSIS_ON_HOLD";
export const ANALYSIS_AT_RISK = "ANALYSIS_AT_RISK";
export const ANALYSIS_DELAYED = "ANALYSIS_DELAYED";
export const ANALYSIS_COMPLETED = "ANALYSIS_COMPLETED";
export const SOLUTION_DESIGN_ON_TRACK = "SOLUTION_DESIGN_ON_TRACK";
export const SOLUTION_DESIGN_CANCELLED = "SOLUTION_DESIGN_CANCELLED";
export const SOLUTION_DESIGN_ON_HOLD = "SOLUTION_DESIGN_ON_HOLD";
export const SOLUTION_DESIGN_AT_RISK = "SOLUTION_DESIGN_AT_RISK";
export const SOLUTION_DESIGN_DELAYED = "SOLUTION_DESIGN_DELAYED";
export const SOLUTION_DESIGN_COMPLETED = "SOLUTION_DESIGN_COMPLETED";
export const DEVELOPMENT_NOT_STARTED = "DEVELOPMENT_NOT_STARTED";
export const DEVELOPMENT_IN_PROGRESS = "DEVELOPMENT_IN_PROGRESS";
export const COE_DEVELOPMENT_IN_PROGRESS = "COE_DEVELOPMENT_IN_PROGRESS";
// Not an actual phase status, only here in order to create a modal
export const DEVELOPMENT_IN_PROGRESS_IN_STUDIO =
  "DEVELOPMENT_IN_PROGRESS_IN_STUDIO";
export const DEVELOPMENT_CANCELLED = "DEVELOPMENT_CANCELLED";
export const DEVELOPMENT_ON_HOLD = "DEVELOPMENT_ON_HOLD";
export const DEVELOPMENT_AT_RISK = "DEVELOPMENT_AT_RISK";
export const DEVELOPMENT_DELAYED = "DEVELOPMENT_DELAYED";
export const DEVELOPMENT_COMPLETED = "DEVELOPMENT_COMPLETED";
export const TESTING_ON_TRACK = "TESTING_ON_TRACK";
export const TESTING_CANCELLED = "TESTING_CANCELLED";
export const TESTING_ON_HOLD = "TESTING_ON_HOLD";
export const TESTING_AT_RISK = "TESTING_AT_RISK";
export const TESTING_DELAYED = "TESTING_DELAYED";
export const TESTING_COMPLETED = "TESTING_COMPLETED";
export const HYPERCARE_ON_TRACK = "HYPERCARE_ON_TRACK";
export const HYPERCARE_CANCELLED = "HYPERCARE_CANCELLED";
export const HYPERCARE_ON_HOLD = "HYPERCARE_ON_HOLD";
export const HYPERCARE_AT_RISK = "HYPERCARE_AT_RISK";
export const HYPERCARE_DELAYED = "HYPERCARE_DELAYED";
export const HYPERCARE_COMPLETED = "HYPERCARE_COMPLETED";
export const LIVE_ONGOING = "LIVE_ONGOING";
export const LIVE_RETIRED = "LIVE_RETIRED";
export const LIVE_ON_HOLD = "LIVE_ON_HOLD";
export const LIVE_COMPLETED = "LIVE_COMPLETED";
export const LIVE_READY_FOR_PRODUCTION = "LIVE_READY_FOR_PRODUCTION";
export const LIVE_HYPERCARE = "LIVE_HYPERCARE";
export const BUSINESS_REVIEW_AWAITING_REVIEW =
  "BUSINESS_REVIEW_AWAITING_REVIEW";
export const BUSINESS_REVIEW_APPROVED = "BUSINESS_REVIEW_APPROVED";
export const BUSINESS_REVIEW_REJECTED = "BUSINESS_REVIEW_REJECTED";
export const BUSINESS_REVIEW_ON_HOLD = "BUSINESS_REVIEW_ON_HOLD";
export const BUSINESS_REVIEW_ARCHIVED = "BUSINESS_REVIEW_ARCHIVED";
export const TECHNICAL_REVIEW_AWAITING_REVIEW =
  "TECHNICAL_REVIEW_AWAITING_REVIEW";
export const TECHNICAL_REVIEW_REWORK_REQUIRED =
  "TECHNICAL_REVIEW_REWORK_REQUIRED";
export const TECHNICAL_REVIEW_ON_HOLD = "TECHNICAL_REVIEW_ON_HOLD";
export const TECHNICAL_REVIEW_ARCHIVED = "TECHNICAL_REVIEW_ARCHIVED";

export const ANALYSIS_ARCHIVED = "ANALYSIS_ARCHIVED";
export const SOLUTION_DESIGN_ARCHIVED = "SOLUTION_DESIGN_ARCHIVED";
export const DEVELOPMENT_ARCHIVED = "DEVELOPMENT_ARCHIVED";
export const TESTING_ARCHIVED = "TESTING_ARCHIVED";
export const LIVE_ARCHIVED = "LIVE_ARCHIVED";

export const DRAFT = "Draft";
export const APPROVED = "Approved";
export const ARCHIVED = "Archived";
export const REJECTED = "Rejected";
export const DUPLICATE = "Duplicate";
export const NOT_STARTED = "Not Started";
export const ON_HOLD = "On Hold";
export const IN_PROGRESS = "In Progress";
export const AT_RISK = "At-Risk";
export const AWAITING_REVIEW = "Awaiting Review";
export const ON_TRACK = "On Track";
export const CANCELLED = "Cancelled";
export const DELAYED = "Delayed";
export const COMPLETED = "Completed";
export const ONGOING = "In Production";
export const READY_FOR_PRODUCTION = "Ready For Production";
// export const AT_RISK = "AT_RISK";
// export const DELAYED = "DELAYED";
// export const COMPLETED = "COMPLETED";
// export const AT_RISK = "AT_RISK";
// export const DELAYED = "DELAYED";
// export const COMPLETED = "COMPLETED";
// export const AT_RISK = "AT_RISK";
// export const DELAYED = "DELAYED";
// export const COMPLETED = "COMPLETED";
// export const AT_RISK = "AT_RISK";
// export const DELAYED = "DELAYED";
// export const COMPLETED = "COMPLETED";
// export const RETIRED = "RETIRED";
// export const COMPLETED = "COMPLETED";

//TODO add id's for CD phase and statuses
export const IDEA_PHASE_ID = 1;
export const ASSESSMENT_PHASE_ID = 2;
export const QUALIFICATION_PHASE_ID = 3;
export const ANALYSIS_PHASE_ID = 4;
export const SOLUTION_PHASE_ID = 5;
export const DEVELOPMENT_PHASE_ID = 6;
export const TESTING_PHASE_ID = 7;
export const HYPERCARE_PHASE_ID = 8;
export const LIVE_PHASE_ID = 9;
export const BUSINESS_REVIEW_ID = 10;
export const TECHNICAL_REVIEW_ID = 11;

export const IDEA_PHASE_KEY = "IDEA";
export const BUSINESS_REVIEW_PHASE_KEY = "BUSINESS_REVIEW";
export const TECHNICAL_REVIEW_PHASE_KEY = "TECHNICAL_REVIEW";
export const ASSESSMENT_PHASE_KEY = "ASSESSMENT";
export const LIVE_PHASE_KEY = "LIVE";

export const IDEA_AWAITING_REVIEW_ID = 46;
export const IDEA_APPROVED_ID = 47;
export const IDEA_REJECTED_ID = 48;
export const IDEA_DUPLICATE_ID = 49;
export const IDEA_ARCHIVED_ID = 50;

export const ASSESSMENT_NOT_STARTED_ID = 51;
export const ASSESSMENT_ON_HOLD_ID = 52;
export const ASSESSMENT_IN_PROGRESS_ID = 53;
export const ASSESSMENT_ARCHIVED_ID = 92;
export const ASSESSMENT_AWAITING_ID = 101;
export const ASSESSMENT_REJECTED_ID = 102;
export const ASSESSMENT_POSTPONED_ID = 103;

export const QUALIFICATION_ON_HOLD_ID = 54;
export const QUALIFICATION_AWAITING_REVIEW_ID = 55;
export const QUALIFICATION_APPROVED_ID = 56;
export const QUALIFICATION_REJECTED_ID = 57;
export const QUALIFICATION_ARCHIVED_ID = 93;

export const ANALYSIS_NOT_STARTED_ID = 58;
export const ANALYSIS_ON_TRACK_ID = 59;
export const ANALYSIS_CANCELLED_ID = 60;
export const ANALYSIS_ON_HOLD_ID = 61;
export const ANALYSIS_AT_RISK_ID = 62;
export const ANALYSIS_DELAYED_ID = 63;
export const ANALYSIS_COMPLETED_ID = 64;
export const ANALYSIS_ARCHIVED_ID = 94;

export const SOLUTION_DESIGN_ON_TRACK_ID = 65;
export const SOLUTION_DESIGN_CANCELLED_ID = 66;
export const SOLUTION_DESIGN_ON_HOLD_ID = 67;
export const SOLUTION_DESIGN_AT_RISK_ID = 68;
export const SOLUTION_DESIGN_DELAYED_ID = 69;
export const SOLUTION_DESIGN_COMPLETED_ID = 70;
export const SOLUTION_DESIGN_ARCHIVED_ID = 95;

export const DEVELOPMENT_IN_PROGRESS_ID = 71;
export const DEVELOPMENT_CANCELLED_ID = 72;
export const DEVELOPMENT_ON_HOLD_ID = 73;
export const DEVELOPMENT_AT_RISK_ID = 74;
export const DEVELOPMENT_DELAYED_ID = 75;
export const DEVELOPMENT_COMPLETED_ID = 76;
export const DEVELOPMENT_ARCHIVED_ID = 96;
export const DEVELOPMENT_NOT_STARTED_ID = 115;

export const TESTING_ON_TRACK_ID = 77;
export const TESTING_CANCELLED_ID = 78;
export const TESTING_ON_HOLD_ID = 79;
export const TESTING_AT_RISK_ID = 80;
export const TESTING_DELAYED_ID = 81;
export const TESTING_COMPLETED_ID = 82;
export const TESTING_ARCHIVED_ID = 97;

export const LIVE_ONGOING_ID = 89;
export const LIVE_ON_HOLD_ID = 90;
export const LIVE_COMPLETED_ID = 91;
export const HYPERCARE_ARCHIVED_ID = 98;
export const LIVE_ARCHIVED_ID = 99;
export const LIVE_READY_FOR_PRODUCTION_ID = 104;
export const LIVE_HYPERCARE_ID = 105;

export const BUSINESS_REVIEW_AWAITING_REVIEW_ID = 106;
export const BUSINESS_REVIEW_APPROVED_ID = 109;
export const BUSINESS_REVIEW_REJECTED_ID = 108;
export const BUSINESS_REVIEW_ON_HOLD_ID = 107;
export const BUSINESS_REVIEW_ARCHIVED_ID = 110;
export const TECHNICAL_REVIEW_AWAITING_REVIEW_ID = 111;
export const TECHNICAL_REVIEW_REWORK_REQUIRED_ID = 113;
export const TECHNICAL_REVIEW_ON_HOLD_ID = 112;
export const TECHNICAL_REVIEW_ARCHIVED_ID = 114;

export const APPROVED_IDS = [
  IDEA_APPROVED_ID,
  QUALIFICATION_APPROVED_ID,
  BUSINESS_REVIEW_APPROVED_ID
];
export const NOT_STARTED_IDS = [
  ASSESSMENT_NOT_STARTED_ID,
  ANALYSIS_NOT_STARTED_ID,
  DEVELOPMENT_NOT_STARTED_ID
];
export const AWAITING_REVIEW_IDS = [
  IDEA_AWAITING_REVIEW_ID,
  QUALIFICATION_AWAITING_REVIEW_ID,
  ASSESSMENT_AWAITING_ID,
  BUSINESS_REVIEW_AWAITING_REVIEW_ID,
  TECHNICAL_REVIEW_AWAITING_REVIEW_ID
];
export const ON_TRACK_IDS = [];
export const COMPLETED_IDS = [
  ANALYSIS_COMPLETED_ID,
  SOLUTION_DESIGN_COMPLETED_ID,
  DEVELOPMENT_COMPLETED_ID,
  TESTING_COMPLETED_ID,
  LIVE_COMPLETED_ID,
  LIVE_HYPERCARE_ID
];
export const REJECTED_IDS = [
  IDEA_REJECTED_ID,
  ASSESSMENT_REJECTED_ID,
  QUALIFICATION_REJECTED_ID,
  BUSINESS_REVIEW_REJECTED_ID
];
export const AT_RISK_IDS = [
  ANALYSIS_AT_RISK_ID,
  SOLUTION_DESIGN_AT_RISK_ID,
  DEVELOPMENT_AT_RISK_ID,
  TESTING_AT_RISK_ID
];
export const DELAYED_IDS = [
  ANALYSIS_DELAYED_ID,
  SOLUTION_DESIGN_DELAYED_ID,
  DEVELOPMENT_DELAYED_ID,
  TESTING_DELAYED_ID
];
export const CANCELLED_IDS = [
  ANALYSIS_CANCELLED_ID,
  SOLUTION_DESIGN_CANCELLED_ID,
  DEVELOPMENT_CANCELLED_ID,
  TESTING_CANCELLED_ID
];

export const REWORK_REQUIRED_IDS = [TECHNICAL_REVIEW_REWORK_REQUIRED_ID];

export const DUPLICATE_IDS = [IDEA_DUPLICATE_ID];
export const ON_HOLD_IDS = [
  ASSESSMENT_ON_HOLD_ID,
  ASSESSMENT_POSTPONED_ID,
  QUALIFICATION_ON_HOLD_ID,
  ANALYSIS_ON_HOLD_ID,
  SOLUTION_DESIGN_ON_HOLD_ID,
  DEVELOPMENT_ON_HOLD_ID,
  TESTING_ON_HOLD_ID,
  LIVE_ON_HOLD_ID,
  BUSINESS_REVIEW_ON_HOLD_ID,
  TECHNICAL_REVIEW_ON_HOLD_ID
];
export const ARCHIVED_IDS = [
  IDEA_ARCHIVED_ID,
  ASSESSMENT_ARCHIVED_ID,
  QUALIFICATION_ARCHIVED_ID,
  ANALYSIS_ARCHIVED_ID,
  HYPERCARE_ARCHIVED_ID,
  SOLUTION_DESIGN_ARCHIVED_ID,
  DEVELOPMENT_ARCHIVED_ID,
  LIVE_ARCHIVED_ID,
  TESTING_ARCHIVED_ID,
  BUSINESS_REVIEW_ARCHIVED_ID,
  TECHNICAL_REVIEW_ARCHIVED_ID
];
export const IN_PROGRESS_IDS = [
  //phase_status_name for ANALYSIS_ON_TRACK_ID, SOLUTION_DESIGN_ON_TRACK_ID and TESTING_ON_TRACK_ID is "In Progress"
  SOLUTION_DESIGN_ON_TRACK_ID,
  ANALYSIS_ON_TRACK_ID,
  TESTING_ON_TRACK_ID,
  ASSESSMENT_IN_PROGRESS_ID,
  DEVELOPMENT_IN_PROGRESS_ID
];
export const ONGOING_IDS = [LIVE_ONGOING_ID];
export const READY_FOR_PRODUCTION_IDS = [LIVE_READY_FOR_PRODUCTION_ID];

export const STATUS_NAME_IDS = {
  [APPROVED]: APPROVED_IDS,
  [ARCHIVED]: ARCHIVED_IDS,
  [REJECTED]: REJECTED_IDS,
  [DUPLICATE]: DUPLICATE_IDS,
  [NOT_STARTED]: NOT_STARTED_IDS,
  [ON_HOLD]: ON_HOLD_IDS,
  [IN_PROGRESS]: IN_PROGRESS_IDS,
  [AT_RISK]: AT_RISK_IDS,
  [AWAITING_REVIEW]: AWAITING_REVIEW_IDS,
  [ON_TRACK]: ON_TRACK_IDS,
  [CANCELLED]: CANCELLED_IDS,
  [DELAYED]: DELAYED_IDS,
  [COMPLETED]: COMPLETED_IDS,
  [ONGOING]: ONGOING_IDS,
  [READY_FOR_PRODUCTION]: READY_FOR_PRODUCTION_IDS
};

const ARCHIVE_GENERIC_MODAL_PROPS = {
  modalName: STATUS_PIPELINE_GENERIC_WITH_RADIO_BUTTONS_MODAL,
  heading: "Archive Automation?",
  headingModalLocalizationKey: "modal_archive_idea_heading",
  body:
    "Are you sure you want to archive the automation? The automation will no longer be visible in Explore Automation Program",
  bodyLocalizationKey: "modal_archive_idea_content",
  confirmText: "Archive",
  confirmTextLocalizationKey: "modal_archive_idea_archive",
  hasComment: true,
  commentLimit: 100,
  placeholder: "modal_archive_placeholder_leave_comment"
};

export const MODAL_PROPS = {
  [IDEA_DUPLICATE]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: IDEA_DUPLICATE,
    heading: "Mark As Duplicate?",
    headingModalLocalizationKey: "modal_mark_as_duplicate_heading",
    body: "Are you sure you want to mark this automation as a duplicate?",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_business_review_archive",
    confirmText: "Mark As Duplicate",
    confirmTextLocalizationKey: "modal_mark_as_duplicate_mark_as_duplicate",
    analyticEventName: MARK_DUPLICATE_IDEA_SUCCESS_EVENT_NAME
  },
  [BUSINESS_REVIEW_APPROVED]: {
    modalName: STATUS_PIPELINE_GENERIC_WITH_CHECKBOX_LIST_MODAL,
    statusChangeKey: BUSINESS_REVIEW_APPROVED,
    heading: "Approve Automation?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_business_review_approve",
    body: "By approving this automation you are confirming that it is:",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_business_review_approve",
    confirmText: "Approve",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_business_review_approve",
    listItemsDescription: [
      [
        "automation_pipeline_modal_body_business_review_approve_list_1",
        "Verified and the details included are reliable"
      ],
      [
        "automation_pipeline_modal_body_business_review_approve_list_2",
        "Adequate to be displayed in the Explore Automation Program page"
      ],
      [
        "automation_pipeline_modal_body_business_review_approve_list_3",
        "Ready for users to show interest on this automation"
      ]
    ],
    analyticEventName: AUTOMATION_BUSINESS_REVIEW_APPROVE
  },
  [BUSINESS_REVIEW_REJECTED]: {
    modalName: STATUS_PIPELINE_GENERIC_WITH_RADIO_BUTTONS_MODAL,
    statusChangeKey: BUSINESS_REVIEW_REJECTED,
    heading: "Reject Automation?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_business_review_reject",
    body:
      "Please select a reason and add a comment for rejecting the automation:",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_business_review_reject",
    confirmText: "Reject",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_business_review_reject",
    radioItems: [
      {
        text: "Duplicate Automation",
        textLocalizationKey:
          "automation_pipeline_modal_body_business_review_reject_radio_1",
        value: 0
      },
      {
        text: "Further edits are required",
        textLocalizationKey:
          "automation_pipeline_modal_body_business_review_reject_radio_2",
        value: 1
      },
      {
        text: "Automation contains dummy information",
        textLocalizationKey:
          "automation_pipeline_modal_body_business_review_reject_radio_3",
        value: 2
      },
      {
        text: "Other",
        textLocalizationKey:
          "automation_pipeline_modal_body_business_review_reject_radio_4",
        value: 3
      }
    ],
    hasComment: true,
    analyticEventName: AUTOMATION_BUSINESS_REVIEW_REJECT
  },
  [TECHNICAL_REVIEW_AWAITING_REVIEW]: {
    modalName: STATUS_PIPELINE_GENERIC_WITH_CHECKBOX_LIST_MODAL,
    statusChangeKey: TECHNICAL_REVIEW_AWAITING_REVIEW,
    heading: "Move to Technical Review?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_technical_review_move_to",
    body:
      "By moving the automation to Technical Review phase you are confirming that it is:",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_technical_review_move_to",
    confirmText: "Approve",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_technical_review_move_to",
    listItemsDescription: [
      [
        "automation_pipeline_modal_body_technical_review_move_to_list_1",
        "Required to be Technical Reviewed in order to be shared for large consumption"
      ],
      [
        "automation_pipeline_modal_body_technical_review_move_to_list_2",
        "Verified and the details included are reliable "
      ],
      [
        "automation_pipeline_modal_body_technical_review_move_to_list_3",
        "Adequate to be displayed in the Explore Automation Program page"
      ],
      [
        "automation_pipeline_modal_body_technical_review_move_to_list_4",
        "Ready for users to show interest on this automation"
      ]
    ],
    analyticEventName: AUTOMATION_TECHNICAL_REVIEW_MOVE_TO_TECHNICAL_REVIEW
  },
  [BUSINESS_REVIEW_ON_HOLD]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: BUSINESS_REVIEW_ON_HOLD,
    heading: "Put Automation on Hold?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_business_review_on_hold",
    body:
      "Are you sure you want to put on hold the automation? You will be able to approve or reject it later.",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_business_review_on_hold",
    confirmText: "Put On Hold",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_business_review_on_hold"
  },
  [BUSINESS_REVIEW_ARCHIVED]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: BUSINESS_REVIEW_ARCHIVED,
    heading: "Archive Automation",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_business_review_archive",
    body:
      "Are you sure you want to archive the automation? The automation will no longer be visible in Explore - Automations.",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_business_review_archive",
    confirmText: "Archive",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_business_review_archive",
    hasComment: true
  },
  [LIVE_ONGOING]: {
    modalName: STATUS_PIPELINE_GENERIC_WITH_CHECKBOX_LIST_MODAL,
    statusChangeKey: LIVE_ONGOING,
    heading: "Approve Automation",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_live_ongoing",
    body: "By approving you are confirming that the automation:",
    bodyLocalizationKey: "automation_pipeline_modal_body_live_ongoing",
    confirmText: "Approve",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_live_ongoing",
    listItemsDescription: [
      [
        "automation_pipeline_modal_body_live_ongoing_list_1",
        "Is verified and the details included are reliable"
      ],
      [
        "automation_pipeline_modal_body_live_ongoing_list_2",
        "Has no code quality issues"
      ]
    ],
    analyticEventName: AUTOMATION_TECHNICAL_REVIEW_APPROVE
  },
  [TECHNICAL_REVIEW_REWORK_REQUIRED]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: TECHNICAL_REVIEW_REWORK_REQUIRED,
    heading: "Rework Required?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_technical_review_rework",
    body:
      "Are you sure that this automation needs rework? Once confirmed, the Program Manager will need to decide on the next steps.",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_technical_review_rework",
    confirmText: "Rework",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_technical_review_rework",
    analyticEventName: AUTOMATION_TECHNICAL_REVIEW_REWORK_REQUIRED
  },
  [TECHNICAL_REVIEW_ON_HOLD]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: TECHNICAL_REVIEW_ON_HOLD,
    heading: "Put Automation on Hold?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_business_review_on_hold",
    body:
      "Are you sure you want to put on hold the automation? You will be able to approve or reject it later.",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_business_review_on_hold",
    confirmText: "Put On Hold",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_business_review_on_hold"
  },
  [TECHNICAL_REVIEW_ARCHIVED]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: TECHNICAL_REVIEW_ARCHIVED,
    heading: "Archive Automation",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_technical_review_archive",
    body:
      "Are you sure you want to archive the automation? The automation will no longer be visible in Explore - Automations.",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_technical_review_archive",
    confirmText: "Archive",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_technical_review_archive",
    hasComment: true
  },
  [QUALIFICATION_APPROVED]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: QUALIFICATION_APPROVED,
    heading: "Approve Automation?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_qualification_approve",
    body:
      "Are you sure you want to approve the automation <strong>to be worked by the CoE team</strong>? Once approved, you will need to assign a Project Manager and plan its implementation.",
    bodyLocalizationKey: "automation_pipeline_modal_body_qualification_approve",
    confirmText: "Approve",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_qualification_approve"
  },
  [DEVELOPMENT_NOT_STARTED]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: DEVELOPMENT_NOT_STARTED,
    heading: "Approve Automation?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_document_not_started",
    body:
      "Are you sure you want to approve the automation to <strong>be worked by a Citizen Developer Power-User</strong>? Once approved, it will move to the Implementation view and a CD Power User will be able to start the development.",
    bodyLocalizationKey: "automation_pipeline_modal_body_document_not_started",
    confirmText: "Approve",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_document_not_started"
  },
  [QUALIFICATION_REJECTED]: {
    modalName: STATUS_PIPELINE_GENERIC_WITH_RADIO_BUTTONS_MODAL,
    statusChangeKey: QUALIFICATION_REJECTED,
    heading: "Reject Automation?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_qualification_reject",
    body:
      "Please select a reason and add a comment for rejecting the automation:",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_business_review_reject",
    confirmText: "Reject",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_qualification_reject",
    radioItems: [
      {
        text: "Duplicate Automation",
        textLocalizationKey:
          "automation_pipeline_modal_body_qualification_reject_radio_1",
        value: 0
      },
      {
        text: "Further edits are required",
        textLocalizationKey:
          "automation_pipeline_modal_body_qualification_reject_radio_2",
        value: 1
      },
      {
        text: "Automation contains dummy information",
        textLocalizationKey:
          "automation_pipeline_modal_body_qualification_reject_radio_3",
        value: 2
      },
      {
        text: "Other",
        textLocalizationKey:
          "automation_pipeline_modal_body_qualification_reject_radio_4",
        value: 3
      }
    ],
    hasComment: true,
    analyticEventName: REJECT_ASSESSED_IDEA_SUCCESS_EVENT_NAME
  },
  [QUALIFICATION_ON_HOLD]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: QUALIFICATION_ON_HOLD,
    heading: "Put Automation on Hold?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_qualification_on_hold",
    body:
      "Are you sure you want to put on hold the automation? You will be able to approve or reject it later.",
    bodyLocalizationKey: "automation_pipeline_modal_body_qualification_on_hold",
    confirmText: "Put On Hold",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_qualification_on_hold"
  },

  [COE_DEVELOPMENT_IN_PROGRESS]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: DEVELOPMENT_IN_PROGRESS,
    heading: "Start Coe Development?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_coe_development_in_progress",
    body: "Do you want to start the development for this automation?",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_coe_development_in_progress",
    confirmText: "Start",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_development_in_progress"
  },
  [DEVELOPMENT_IN_PROGRESS]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: DEVELOPMENT_IN_PROGRESS,
    heading: "Start Development?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_development_in_progress",
    body:
      "Do you want to start the development for this automation? Once confirmed, you will be assigned as the Citizen Developer in the collaborators page.",
    bodyLocalizationKey:
      "automation_pipeline_modal_body_development_in_progress",
    confirmText: "Start",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_development_in_progress"
  },
  [DEVELOPMENT_IN_PROGRESS_IN_STUDIO]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: DEVELOPMENT_IN_PROGRESS,
    heading: "Start Development in Studio?",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_development_in_studio",
    body:
      'Do you want to start the development for this automation? Once confirmed, the status of the idea will be changed to "Development - In Progress"',
    bodyLocalizationKey: "automation_pipeline_modal_body_development_in_studio",
    confirmText: "Start",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_development_in_progress",
    // so BE won't trigger collaborators or extra actions
    noAdditionalBEActions: true
  },
  [DEVELOPMENT_ARCHIVED]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: DEVELOPMENT_ARCHIVED,
    heading: "Archive Automation",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_development_archive",
    body:
      "Are you sure you want to archive the automation? The automation will no longer be visible in Explore - Automations.",
    bodyLocalizationKey: "automation_pipeline_modal_body_development_archive",
    confirmText: "Archive",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_development_archive",
    hasComment: true
  },
  [LIVE_ARCHIVED]: {
    modalName: STATUS_PIPELINE_GENERIC_MODAL,
    statusChangeKey: LIVE_ARCHIVED,
    heading: "Archive Automation",
    headingModalLocalizationKey:
      "automation_pipeline_modal_header_live_archive",
    body:
      "Are you sure you want to archive the automation? The automation will no longer be visible in Explore - Automations.",
    bodyLocalizationKey: "automation_pipeline_modal_body_live_archive",
    confirmText: "Archive",
    confirmTextLocalizationKey:
      "automation_pipeline_modal_confirm_text_live_archive",
    hasComment: true
  },
  [IDEA_ARCHIVED]: {
    ...ARCHIVE_GENERIC_MODAL_PROPS,
    statusChangeKey: IDEA_ARCHIVED
  },
  [ASSESSMENT_ARCHIVED]: {
    ...ARCHIVE_GENERIC_MODAL_PROPS,
    statusChangeKey: ASSESSMENT_ARCHIVED
  },
  [QUALIFICATION_ARCHIVED]: {
    ...ARCHIVE_GENERIC_MODAL_PROPS,
    statusChangeKey: QUALIFICATION_ARCHIVED
  },
  [ANALYSIS_ARCHIVED]: {
    ...ARCHIVE_GENERIC_MODAL_PROPS,
    statusChangeKey: ANALYSIS_ARCHIVED
  },
  [SOLUTION_DESIGN_ARCHIVED]: {
    ...ARCHIVE_GENERIC_MODAL_PROPS,
    statusChangeKey: SOLUTION_DESIGN_ARCHIVED
  },
  [DEVELOPMENT_ARCHIVED]: {
    ...ARCHIVE_GENERIC_MODAL_PROPS,
    statusChangeKey: DEVELOPMENT_ARCHIVED
  },
  [TESTING_ARCHIVED]: {
    ...ARCHIVE_GENERIC_MODAL_PROPS,
    statusChangeKey: TESTING_ARCHIVED
  },
  [LIVE_ARCHIVED]: {
    ...ARCHIVE_GENERIC_MODAL_PROPS,
    statusChangeKey: LIVE_ARCHIVED
  }
};

export const AUTOMATION_DEVELOPMENT_TYPE_SECTION_VISIBILITY: {
  [key: string]: {
    isQ1Visible?: boolean;
    isQ2Visible?: boolean;
    isQ3Visible?: boolean;
  };
} = {
  [DEVELOPMENT_TYPE_COE_USER_SLUG]: {
    isQ2Visible: true
  },
  [DEVELOPMENT_TYPE_CD_POWER_USER_SLUG]: {},
  [DEVELOPMENT_TYPE_CD_SELF_USER_SLUG]: {}
};

export const AUTOMATION_STATUS_OPTIONS: {
  [key: string]: {
    modalActions?: {
      [key: string]: {
        modalName: string;
        statusChangeKey: string;
      };
    };
    automationProfileSectionVisibility?: {
      employeeIdea?: {
        isQ1Visible?: boolean;
        isQ2Visible?: boolean;
        isQ3Visible?: boolean;
      };
      coeIdea?: {
        isQ1Visible?: boolean;
        isQ2Visible?: boolean;
        isQ3Visible?: boolean;
      };
      cdIdea?: {
        isQ1Visible?: boolean;
        isQ2Visible?: boolean;
        isQ3Visible?: boolean;
      };
      taskMiningIdea?: {
        isQ1Visible?: boolean;
        isQ2Visible?: boolean;
        isQ3Visible?: boolean;
      };
      processMiningIdea?: {
        isQ1Visible?: boolean;
        isQ2Visible?: boolean;
        isQ3Visible?: boolean;
      };
    };
    automationPublishedStatus?: {
      forSubmission?: boolean;
      forDownload?: boolean;
    };
    automationAlertMessages?: {
      [key: string]: boolean;
    };
  };
} = {
  [IDEA_DRAFT]: {},
  [IDEA_AWAITING_REVIEW]: {
    modalActions: {
      [APPROVE]: {
        modalName: APPROVE_IDEAS,
        statusChangeKey: ASSESSMENT_NOT_STARTED
      },
      [REJECT]: {
        modalName: REJECT_IDEAS,
        statusChangeKey: IDEA_REJECTED
      },
      [ARCHIVE]: MODAL_PROPS[IDEA_ARCHIVED],
      [MARK_AS_DUPLICATE]: MODAL_PROPS[IDEA_DUPLICATE]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [IDEA_APPROVED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[IDEA_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [IDEA_REJECTED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[IDEA_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [IDEA_DUPLICATE]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[IDEA_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [IDEA_ARCHIVED]: {
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [ASSESSMENT_NOT_STARTED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[ASSESSMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [ASSESSMENT_ON_HOLD]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[ASSESSMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [ASSESSMENT_IN_PROGRESS]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[ASSESSMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [ASSESSMENT_AWAITING_REVIEW]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[ASSESSMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [QUALIFICATION_ON_HOLD]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[QUALIFICATION_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [QUALIFICATION_AWAITING_REVIEW]: {
    modalActions: {
      [APPROVE]: MODAL_PROPS[QUALIFICATION_APPROVED],
      [APPROVE_COE]: MODAL_PROPS[QUALIFICATION_APPROVED],
      [APPROVE_CD]: MODAL_PROPS[DEVELOPMENT_NOT_STARTED],
      [REJECT]: MODAL_PROPS[QUALIFICATION_REJECTED],
      [PUT_ON_HOLD]: MODAL_PROPS[QUALIFICATION_ON_HOLD],
      [ARCHIVE]: MODAL_PROPS[QUALIFICATION_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [QUALIFICATION_APPROVED]: {
    modalActions: {
      [REJECT]: MODAL_PROPS[QUALIFICATION_REJECTED],
      [PUT_ON_HOLD]: MODAL_PROPS[QUALIFICATION_ON_HOLD],
      [ARCHIVE]: MODAL_PROPS[QUALIFICATION_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [QUALIFICATION_ON_HOLD]: {
    modalActions: {
      [APPROVE]: MODAL_PROPS[QUALIFICATION_APPROVED],
      [APPROVE_COE]: MODAL_PROPS[QUALIFICATION_APPROVED],
      [APPROVE_CD]: MODAL_PROPS[DEVELOPMENT_NOT_STARTED],
      [REJECT]: MODAL_PROPS[QUALIFICATION_REJECTED],

      [ARCHIVE]: MODAL_PROPS[QUALIFICATION_ARCHIVED]
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [QUALIFICATION_REJECTED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[QUALIFICATION_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [ANALYSIS_NOT_STARTED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[ANALYSIS_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [ANALYSIS_ON_TRACK]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[ANALYSIS_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [ANALYSIS_CANCELLED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[ANALYSIS_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [ANALYSIS_ON_HOLD]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[ANALYSIS_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [ANALYSIS_AT_RISK]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[ANALYSIS_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [ANALYSIS_DELAYED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[ANALYSIS_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [ANALYSIS_COMPLETED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[ANALYSIS_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [SOLUTION_DESIGN_ON_TRACK]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[SOLUTION_DESIGN_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [SOLUTION_DESIGN_CANCELLED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[SOLUTION_DESIGN_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [SOLUTION_DESIGN_ON_HOLD]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[SOLUTION_DESIGN_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [SOLUTION_DESIGN_AT_RISK]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[SOLUTION_DESIGN_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [SOLUTION_DESIGN_DELAYED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[SOLUTION_DESIGN_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [SOLUTION_DESIGN_COMPLETED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[SOLUTION_DESIGN_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [DEVELOPMENT_IN_PROGRESS]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[DEVELOPMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [DEVELOPMENT_CANCELLED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[DEVELOPMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [DEVELOPMENT_ON_HOLD]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[DEVELOPMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [DEVELOPMENT_AT_RISK]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[DEVELOPMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [DEVELOPMENT_DELAYED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[DEVELOPMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [DEVELOPMENT_COMPLETED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[DEVELOPMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [TESTING_ON_TRACK]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[TESTING_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [TESTING_CANCELLED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[TESTING_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [TESTING_ON_HOLD]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[TESTING_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [TESTING_AT_RISK]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[TESTING_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [TESTING_DELAYED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[TESTING_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [TESTING_COMPLETED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[TESTING_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [LIVE_RETIRED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[LIVE_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },
  [LIVE_ON_HOLD]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[LIVE_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [LIVE_READY_FOR_PRODUCTION]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[LIVE_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [LIVE_HYPERCARE]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[LIVE_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },

  [LIVE_COMPLETED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[LIVE_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },

  [LIVE_ARCHIVED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[LIVE_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },

  [BUSINESS_REVIEW_AWAITING_REVIEW]: {
    modalActions: {
      [APPROVE]: MODAL_PROPS[BUSINESS_REVIEW_APPROVED],
      [REJECT]: MODAL_PROPS[BUSINESS_REVIEW_REJECTED],
      [PUT_ON_HOLD]: MODAL_PROPS[BUSINESS_REVIEW_ON_HOLD],
      [MOVE_TO]: MODAL_PROPS[TECHNICAL_REVIEW_AWAITING_REVIEW],
      [ARCHIVE]: MODAL_PROPS[BUSINESS_REVIEW_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: false,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },

  [BUSINESS_REVIEW_ON_HOLD]: {
    modalActions: {
      [APPROVE]: MODAL_PROPS[BUSINESS_REVIEW_APPROVED],
      [REJECT]: MODAL_PROPS[BUSINESS_REVIEW_REJECTED],
      [ARCHIVE]: MODAL_PROPS[BUSINESS_REVIEW_ARCHIVED],
      [MOVE_TO]: MODAL_PROPS[TECHNICAL_REVIEW_AWAITING_REVIEW]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },

  [BUSINESS_REVIEW_REJECTED]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[BUSINESS_REVIEW_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: false,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  },

  [BUSINESS_REVIEW_APPROVED]: {
    modalActions: {
      [REJECT]: MODAL_PROPS[BUSINESS_REVIEW_REJECTED],
      [PUT_ON_HOLD]: MODAL_PROPS[BUSINESS_REVIEW_ON_HOLD],
      [MOVE_TO]: MODAL_PROPS[TECHNICAL_REVIEW_AWAITING_REVIEW],
      [ARCHIVE]: MODAL_PROPS[BUSINESS_REVIEW_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },

  [TECHNICAL_REVIEW_AWAITING_REVIEW]: {
    modalActions: {
      [APPROVE]: MODAL_PROPS[LIVE_ONGOING],
      [REWORK]: MODAL_PROPS[TECHNICAL_REVIEW_REWORK_REQUIRED],
      [PUT_ON_HOLD]: MODAL_PROPS[TECHNICAL_REVIEW_ON_HOLD],
      [ARCHIVE]: MODAL_PROPS[TECHNICAL_REVIEW_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [TECHNICAL_REVIEW_ON_HOLD]: {
    modalActions: {
      [APPROVE]: MODAL_PROPS[LIVE_ONGOING],
      [REWORK]: MODAL_PROPS[TECHNICAL_REVIEW_REWORK_REQUIRED],
      [ARCHIVE]: MODAL_PROPS[TECHNICAL_REVIEW_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [TECHNICAL_REVIEW_REWORK_REQUIRED]: {
    modalActions: {
      [APPROVE_COE]: MODAL_PROPS[QUALIFICATION_APPROVED],
      [APPROVE_CD]: MODAL_PROPS[DEVELOPMENT_NOT_STARTED],
      [REJECT]: MODAL_PROPS[QUALIFICATION_REJECTED],
      [PUT_ON_HOLD]: MODAL_PROPS[QUALIFICATION_ON_HOLD],
      [ARCHIVE]: MODAL_PROPS[TECHNICAL_REVIEW_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [DEVELOPMENT_NOT_STARTED]: {
    modalActions: {
      [START_DEVELOPMENT]: MODAL_PROPS[DEVELOPMENT_IN_PROGRESS],
      [START_COE_DEVELOPMENT]: MODAL_PROPS[COE_DEVELOPMENT_IN_PROGRESS],
      [START_DEVELOPMENT_IN_STUDIO]:
        MODAL_PROPS[DEVELOPMENT_IN_PROGRESS_IN_STUDIO],
      [ARCHIVE]: MODAL_PROPS[DEVELOPMENT_ARCHIVED],
      [MOVE_TO]: MODAL_PROPS[TECHNICAL_REVIEW_AWAITING_REVIEW]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [COE_DEVELOPMENT_IN_PROGRESS]: {
    modalActions: {
      [MOVE_TO]: MODAL_PROPS[TECHNICAL_REVIEW_AWAITING_REVIEW],
      [ARCHIVE]: MODAL_PROPS[DEVELOPMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [DEVELOPMENT_IN_PROGRESS]: {
    modalActions: {
      [MOVE_TO]: MODAL_PROPS[TECHNICAL_REVIEW_AWAITING_REVIEW],
      [ARCHIVE]: MODAL_PROPS[DEVELOPMENT_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: true,
      forDownload: false
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: true
    }
  },
  [LIVE_ONGOING]: {
    modalActions: {
      [ARCHIVE]: MODAL_PROPS[LIVE_ARCHIVED]
    },
    automationProfileSectionVisibility: {
      employeeIdea: {
        isQ1Visible: true,
        isQ2Visible: true
      },
      coeIdea: {
        isQ2Visible: true
      },
      cdIdea: {
        isQ3Visible: true
      },
      taskMiningIdea: {
        isQ2Visible: true
      },
      processMiningIdea: {
        isQ2Visible: true
      }
    },
    automationPublishedStatus: {
      forSubmission: false,
      forDownload: true
    },
    automationAlertMessages: {
      thankYouAlertForInterestInAutomationInReview: false
    }
  }
};

export const BULK_ACTION_ALERT_PROPS = {
  [APPROVE]: {
    alertTitle: "Approve Idea/Automation",
    alertTitleLocalizationKey: "bulk_action_alert_title_approve",
    alertBody:
      "Are you sure you want to approve all selected ideas and automations?",
    alertBodyLocalizationKey: "bulk_action_alert_body_approve",
    confirmButtonText: "Approve",
    confirmButtonTextKey: "bulk_action_alert_confirm_approve"
  },
  [MARK_AS_DUPLICATE]: {
    alertTitle: "Mark As Duplicate?",
    alertTitleLocalizationKey: "bulk_action_alert_title_mark_as_duplicate",
    alertBody:
      "Are you sure you want to archive the automation? The automations will no longer be visible in Explore - Automations.",
    alertBodyLocalizationKey: "bulk_action_alert_body_mark_as_duplicate",
    confirmButtonText: "Mark As Duplicate",
    confirmButtonTextKey: "bulk_action_alert_confirm_mark_as_duplicate"
  },
  [PUT_ON_HOLD]: {
    alertTitle: "Put Automations on Hold?",
    alertTitleLocalizationKey: "bulk_action_alert_title_put_on_hold",
    alertBody:
      "Are you sure you want to put on hold the automations? You will be able to approve or reject it later.",
    alertBodyLocalizationKey: "bulk_action_alert_body_put_on_hold",
    confirmButtonText: "Put on Hold",
    confirmButtonTextKey: "bulk_action_alert_confirm_put_on_hold"
  },
  [REWORK]: {
    alertTitle: "Rework Required?",
    alertTitleLocalizationKey: "bulk_action_alert_title_rework_required",
    alertBody:
      "Are you sure that these automations need rework? Once confirmed, the Program Manager will need to decide on the next steps.",
    alertBodyLocalizationKey: "bulk_action_alert_body_rework_required",
    confirmButtonText: "Rework",
    confirmButtonTextKey: "bulk_action_alert_confirm_rework_required"
  },
  [APPROVE_COE]: {
    alertTitle: "Approve Automations?",
    alertTitleLocalizationKey: "bulk_action_alert_title_approve_coe",
    alertBody:
      "Are you sure you want to approve the automations <strong>to be worked by the CoE team</strong>? Once approved, you will need to assign a Project Manager and plan its implementation.",
    alertBodyLocalizationKey: "bulk_action_alert_body_approve_coe",
    confirmButtonText: "Approve",
    confirmButtonTextKey: "bulk_action_alert_confirm_approve_coe"
  },
  [APPROVE_CD]: {
    alertTitle: "Approve Automations?",
    alertTitleLocalizationKey: "bulk_action_alert_title_approve_cd",
    alertBody:
      "Are you sure you want to approve the automation to <strong>be worked by a Citizen Developer Power-User</strong>? Once approved, it will move to the Implementation view and a CD Power User will be able to start the development.",
    alertBodyLocalizationKey: "bulk_action_alert_body_approve_cd",
    confirmButtonText: "Approve",
    confirmButtonTextKey: "bulk_action_alert_confirm_approve_cd"
  },
  [DELETE]: {
    alertTitle: "Delete Ideas/Automations",
    alertTitleLocalizationKey: "!!!!",
    alertBody:
      "Are you sure you want to delete all selected ideas and automations?",
    alertBodyLocalizationKey: "!!!!",
    confirmButtonText: "Delete",
    confirmButtonTextKey: "!!!!"
  }
};

export const REJECT_IDEA_CHECKBOX_LIST = [
  {
    message_key: "modal_reject_idea_reason_process_inadequate",
    message_text: "Process is inadequate for automation",
    value: 0
  },
  {
    message_key: "modal_reject_idea_reason_edits_are_required",
    message_text: "Further edits are required",
    value: 1
  },
  {
    message_key: "modal_reject_idea_reason_dummy_information",
    message_text: "Process contains dummy information",
    value: 2
  },
  {
    message_key: "modal_reject_idea_reason_other",
    message_text: "Other",
    value: 3
  }
];

export const GENERAL_REJECT_CHECKBOX_LIST = [
  {
    message_text: "Duplicate Automation",
    message_key:
      "automation_pipeline_modal_body_business_review_reject_radio_1",
    value: 0
  },
  {
    message_text: "Further edits are required",
    message_key:
      "automation_pipeline_modal_body_business_review_reject_radio_2",
    value: 1
  },
  {
    message_text: "Automation contains dummy information",
    message_key:
      "automation_pipeline_modal_body_business_review_reject_radio_3",
    value: 2
  },
  {
    message_text: "Other",
    message_key:
      "automation_pipeline_modal_body_business_review_reject_radio_4",
    value: 3
  }
];
