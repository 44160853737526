import SessionHelper from "./SessionHelper";
import {
  TASK_CAPTURE_LD_KEY,
  EXTEND_TRIAL_LD_KEY,
  TASK_CAPTURE_MAC_LD_KEY,
  LOCALIZATION_TESTING_LD_KEY,
  ADMIN_MANAGE_OPEN_API_LD_KEY,
  TENANT_SELECTED_DATE_FORMAT,
  USERPILOT_KEY,
  HOTJAR_KEY,
  ADMIN_AUDIT_LOGS_LD_KEY,
  IMPORT_PIPELINE_KEY,
  AUTOMATION_CLOUD_USER_GROUP_MANAGEMENT_LD_KEY,
  AUTOMATION_STORE_KEY,
  POLICY_TERMS_FAQ_CONTACT_LINKS_KEY,
  APOLLO_DARK_THEME_KEY,
  LOCALIZATION_SPANISH_MEXICO_LD_KEY,
  LOCALIZATION_KOREAN_LD_KEY,
  LOCALIZATION_CHINESE_SIMPLIFIED_LD_KEY,
  LOCALIZATION_TURKISH_LD_KEY,
  LOCALIZATION_RUSSIAN_LD_KEY,
  LOCALIZATION_PORTUGUESE_PORTUGAL_LD_KEY,
  USER_PILOT_DARK_THEME_LD_KEY,
  AUTOMATION_STORE_OPEN_IN_ASSISTANT_LD_KEY,
  ASSISTANT_MAC_COMPATIBILITY_LD_KEY,
  TASK_MINING_INTEGRATION_LD_KEY,
  ORCHESTRATOR_MAPPING_LD_KEY,
  CO_ADVANCED_EDITING_LD_KEY,
  CO_ASSESSMENT_EXPORT_IMPORT_LD_KEY,
  CO_DEBUG_LD_KEY,
  INSIGHTS_PRIVATE_PREVIEW_LD_KEY,
  TENANT_SIGNUP_MIGRATE_DISABLED_LD_KEY,
  APOLLO_COMPACT_HEADER_OVERRIDE_LD_KEY,
  JIRA_INTEGRATION_ENABLED_LD_KEY,
  HIDE_USER_GUIDE_AND_SUPPORT_LINK_ENABLED,
  BULK_DELETE_AUTOMATION_PIPELINE
} from "../constants/featureConstants";
import * as LDClient from "launchdarkly-js-client-sdk";
import { LAUNCH_DARKLY_CLIENT_SIDE_ID } from "../constants/envConstants";
import { onPrem } from "@uipath/ah-common";

var ldclient;
class FeatureFlag {
  constructor() {}
  async initialize() {
    if (onPrem()) {
      return;
    }
    if (!ldclient) {
      try {
        var user = {
          key: "anon",
          custom: {
            tenant: (
              SessionHelper.tenantNameFromCurrentPath() || ""
            ).toLowerCase()
          }
        };
        var options = {
          bootstrap: "localStorage"
        };

        ldclient = LDClient.initialize(
          LAUNCH_DARKLY_CLIENT_SIDE_ID,
          user,
          options
        );

        await ldclient.waitUntilReady();

        ldclient.on("change", function(settings) {
          console.log("FF Changed:", settings);
        });
      } catch (error) {}
    }
  }

  isFeatureEnabled(feature_id) {
    //no longer using feature flags from User Object
    try {
      //using boostrap localhost we still might need a super tiny delay here, this will block the main thread

      if (ldclient && ldclient.variation(feature_id, false)) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  }

  isExtendTrialEnabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(EXTEND_TRIAL_LD_KEY);
  };

  isLocalizationEnabled = () => {
    return true;
  };

  isLocalizationTestingEnabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(LOCALIZATION_TESTING_LD_KEY);
  };

  isTaskCaptureLaunchIntegrationEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(TASK_CAPTURE_LD_KEY);
  };

  isTaskCaptureEditDocumentEnabled = () => {
    return true;
  };

  isTaskCaptureMacCompatibilityEnabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(TASK_CAPTURE_MAC_LD_KEY);
  };

  isAdminManageOpenApiEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(ADMIN_MANAGE_OPEN_API_LD_KEY);
  };

  isTenantSelectedDateFormatEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(TENANT_SELECTED_DATE_FORMAT);
  };

  isCategoriesEnabled = () => {
    return true;
  };

  isTopDownDraftsEnabled = () => {
    return true;
  };

  isLocalizationFrenchEnabled = () => {
    return true;
  };

  isLocalizationGermanEnabled = () => {
    return true;
  };

  isLocalizationSpanishEnabled = () => {
    return true;
  };

  isLocalizationPortugueseBrazilEnabled = () => {
    return true;
  };

  isLocalizationPortugueseEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(LOCALIZATION_PORTUGUESE_PORTUGAL_LD_KEY);
  };

  isLocalizationRussianEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(LOCALIZATION_RUSSIAN_LD_KEY);
  };

  isLocalizationTurkishEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(LOCALIZATION_TURKISH_LD_KEY);
  };

  isLocalizationSpanishMexicoEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(LOCALIZATION_SPANISH_MEXICO_LD_KEY);
  };

  isLocalizationKoreanEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(LOCALIZATION_KOREAN_LD_KEY);
  };

  isLocalizationChineseSimplifiedEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(LOCALIZATION_CHINESE_SIMPLIFIED_LD_KEY);
  };

  isUserPilotDisabled = () => {
    if (onPrem()) {
      return true;
    }
    /* Default case intentionally false */
    return this.isFeatureEnabled(USERPILOT_KEY);
  };

  isHotjarDisabled = () => {
    if (onPrem()) {
      return true;
    }
    /* Default case intentionally false */
    return this.isFeatureEnabled(HOTJAR_KEY);
  };

  isAdminAuditLogsEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(ADMIN_AUDIT_LOGS_LD_KEY);
  };

  isAutomationCloudUserGroupManagementEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(AUTOMATION_CLOUD_USER_GROUP_MANAGEMENT_LD_KEY);
  };

  isImportPipelineEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(IMPORT_PIPELINE_KEY);
  };

  isAutomationStoreEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(AUTOMATION_STORE_KEY);
  };

  isPolicyTermsFAQContactLinksEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(POLICY_TERMS_FAQ_CONTACT_LINKS_KEY);
  };

  isApolloDarkThemeEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(APOLLO_DARK_THEME_KEY);
  };

  isUserPilotDarkThemeEnabled = () => {
    return this.isFeatureEnabled(USER_PILOT_DARK_THEME_LD_KEY);
  };

  isAutomationStoreOpenInAssistantEnabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(AUTOMATION_STORE_OPEN_IN_ASSISTANT_LD_KEY);
  };

  isAssistantMacCompatibilityEnabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(ASSISTANT_MAC_COMPATIBILITY_LD_KEY);
  };

  isTaskMiningIntegrationEnabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(TASK_MINING_INTEGRATION_LD_KEY);
  };

  isOrchestratorMappingEnabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(ORCHESTRATOR_MAPPING_LD_KEY);
  };

  isCOAdvancedEditingEnabled = () => {
    if (window?.location?.host?.includes("localhost")) {
      return true;
    }
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(CO_ADVANCED_EDITING_LD_KEY);
  };

  isCOAssessmentImportExportEnabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(CO_ASSESSMENT_EXPORT_IMPORT_LD_KEY);
  };

  isCODebugEnabled = () => {
    if (window?.location?.host?.includes("localhost")) {
      return true;
    }
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(CO_DEBUG_LD_KEY);
  };

  isInsightsPrivatePreviewEnabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(INSIGHTS_PRIVATE_PREVIEW_LD_KEY);
  };

  isTenantSignupMigrateDisabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(TENANT_SIGNUP_MIGRATE_DISABLED_LD_KEY);
  };

  isApolloCompactHeaderOverrideEnabled = () => {
    if (onPrem()) {
      return true;
    }
    return this.isFeatureEnabled(APOLLO_COMPACT_HEADER_OVERRIDE_LD_KEY);
  };

  isJiraIntegrationEnabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(JIRA_INTEGRATION_ENABLED_LD_KEY);
  };

  isHideUserGuideAndSupportLinkEnabled = () => {
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(HIDE_USER_GUIDE_AND_SUPPORT_LINK_ENABLED);
  };

  canBulkDeleteFromAutomationPipeline = () => {
    if (window?.location?.host?.includes("localhost")) {
      return true;
    }
    if (onPrem()) {
      return false;
    }
    return this.isFeatureEnabled(BULK_DELETE_AUTOMATION_PIPELINE);
  };
}

export let featureFlag = new FeatureFlag();
