export enum COSectionTemplateSlugs {
  Q2_DEFAULT_SECTION_V1 = "Q2_DEFAULT_SECTION_V1",
  CBA_DEFAULT_SECTION_V1 = "CBA_DEFAULT_SECTION_V1",
  Q2_DEFAULT_SIDEBAR_SECTION_V1 = "Q2_DEFAULT_SIDEBAR_SECTION_V1",
  Q2_APPLICATIONS_SECTION_V1 = "Q2_APPLICATIONS_SECTION_V1",
  Q3_DEFAULT_SECTION_V1 = "Q3_DEFAULT_SECTION_V1",
  Q1_DEFAULT_SECTION_V1 = "Q1_DEFAULT_SECTION_V1",
  OVR_DEFAULT_SECTION_V1 = "OVR_DEFAULT_SECTION_V1",
  MISC_DEFAULT_SECTION_V1 = "MISC_DEFAULT_SECTION_V1",
  STATS_DEFAULT_SECTION_V1 = "STATS_DEFAULT_SECTION_V1",
  CR_DEFAULT_SECTION_V1 = "CR_DEFAULT_SECTION_V1"
}

export enum CONonStandardSectionTemplateSlugs {
  PROCESS_OWNER_SECTION_V1 = "PROCESS_OWNER_SECTION_V1",
  POTENTIAL_BENEFIT_SECTION_V1 = "POTENTIAL_BENEFIT_SECTION_V1",
  CR_SECTION_VISIBILITY_LOCKED_V1 = "CR_SECTION_VISIBILITY_LOCKED_V1"
}
