import React from "react";
import Truncate from "react-text-truncate";
import { withTranslation } from "react-i18next";
import { showDateWithTenantDateFormat } from "../../helpers/timeDateHelper";
import "./CellTitleMyTasks.scss";
import { IDEA_TYPES, AUTOMATION_TYPE } from "../../constants/profileConstants";
import Tooltip from "../Tooltip/Tooltip";

const CellTitleMyTasks = props => {
  let data;
  const typeClasses = ["CellTitleMyTasks__type"];

  //check if data received is component data or automation data
  if (props.component_name) {
    const { component_name, component_updated_epoch } = props;
    data = {
      name: component_name,
      updated_epoch: component_updated_epoch,
      type: props.t("my_tasks_page_title_cell_type_component")
    };
    typeClasses.push("CellTitleMyTasks__type--purple");
  } else {
    const {
      process_name,
      process_updated_epoch,
      process_submission_type
    } = props;

    let type = "";
    if (IDEA_TYPES.includes(process_submission_type)) {
      type = props.t(["my_tasks_page_title_cell_type_idea", "Idea"]);
    } else if (process_submission_type === AUTOMATION_TYPE) {
      type = props.t([
        "my_tasks_page_title_cell_type_automation",
        "Automation"
      ]);
    }

    data = {
      name: process_name,
      updated_epoch: process_updated_epoch,
      type: type
    };
    typeClasses.push("CellTitleMyTasks__type--blue");
  }

  let name = data.name;

  return (
    <div
      className={`CellTitleMyTasks ${props.className || ""}`}
      data-testid="CellTitleMyTasksContainer"
    >
      <Tooltip title={name}>
        <span className="CellTitleMyTasks__name">
          <Truncate text={name} />
        </span>
      </Tooltip>
      <span className="CellTitleMyTasks__updated-at">
        {showDateWithTenantDateFormat(
          data.updated_epoch,
          props.t("general_data_format_slash", "L")
        )}
      </span>
      <span className={typeClasses.join(" ")}>{data.type}</span>
    </div>
  );
};

export default withTranslation("common")(CellTitleMyTasks);
