import React from "react";
import { useTranslation } from "react-i18next";
import "./CellPhase.scss";

export const processPhase = (process_phase, t) => {
  //e.g. automation_table_sort_options_be_alphabetical
  return process_phase
    ? t([
        "automation_table_sort_options_be_" +
          (process_phase?.phase_key || "").toLowerCase(),
        process_phase.phase_name
      ])
    : "";
};

const CellPhase = props => {
  const { process_phase } = props || {};
  const { t } = useTranslation("common");

  return (
    <div className="CellPhase" data-testid="CellPhaseContainer">
      <span className="CellPhase__text">{processPhase(process_phase, t)}</span>
    </div>
  );
};

export default CellPhase;
