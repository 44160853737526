import React, { Component } from "react";
import { Link } from "react-router-dom";
import Truncate from "react-text-truncate";
import { withTranslation } from "react-i18next";
import Avatar from "../Avatar/Avatar";
import { NESTED_THEME_CONTAINER_ID } from "../StyleThemeProvider/StyleThemeProvider";
import { getTaskIcon } from "../../constants/myTasksIconConstants";
import "./CellTaskMyTasks.scss";
import Tooltip from "../Tooltip/Tooltip";
import { withStyles } from "@material-ui/styles";

const REF_USER_FULL_NAME = "{RefUserFullName}";

const TaskCommentTooltip = withStyles({
  tooltip: {
    width: "254px",
    background: "var(--color-background-raised, #ffffff)",
    border: "1px solid var(--color-border, #a4b1b8)"
  }
})(Tooltip);

class CellTaskMyTasks extends Component {
  constructor(props) {
    super(props);
    this.state = { showTaskComment: false };
  }

  componentDidMount() {
    //this hack is for react truncate to work
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);
  }

  renderTaskComment = () => {
    if (this.props.task_comment) {
      const { user, comment } = this.props.task_comment;
      if (!user) {
        return null;
      }
      const { user_first_name, user_last_name } = user;
      return (
        <div className="CellTaskMyTasks__task-comment">
          <div className="CellTaskMyTasks__avatar-with-name">
            <Avatar user={user} />
            <span className="CellTaskMyTasks__full-name">
              {user_first_name} {user_last_name}{" "}
            </span>
          </div>
          {comment}
        </div>
      );
    }
  };

  onMouseEnterEvent = () => {
    if (this.props.task_comment) {
      this.setState({ showTaskComment: true });
    }
  };

  onMouseLeaveEvent = () => {
    if (this.props.task_comment) {
      this.setState({ showTaskComment: false });
    }
  };

  replaceTaskText = (localizedTaskText, reference_user) => {
    if (
      localizedTaskText &&
      localizedTaskText.includes(REF_USER_FULL_NAME) &&
      reference_user &&
      reference_user.user_first_name &&
      reference_user.user_last_name
    ) {
      const { user_first_name, user_last_name } = reference_user;
      const refUserFullname = `${user_first_name} ${user_last_name}`;
      localizedTaskText = localizedTaskText.replace(
        REF_USER_FULL_NAME,
        refUserFullname
      );
    }
    return localizedTaskText;
  };

  render() {
    const {
      type_name,
      type_key,
      user_task_text,
      task_activity_key,
      user_task_action_url = "",
      t,
      task_comment,
      reference_user
    } = this.props;

    const taskIcon = !this.state.showTaskComment
      ? getTaskIcon(type_key)
      : getTaskIcon(type_key, true);

    let localizedTaskText = task_activity_key
      ? t("my_tasks_page_" + task_activity_key.toLowerCase(), user_task_text)
      : user_task_text;

    // handle replacements here (for now only implementing user who triggered the action)
    localizedTaskText =
      this.replaceTaskText(localizedTaskText, reference_user) ||
      localizedTaskText;

    const commentTooltipProps = !!task_comment ? {} : { open: false };

    return (
      <div className="CellTaskMyTask" data-testid="CellTaskMyTaskContainer">
        <div className="CellTaskMyTask__icon-container">
          {task_comment && !this.state.showTaskComment && (
            <div className="CellTaskMyTask__has-comment-dot" />
          )}
          <TaskCommentTooltip
            title={this.renderTaskComment()}
            placement="right-start"
            {...commentTooltipProps}
            PopperProps={{
              container: document.querySelector(`#${NESTED_THEME_CONTAINER_ID}`)
            }}
          >
            <span
              className="CellTaskMyTask__icon"
              onMouseEnter={this.onMouseEnterEvent}
              onMouseLeave={this.onMouseLeaveEvent}
            >
              {taskIcon}
            </span>
          </TaskCommentTooltip>
        </div>
        <div className="CellTaskMyTask__main">
          <Tooltip title={localizedTaskText} placement="top">
            <Link
              className="CellTaskMyTask__task-name"
              to={user_task_action_url}
              data-for="taskName"
              data-tip={localizedTaskText}
            >
              <Truncate text={localizedTaskText} line={2} />
            </Link>
          </Tooltip>
          <span className="CellTaskMyTask__task-type">{type_name}</span>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(CellTaskMyTasks);
