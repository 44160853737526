import { COPermissionSlugs } from "../../../constants/co-constants";
import { COPermissionInterface } from "../../../interfaces/co-interfaces";

export const permissionsInterfaces: COPermissionInterface[] = [
  {
    slug: COPermissionSlugs.GENERAL_PERMISSIONS,
    meta: {
      value: "View sensitive information from general assessments",
      value_localization_key: "co_sensitive_option_q1_title",
      tooltip:
        "View the questions and KPIs marked as ‘sensitive information’ (when customizing the Overview, Misc. and High Level assessment) in the submission forms, Automation Profile and Automation Pipeline.",
      tooltip_localization_key: "co_sensitive_option_q1_tooltip"
    }
  },
  {
    slug: COPermissionSlugs.Q2_PERMISSIONS,
    meta: {
      value: "View sensitive information from Detailed Assessment",
      value_localization_key: "co_sensitive_option_q2_title",
      tooltip:
        "View the questions and KPIs marked as 'sensitive information' (when customizing the Detailed Assessment) in the Share a CoE idea form, Automation Profile and Automation Pipeline.",
      tooltip_localization_key: "co_sensitive_option_q2_tooltip"
    }
  },
  {
    slug: COPermissionSlugs.Q3_PERMISSIONS,
    meta: {
      value: "View sensitive information from Citizen Developer Assessment",
      value_localization_key: "co_sensitive_option_q3_title",
      tooltip:
        "View the questions and KPIs marked as ‘sensitive information’ (when customizing the Citizen Developer assessment) in the Share an automation form, Automation Profile and Automation Pipeline.",
      tooltip_localization_key: "co_sensitive_option_q3_tooltip"
    }
  },
  {
    slug: COPermissionSlugs.CHANGE_REQUEST_PERMISSIONS,
    meta: {
      value: "View sensitive information from Change request assessments",
      value_localization_key: "co_sensitive_option_change_request_title",
      tooltip:
        "View the questions and KPIs marked as ‘sensitive information’ (when customizing the Change request assessment) in the Submit a change request form, Automation Profile (View & Edit mode) and Automation Pipeline.",
      tooltip_localization_key: "co_sensitive_option_change_request_tooltip"
    }
  }
];
