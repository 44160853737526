import React, { Component } from "react";
import styled, { css } from "styled-components/macro";
import ModalContainer from "../components/Modal/ModalContainer";
import LevelUpModal from "../components/LevelUpModal/LevelUpModal";
import NavAuth from "./../components/NavAuth/NavAuth";
import "./authLayout.scss";
import ErrorHandler from "../components/ErrorHandler/ErrorHandler";
import MasterLayoutWrapper from "./masterLayoutWrapper";
import NavAutomationStore from "../components/NavAutomationStore/NavAutomationStore";

const Container = styled.div(
  props => css`
    && {
      ${props.$portalNavPadding
        ? css`
            padding-top: ${props.theme.sizes["nav-auth-height"]};
          `
        : ""}
    }
  `
);

export default class AuthLayout extends Component {
  render() {
    const { isAutomationStoreNav = false } = this.props;

    const renderNav = () => {
      if (isAutomationStoreNav) {
        return <NavAutomationStore />;
      }

      return <NavAuth />;
    };

    return (
      <MasterLayoutWrapper>
        <Container
          className="AuthLayout"
          $portalNavPadding={isAutomationStoreNav}
        >
          {renderNav()}
          <ErrorHandler>
            {this.props.children}
            <ModalContainer />
            <LevelUpModal />
          </ErrorHandler>
        </Container>
      </MasterLayoutWrapper>
    );
  }
}
