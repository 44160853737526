import React from "react";
import ModalContainer from "../components/Modal/ModalContainer";
import NavAuth from "./../components/NavAuth/NavAuth";
import NavAdmin from "./../components/NavAdmin/NavAdmin";
import ErrorHandler from "../components/ErrorHandler/ErrorHandler";
import MasterLayoutWrapper from "./masterLayoutWrapper";
import styleTheme from "../constants/styleTheme";

const AdminLayout = ({ children }) => {
  const navHeight = styleTheme.sizes["nav-auth-height"];
  return (
    <MasterLayoutWrapper>
      <div style={{ paddingTop: navHeight }}>
        <NavAuth />
        <ErrorHandler>
          <div style={{ paddingTop: navHeight }}>
            <NavAdmin />
            {children}
            <ModalContainer />
          </div>
        </ErrorHandler>
      </div>
    </MasterLayoutWrapper>
  );
};

export default AdminLayout;
