import "core-js/es6";
import React, { Suspense } from "react";
import { render } from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import Routes from "./routes";
import CustomScrollMemory from "./components/CustomScrollMemory/CustomScrollMemory";
import SessionHelper from "./helpers/SessionHelper";
import store from "./store/configureStore";
import { createBrowserHistory } from "history";
import { ai } from "./utils/TelemetryService";
import CookiePopup from "./components/CookiePopup/CookiePopup";
import TagManager from "react-gtm-module";
import { GOOGLE_TAG_MANAGER_ID } from "./constants/envConstants";
import {
  LANGUAGE_COOKIE_NAME,
  getValidLanguage
} from "./constants/localizationConstants";
import LoaderLogoSmall from "./components/LoaderLogoSmall/LoaderLogoSmall";
import { featureFlag } from "./helpers/FeatureFlag";
import moment from "moment";
// Import localization init file
import "./i18n";
import "moment/locale/ja";
import "moment/locale/de";
import "moment/locale/fr";

import { onPrem } from "@uipath/ah-common";
import StyleThemeProvider from "./components/StyleThemeProvider/StyleThemeProvider";
import { registerApolloFonts } from "./utils/register-fonts";
import { registerApolloPackagesScript } from "./utils/register-scripts";

// For non fps deployment use local copy of portal shell and fonts
registerApolloFonts();
registerApolloPackagesScript();

(async () => {
  moment.locale(
    getValidLanguage(SessionHelper.getValueForKey(LANGUAGE_COOKIE_NAME))
  );
  const history = createBrowserHistory({
    basename: ""
  });
  const tagManagerArgs = {
    gtmId: GOOGLE_TAG_MANAGER_ID
  };

  if (!onPrem()) {
    ai.initialize({
      history: history
    });
  }

  await featureFlag.initialize();

  if (!onPrem()) {
    TagManager.initialize(tagManagerArgs);
  }

  history.listen((location, action) => {
    if (!onPrem()) {
      ai.appInsights.trackPageView({
        name: location.pathname
      });
    }

    try {
      if (window.userpilot && !featureFlag.isUserPilotDisabled()) {
        window.userpilot.reload();
      }
    } catch (e) {}
  });

  render(
    <Suspense fallback={<LoaderLogoSmall fullPage />}>
      <StyleThemeProvider>
        <Provider store={store}>
          <Router history={history}>
            <div>
              <CustomScrollMemory />
              <Routes />
              {SessionHelper.isFpsUri() || <CookiePopup />}
            </div>
          </Router>
        </Provider>
      </StyleThemeProvider>
    </Suspense>,
    document.getElementById("root")
  );

  //this allows us to not have the ugly blue focus box around everything by default but allow it if the user is tabbing
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      // the "I am a keyboard user" key
      document.body.classList.add("user-is-tabbing");
      window.removeEventListener("keydown", handleFirstTab);
    }
  }

  window.addEventListener("keydown", handleFirstTab);
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
