import {
  COComponentType,
  COConditionSlugs
} from "../../../constants/co-constants";
import {
  COConditionOverride,
  COConditionOverrideSlugPayload,
  COContextInterface,
  COControlInterface,
  COControlInterfaceKeys,
  COControlOptionListItem,
  COMetadataInterface
} from "../../../interfaces/co-interfaces";
import { getValue } from "../../../utils/co-path.utils";
import { isString } from "../../../utils/co-utils";
import { conditionOverrideFromSlugPayload } from "../conditions/co-conditions";

// basically converts template to array - could filter out ones we don't want here
export const conditionTemplateOptions = (
  slugsOrSlugPayloads: (COConditionSlugs | COConditionOverrideSlugPayload)[]
) => {
  let optionsArray: COConditionOverride[] = slugsOrSlugPayloads.map(SP => {
    let aSP: any = SP;
    if (isString(SP)) {
      return conditionOverrideFromSlugPayload({ slug: aSP }); //currently no payloads
    }
    return conditionOverrideFromSlugPayload(aSP); //currently no payloads
  });
  return optionsArray;
};

export const areSlugPayloadsEqual = (
  a: COConditionOverrideSlugPayload,
  b: COConditionOverrideSlugPayload
): boolean => {
  if (!a && !b) {
    return true;
  }

  if (a.slug != b.slug) {
    return false;
  }

  // like they're both undefined or empty
  if (a.payload == b.payload) {
    return true;
  }

  // super payload compare
  if (JSON.stringify(a) == JSON.stringify(b)) {
    return true;
  }

  return false;
};

const dropdownItems = (
  condition_overrides: COConditionOverrideSlugPayload[]
): COControlOptionListItem[] => {
  return condition_overrides.map(SP => {
    let cond: COConditionOverride = conditionOverrideFromSlugPayload(SP);
    return {
      key: SP.slug,
      value: SP,
      meta: cond.meta
    };
  });
};

export const conditionsSingleChoiceControl = ({
  key,
  target,
  is_disabled,
  is_hidden,
  meta,
  items = []
}: {
  key?: string;
  target?: string;
  is_disabled?: number | string;
  is_hidden?: number | string;
  meta: COMetadataInterface;
  items?: COConditionOverrideSlugPayload[];
}): COControlInterface => {
  if (items?.length == 0) {
    return {};
  }

  return {
    key: key,
    // target should be a path to the options json object we want to set the conditions to
    target: target,
    // what is fired when you select something
    controlFunction: ({
      context,
      control,
      option
    }: {
      context: COContextInterface;
      control: COControlInterface;
      option: any;
    }) => {
      // we're going to handle this manually because we don't want to the resolved item (the unit) we want to set the path to the unit in the template
      // multi select so this is coming in as an array
      let target = control.target; // this is a unresolved path here.
      let condition_overrides: COConditionOverrideSlugPayload[] = getValue(
        context,
        target
      );
      const selectedCondition = option.value;

      // we need to add the selected option, and remove all the already selected options from the items list
      addCondition({
        context,
        slugPayload: selectedCondition,
        condition_overrides
      });
      for (const item of items) {
        if (item.slug === selectedCondition.slug) {
          continue;
        }
        if (
          condition_overrides.some(condition => condition.slug === item.slug)
        ) {
          removeCondition({
            context,
            slugPayload: item,
            condition_overrides
          });
        }
      }
    },
    meta: meta,
    options: {
      component_type: COComponentType.CONTROL_PICKER,
      is_disabled: is_disabled,
      is_hidden: is_hidden
    },
    items: dropdownItems(items)
  };
};

export const conditionsMultiChoiceControl = ({
  key,
  target,
  is_disabled,
  meta,
  items = []
}: {
  key?: string;
  target?: string;
  is_disabled?: number | string;
  meta: COMetadataInterface;
  items?: COConditionOverrideSlugPayload[];
}): COControlInterface => {
  if (items?.length == 0) {
    return {};
  }

  return {
    key: key,
    // target should be a path to the options json object we want to set the conditions to
    target: target,
    // what is fired when you select something
    controlFunction: ({
      context,
      control,
      option
    }: {
      context: COContextInterface;
      control: COControlInterface;
      option: any;
    }) => {
      // we're going to handle this manually because we don't want to the resolved item (the unit) we want to set the path to the unit in the template
      // multi select so this is coming in as an array
      let target = control.target; // this is a unresolved path here.
      let condition_overrides: COConditionOverrideSlugPayload[] = getValue(
        context,
        target
      );
      let options: any[] = option;
      if (options && !Array.isArray(options)) {
        console.log(
          `Error with MutiSelect Control - Selected Answers Not Array ${control.meta?.title?.value}`
        );
      }

      // console.log(condition_overrides);
      let selected_conditional_overrides: any[] = [];
      let conditional_overrides_to_add: any[] = [];
      if (options) {
        for (const opt of options) {
          let slugPayload = opt.value;
          // we can't use set value or it will resolve the unit to the unit in the template
          // only "add it" if it's not already in there
          if (slugPayload) {
            if (
              !condition_overrides.find(SP =>
                areSlugPayloadsEqual(slugPayload, SP)
              )
            ) {
              conditional_overrides_to_add.push(slugPayload);
            }
            selected_conditional_overrides.push(slugPayload);
          }
          //}
        }
      }

      // this makes it so that the control can control a subset of the options
      if (condition_overrides) {
        // filter out the ones that were selectable but not selected
        let allOptions: COControlOptionListItem[] = Array.isArray(control.items)
          ? control.items
          : [];

        let possibly_selected_options: COConditionOverrideSlugPayload[] = allOptions.map(
          opt => {
            return opt.value;
          }
        );

        // remove all of the items that are in the options
        let optionsToRemove = possibly_selected_options.filter(SP => {
          return (
            !selected_conditional_overrides.find(sSP =>
              areSlugPayloadsEqual(sSP, SP)
            ) && condition_overrides.find(eSP => areSlugPayloadsEqual(eSP, SP)) // TODO if we have an option use the same slug as another we need to compare payload
          );
        });
        // remove and add in place to keep pointer working
        // console.log("MAIN REMOVE");
        for (const toRemove of optionsToRemove) {
          // ok need to apply things because we're remove
          removeCondition({
            context,
            slugPayload: toRemove,
            condition_overrides
          });
        }

        // console.log("MAIN ADD");
        // so the issue is that this add shouldn't add things that were removed
        for (const toAdd of conditional_overrides_to_add) {
          addCondition({ context, slugPayload: toAdd, condition_overrides });
        }
      }
    },
    meta: meta,
    options: {
      component_type: COComponentType.CONTROL_PICKER,
      max_selectable_answers: 10000,
      is_disabled: is_disabled
    },
    items: dropdownItems(items)
  };
};

export const addCondition = ({
  context,
  slugPayload,
  condition_overrides
}: {
  context: COContextInterface;
  slugPayload: COConditionOverrideSlugPayload;
  condition_overrides: COConditionOverrideSlugPayload[];
}) => {
  if (slugPayload) {
    const indexOfExisting = condition_overrides.findIndex(SP =>
      areSlugPayloadsEqual(SP, slugPayload)
    );
    if (indexOfExisting < 0) {
      //   console.log(`adding condition - ${condition}`);
      // only add if not in there
      condition_overrides.push(slugPayload);
      //  console.log(condition_overrides);
      let conditionOverride: COConditionOverride = conditionOverrideFromSlugPayload(
        slugPayload
      );

      if (conditionOverride) {
        for (const SP of conditionOverride.add_condition_override_slugs_when_selected ||
          []) {
          //    console.log(`attempting to add ${slug}`);
          addCondition({
            context,
            slugPayload: SP,
            condition_overrides
          });
        }
        for (const SP of conditionOverride.remove_condition_override_slugs_when_selected ||
          []) {
          // console.log(`attempting to remove ${slug}`);
          removeCondition({
            context,
            slugPayload: SP,
            condition_overrides
          });
        }
      }
    }
  }
  // console.log(condition_overrides);
};

export const removeCondition = ({
  context,
  slugPayload,
  condition_overrides
}: {
  context: COContextInterface;
  slugPayload: COConditionOverrideSlugPayload;
  condition_overrides: COConditionOverrideSlugPayload[];
}) => {
  if (slugPayload) {
    let idxToRemove = condition_overrides.findIndex(SP =>
      areSlugPayloadsEqual(SP, slugPayload)
    );
    if (idxToRemove > -1) {
      // console.log(
      //   `removing condition - ${condition} - at index ${idxToRemove}`
      // );
      condition_overrides.splice(idxToRemove, 1);
      // console.log(condition_overrides);
      let conditionOverride: COConditionOverride = conditionOverrideFromSlugPayload(
        slugPayload
      );
      if (conditionOverride) {
        for (const SP of conditionOverride.add_condition_override_slugs_when_deselected || //TODO those should be slug payloads
          []) {
          //      console.log(`attempting to add ${slug}`);
          addCondition({
            context,
            slugPayload: SP,
            condition_overrides
          });
        }
        for (const SP of conditionOverride.remove_condition_override_slugs_when_deselected ||
          []) {
          //    console.log(`attempting to remove ${slug}`);
          removeCondition({
            context,
            slugPayload: SP,
            condition_overrides
          });
        }
      }
    }
  }
  //  console.log(condition_overrides);
};
