import { COContextObjectKey } from "./co-constants";

// this will return all of the paths that we use under a route so like context.questions or context.sections.meta
export const allPathsInRoute = (pathRoute): string[] => {
  let paths: string[] = [];
  if (typeof pathRoute === "object") {
    for (const k of Object.keys(pathRoute)) {
      paths = [...paths, ...allPathsInRoute(pathRoute[k])];
    }
  } else {
    paths.push(pathRoute);
  }
  return paths;
};

//format is PATH(context.question.co_question_options_json.is_hidden,inverse)
//first index is the pathObjects property, then properties on that object, then an operator
export const PATH_SEP = ".";
export const PATH_PARAM_SEP = ",";

// this generates a path string in the correct format for a route and an operator
export const PATH = ({
  route,
  operator,
  operatorParams
}: {
  route: any;
  operator?: string;
  operatorParams?: any;
}) => {
  return `PATH(${route}${operator ? "," + operator : ""}${
    operatorParams ? "," + JSON.stringify(operatorParams || "") : ""
  })`;
};

// this is more flexible - used where we're more programatically making paths like for units
export const dynamicPath = (
  contextObjectKey: COContextObjectKey,
  pathToObject: string,
  operator?: string
): string => {
  return PATH({
    route: `${CONTEXT_KEY}.${contextObjectKey}.${pathToObject}`,
    operator
  });
};

export const CONTEXT_KEY = "context";
export const COMPARISON_CONTEXT_KEY = "comparison_context";

// this are used to transform the value at the end of the path when getting or setting it
// very useful when you want a switch that is "Show Section" to map to a property like is_hidden
export const PATH_OPERATORS = {
  FUNCTION: "function",
  SPLIT_INDEX: "splitIndex",
  INVERSE: "inverse",
  FORCE_OFF: "forceOff",
  FORCE_ON: "forceOn",
  IS_DEFINED: "isDefined",
  IS_UNDEFINED: "isUndefined",
  IS_EMPTY_ARRAY: "isEmptyArray",
  IS_NOT_EMPTY_ARRAY: "isNotEmptyArray",
  IS_GREATER_THAN_ONE: "isGreaterThanOne",
  IS_NOT_GREATER_THAN_ONE: "isNotGreaterThanOne"
};
export const PATH_ROUTE = {
  CONTEXT: {
    ASSESSMENT_OBJECT: "context.assessment",
    DISPLAY_CONTEXT: {
      ALL_PIPELINES: "context.display_context.all_pipelines",
      ASSESSMENT_SUBMISSION: "context.display_context.assessment_submission",
      ASSESSMENT_VIEW_SIDEBAR:
        "context.display_context.assessment_view_sidebar",
      ASSESSMENT_VIEW: "context.display_context.assessment_view",
      ASSESSMENT_CUSTOMIZE: "context.display_context.assessment_customize"
    },
    ASSESSMENT: {
      FUNCTION_ALL_QUESTIONS: "context.assessment.functionAllQuestions",
      FUNCTION_ASSESSMENT_VISIBLE_SECTIONS:
        "context.assessment.functionNumVisibleSections",
      FUNCTION_SELECTED_ANSWER_OPTION_AHID_FOR_QUESTION:
        "context.assessment.functionSelectedAnswerOptionAhidForQuestion",
      FUNCTION_ARE_ALL_REQUIRED_QUESTIONS_ANSWERED:
        "context.assessment.functionAreAllRequiredQuestionsAnswered",
      CO_ASSESSMENT_SECTIONS_OBJECT:
        "context.assessment.co_assessment_sections",
      CO_ASSESSMENT_SECTIONS: "context.assessment.co_assessment_sections",
      CO_ASSESSMENT_META_JSON: {
        TITLE: {
          VALUE: "context.assessment.co_assessment_meta_json.title.value"
        },
        DESCRIPTION: {
          VALUE: "context.assessment.co_assessment_meta_json.description.value"
        }
      },
      CO_ASSESSMENT_VERSION_META_JSON: {
        TITLE: {
          VALUE:
            "context.assessment.co_assessment_version_meta_json.title.value"
        },
        DESCRIPTION: {
          VALUE:
            "context.assessment.co_assessment_version_meta_json.description.value"
        },
        VERSION: {
          VALUE:
            "context.assessment.co_assessment_version_meta_json.version.value"
        },
        VERSION_NUMBER: {
          VALUE:
            "context.assessment.co_assessment_version_meta_json.version_number.value"
        }
      }
    },
    SECTION: {
      FUNCTION_SECTION_VISIBLE_QUESTIONS:
        "context.section.functionNumVisibleQuestions",
      FUNCTION_SECTION_BRANCHING_CONTROLS:
        "context.section.functionSectionBranchingControls",
      CO_SECTION_META_JSON: {
        TITLE: {
          VALUE: "context.section.co_section_meta_json.title.value"
        },
        DESCRIPTION: {
          VALUE: "context.section.co_section_meta_json.description.value"
        },
        NOTE: { VALUE: "context.section.co_section_meta_json.note.value" }
      },
      META: {
        TITLE: { VALUE: "context.section.meta.title.value" },
        DESCRIPTION: { VALUE: "context.section.meta.description.value" }
      },
      OPTIONS: {
        DISPLAY_CONTEXT_OVERRIDES: {
          ASSESSMENT_VIEW: {
            IS_HIDDEN:
              "context.section.options.display_context_overrides.assessment_view.is_hidden"
          },
          ASSESSMENT_SUBMISSION: {
            IS_HIDDEN:
              "context.section.options.display_context_overrides.assessment_submission.is_hidden"
          },
          ASSESSMENT_VIEW_SIDEBAR: {
            IS_HIDDEN:
              "context.section.options.display_context_overrides.assessment_view_sidebar.is_hidden"
          }
        },
        IS_HIDDEN: "context.section.options.is_hidden",
        IS_READ_ONLY: "context.section.options.is_read_only",
        IS_TABULAR: "context.section.options.is_tabular"
      },
      CO_QUESTIONS: "context.section.co_questions",
      CO_SECTION_OPTIONS_JSON: {
        CONDITION_OVERRIDES:
          "context.section.co_section_options_json.condition_overrides",
        DISABLE_ADDING_APPLICATIONS:
          "context.section.co_section_options_json.disable_adding_applications",
        DISPLAY_CONTEXT_OVERRIDES: {
          ASSESSMENT_VIEW_SIDEBAR: {
            IS_HIDDEN:
              "context.section.co_section_options_json.display_context_overrides.assessment_view_sidebar.is_hidden"
          },
          ASSESSMENT_VIEW: {
            IS_HIDDEN:
              "context.section.co_section_options_json.display_context_overrides.assessment_view.is_hidden"
          },
          ASSESSMENT_SUBMISSION: {
            IS_HIDDEN:
              "context.section.co_section_options_json.display_context_overrides.assessment_submission.is_hidden"
          }
        },
        IS_HIDDEN: "context.section.co_section_options_json.is_hidden",
        IS_TABULAR: "context.section.co_section_options_json.is_tabular"
      }
    },
    QUESTION: {
      FUNCTION_QUESTION_IS_ANSWERED:
        "context.question.functionQuestionIsAnswered",
      FUNCTION_KPIS_REFERENCED_CONTROL_OPTIONS:
        "context.question.functionKPIReferencedControlOptions",
      FUNCTION_OTHER_QUESTIONS_CONTROL_OPTIONS:
        "context.question.functionOtherQuestionsControlOptions",
      FUNCTION_QUESTION_ANSWERS_CONTROL_OPTIONS:
        "context.question.functionQuestionAnswersControlOptions",
      FUNCTION_OTHER_QUESTIONS_VARIABLES_DROPDOWN_OPTIONS:
        "context.question.functionOtherQuestionsVariablesDropdownOptions",
      FUNCTION_QUESTION_BRANCHING_CONTROLS:
        "context.question.functionQuestionBranchingControls",
      FUNCTION_QUESTIONS_THAT_CAN_IMPACT_KPIS_CONTROL_OPTIONS:
        "context.question.functionQuestionsThatCanImpactKPISControlOptions",
      CO_QUESTION_DEFAULT_OPTIONS_JSON: {
        DEFAULT_EQUATION_VALUE:
          "context.question.co_question_default_options_json.default_equation_value"
      },
      OPTIONS: {
        DISPLAY_CONTEXT_OVERRIDES: {
          ASSESSMENT_VIEW: {
            IS_HIDDEN:
              "context.question.options.display_context_overrides.assessment_view.is_hidden"
          },
          ASSESSMENT_SUBMISSION: {
            OPTIONAL_FOR_SUBMISSION:
              "context.question.options.display_context_overrides.assessment_submission.optional_for_submission"
          },
          ASSESSMENT_VIEW_SIDEBAR: {
            IS_HIDDEN:
              "context.question.options.display_context_overrides.assessment_view_sidebar.is_hidden"
          }
        },
        UNIT_OBJECT: "context.question.options.unit", //wish I had a better way to reference the object directly here -
        UNIT: {
          TITLE_FORMAT: "context.question.options.unit.title_format",
          DISPLAY_VALUE_FORMAT:
            "context.question.options.unit.display_value_format",
          INPUT_VALUE_FORMAT: {
            PREFIX: "context.question.options.unit.input_value_format.prefix",
            SUFFIX: "context.question.options.unit.input_value_format.suffix"
          }
        },
        IS_HIDDEN: "context.question.options.is_hidden",
        IS_READ_ONLY: "context.question.options.is_read_only",
        NO_TABULAR_SUPPORT: "context.question.options.no_tabular_support",
        VISIBILITY_PERMISSIONS:
          "context.question.options.visibility_premissions",
        CHART: {
          X_AXIS: "context.question.options.chart.x_axis",
          Y_AXIS: "context.question.options.chart.y_axis",
          INTERSECTION: "context.question.options.chart.intersection"
        },
        HIDE_COMMENTS_SECTION: "context.question.options.hide_comments_section",
        PRE_FILL_DEFAULT_ANSWER:
          "context.question.options.pre_fill_default_answer",
        MAX_SELECTABLE_ANSWERS:
          "context.question.options.max_selectable_answers",
        MAX_QUESTION_ANSWER_OPTIONS:
          "context.question.options.max_question_answer_options",
        MIN_QUESTION_ANSWER_OPTIONS:
          "context.question.options.min_question_answer_options",
        OPTIONAL_FOR_SUBMISSION:
          "context.question.options.optional_for_submission",
        ROUNDING_DECIMALS: "context.question.options.rounding_decimals",
        MIN_VALUE: "context.question.options.min_value",
        MAX_VALUE: "context.question.options.max_value",
        MIN_SELECTABLE_ANSWERS:
          "context.question.options.min_selectable_answers",
        EXTERNAL_ANSWER_SOURCE:
          "context.question.options.external_answer_source",
        CAN_IMPACT_KPI: "context.question.options.can_impact_kpi",
        MAX_ANSWER_LENGTH: "context.question.options.max_answer_length"
      },
      CO_QUESTION_OPTIONS_JSON: {
        MIN_SELECTABLE_ANSWERS:
          "context.question.co_question_options_json.min_selectable_answers",
        EXTERNAL_ANSWER_SOURCE:
          "context.question.co_question_options_json.external_answer_source",
        DISPLAY_CONTEXT_OVERRIDES: {
          ASSESSMENT_VIEW: {
            IS_HIDDEN:
              "context.question.co_question_options_json.display_context_overrides.assessment_view.is_hidden"
          },
          ASSESSMENT_VIEW_SIDEBAR: {
            IS_HIDDEN:
              "context.question.co_question_options_json.display_context_overrides.assessment_view_sidebar.is_hidden"
          },
          ASSESSMENT_VIEW_MODAL: {
            IS_HIDDEN:
              "context.question.co_question_options_json.display_context_overrides.assessment_view_modal.is_hidden"
          },
          ASSESSMENT_SUBMISSION: {
            IS_HIDDEN:
              "context.question.co_question_options_json.display_context_overrides.assessment_submission.is_hidden"
          }
        },
        UNIT_OBJECT: "context.question.co_question_options_json.unit", //wish I had a better way to reference the object directly here -
        IS_HIDDEN: "context.question.co_question_options_json.is_hidden",
        VISIBILITY_PERMISSIONS:
          "context.question.co_question_options_json.visibility_permissions",
        CONDITION_OVERRIDES:
          "context.question.co_question_options_json.condition_overrides",
        CHART: {
          TITLE: {
            VALUE: "context.question.co_question_options_json.chart.title.value"
          },
          X_AXIS_AHID:
            "context.question.co_question_options_json.chart.x_axis_ahid",
          Y_AXIS_AHID:
            "context.question.co_question_options_json.chart.y_axis_ahid",
          BUBBLE_AHID:
            "context.question.co_question_options_json.chart.bubble_ahid",

          X_AXIS_RANGE:
            "context.question.co_question_options_json.chart.x_axis_range",
          Y_AXIS_RANGE:
            "context.question.co_question_options_json.chart.y_axis_range",

          BUBBLE_RANGE:
            "context.question.co_question_options_json.chart.bubble_range"
        },
        MAX_SELECTABLE_ANSWERS:
          "context.question.co_question_options_json.max_selectable_answers",
        MAX_QUESTION_ANSWER_OPTIONS:
          "context.question.co_question_options_json.max_question_answer_options",
        MIN_QUESTION_ANSWER_OPTIONS:
          "context.question.co_question_options_json.min_question_answer_options",
        ROUNDING_DECIMALS:
          "context.question.co_question_options_json.rounding_decimals"
      },
      CO_QUESTION_META_JSON: {
        TITLE: {
          VALUE: "context.question.co_question_meta_json.title.value",
          HELP_TEXT: "context.question.co_question_meta_json.title.help_text"
        },
        DESCRIPTION: {
          VALUE: "context.question.co_question_meta_json.description.value"
        },
        NOTE: {
          VALUE: "context.question.co_question_meta_json.note.value"
        },
        PREFILL: {
          VALUE: "context.question.co_question_meta_json.prefill.value"
        }
      },
      CO_VARIABLE_NAME: "context.question.co_variable_name",
      CO_DISPLAY_EQUATION: "context.question.co_display_equation",
      CO_PROCESS_CALCULATED_VALUE: {
        CO_CALCULATED_VALUE:
          "context.question.co_process_calculated_value.co_calculated_value"
      },
      CO_QUESTION_TEMPLATE_SLUG: "context.question.co_question_template_slug",
      META: {
        TITLE: { VALUE: "context.question.meta.title.value" },
        DESCRIPTION: {
          VALUE: "context.question.meta.description.value"
        },
        VARIABLE: { VALUE: "context.question.meta.variable.value" },
        EQUATION: { VALUE: "context.question.meta.equation.value" }
      },
      CO_PROCESS_ANSWER: {
        CO_PROCESS_ANSWER_SELECTIONS:
          "context.question.co_process_answer.co_process_answer_selections"
      },
      CO_QUESTION_ANSWER_OPTIONS: "context.question.co_question_answer_options",
      CO_EQUATION: "context.question.co_equation"
    },
    PROCESS_ANSWER: {
      FUNCTION_SELECTED_ANSWER_OPTION_LEGACY_SLUGS:
        "context.process_answer.functionSelectedAnswerOptionsLegacySlugs",
      META: {
        COMMENT: { VALUE: "context.process_answer.meta.comment.value" }
      },
      CO_PROCESS_ANSWER_META_JSON: {
        COMMENT: {
          VALUE:
            "context.process_answer.co_process_answer_meta_json.comment.value"
        }
      },
      CO_PROCESS_ANSWER_SELECTIONS:
        "context.process_answer.co_process_answer_selections"
    },
    ANSWER_OPTION: {
      FUNCTION_BRANCHING_PATHS_CONTROLS:
        "context.assessment.functionBranchingPathsControls",
      CO_QUESTION_ANSWER_OPTION_VALUE:
        "context.answer_option.co_question_answer_option_value",
      META: {
        ANSWER_VALUE: {
          VALUE: "context.answer_option.meta.answer_value.value",
          PLACEHOLDER: "context.answer_option.meta.answer_value.placeholder"
        },
        TITLE: { VALUE: "context.answer_option.meta.title.value" }
      },
      CO_QUESTION_ANSWER_OPTION_META_JSON: {
        ANSWER_VALUE: {
          VALUE:
            "context.answer_option.co_question_answer_option_meta_json.answer_value.value",
          PLACEHOLDER:
            "context.answer_option.co_question_answer_option_meta_json.answer_value.placeholder"
        },
        TITLE: {
          VALUE:
            "context.answer_option.co_question_answer_option_meta_json.title.value"
        }
      }
    },
    PROCESS_ANSWER_SELECTION: {
      PROCESS_ANSWER_SELECTION_OBJECT: "context.process_answer_selection",
      CO_PROCESS_ANSWER_SELECTION_INPUT:
        "context.process_answer_selection.co_process_answer_selection_input",
      CO_PROCESS_ANSWER_SELECTION_INFO_JSON:
        "context.process_answer_selection.co_process_answer_selection_info_json"
    },

    TEMPLATES: {
      UNITS: {
        NONE: "context.templates.units.none",
        EASE_OF_IMPLEMENTATION_LEVEL:
          "context.templates.units.ease_of_implementation_level",
        DAYS_YEAR: "context.templates.units.days_year",
        HOURS_YEAR: "context.templates.units.hours_year",
        HOURS_MONTH: "context.templates.units.hours_month",
        HOURS_DAY: "context.templates.units.hours_day",
        MINUTES: "context.templates.units.minutes",
        DAYS: "context.templates.units.days",
        YEARS: "context.templates.units.years",
        CURRENCY: "context.templates.units.currency",
        PERCENT: "context.templates.units.percent",
        YESNO: "context.templates.units.yesno",
        SUBMISSION_TYPE: "context.templates.units.submission_type",
        DEVELOPMENT_TYPE: "context.templates.units.development_type",
        AUTOMATION_TYPE: "context.templates.units.automation_type"
      },
      QUESTION_TYPE_CONTROL_OPTIONS:
        "context.templates.question_type_control_options",
      QUESTION_TYPE_ADVANCED_CONTROL_OPTIONS:
        "context.templates.question_type_advanced_control_options",
      KPI_TYPE_CONTROL_OPTIONS: "context.templates.kpi_type_control_options",
      KPI_TYPE_ADVANCED_CONTROL_OPTIONS:
        "context.templates.kpi_type_advanced_control_options"
    },
    PROCESS_EXTERNAL_DATA: {
      PHASE_STATUS_KEY: "context.process_external_data.phase_status_key",
      CATEGORY_DATA: "context.process_external_data.category_data",
      POST_ACTION_PHASE_STATUS_KEY:
        "context.process_external_data.post_action_phase_status_key",
      FUNCTION_EXTERNAL_PROPERTY_VALUE_FOR_QUESTION:
        "context.process_external_data.functionExternalPropertyValueForQuestion",
      APPLICATION_DATA: {
        APPLICATIONS:
          "context.process_external_data.application_data.applications"
      },
      CURRENCY_OBJECT: "context.process_external_data.currency",
      CURRENCY: {
        CURRENCY_NAME: "context.process_external_data.currency.currency_name",
        CURRENCY_ABBREVIATION:
          "context.process_external_data.currency.currency_abbreviation",
        CURRENCY_SYMBOL:
          "context.process_external_data.currency.currency_symbol"
      },
      COLLABORATOR_DATA: {
        PROCESS_OWNER:
          "context.process_external_data.collaborator_data.process_owner"
      },
      PROCESS: {
        PARENT_PROCESS: {
          PROCESS_SUBMISSION_TYPE:
            "context.process_external_data.process.parent_process.process_submission_type"
        }
      },
      PERMISSIONS: {
        PROCESS_PERMISSIONS: {
          P_EDIT:
            "context.process_external_data.permissions.process_permissions.p_edit"
        }
      }
    }
  },
  COMPARISON_CONTEXT: {
    ASSESSMENT_OBJECT: "comparison_context.assessment",
    ASSESSMENT: {
      CO_ASSESSMENT_SECTIONS:
        "comparison_context.assessment.co_assessment_sections"
    },
    SECTION: {
      CO_QUESTIONS: "comparison_context.section.co_questions",
      CO_SECTION_OPTIONS_JSON: {
        IS_TABULAR:
          "comparison_context.section.co_section_options_json.is_tabular"
      }
    },
    QUESTION: {
      CO_VARIABLE_NAME: "comparison_context.question.co_variable_name",
      CO_DISPLAY_EQUATION: "comparison_context.question.co_display_equation",
      CO_QUESTION_TEMPLATE_SLUG:
        "comparison_context.question.co_question_template_slug",
      META: {
        TITLE: { VALUE: "comparison_context.question.meta.title.value" },
        DESCRIPTION: {
          VALUE: "comparison_context.question.meta.description.value"
        },
        VARIABLE: {
          VALUE: "comparison_context.question.meta.variable.value"
        },
        EQUATION: {
          VALUE: "comparison_context.question.meta.equation.value"
        }
      },
      CO_QUESTION_ANSWER_OPTIONS:
        "comparison_context.question.co_question_answer_options",
      CO_EQUATION: "comparison_context.question.co_equation"
    },
    ANSWER_OPTION: {
      CO_QUESTION_ANSWER_OPTION_VALUE:
        "comparison_context.answer_option.co_question_answer_option_value",
      META: {
        ANSWER_VALUE: {
          VALUE: "comparison_context.answer_option.meta.answer_value.value"
        },
        TITLE: {
          VALUE: "comparison_context.answer_option.meta.title.value"
        }
      },
      CO_QUESTION_ANSWER_OPTION_META_JSON: {
        TITLE: {
          VALUE:
            "comparison_context.answer_option.co_question_answer_option_meta_json.title.value"
        }
      }
    }
  }
};
