import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Modal from "../Modal";
import ModalTemplateOne from "../ModalTemplateOne/ModalTemplateOne";
import "./RejectComponent.scss";
import ButtonApolloSecondary from "../../buttons/ButtonApolloSecondary/ButtonApolloSecondary";
import ButtonApolloPrimary from "../../buttons/ButtonApolloPrimary/ButtonApolloPrimary";
import InputTextApollo from "../../inputs/InputTextApollo/InputTextApollo";
import InputLabelApollo from "../../inputs/InputLabelApollo/InputLabelApollo";
import ReactSelectApollo from "../../ReactSelectApollo/ReactSelectApollo";

class RejectComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rejectionType: null,
      rejectionTypeError: "",
      rejectionReason: "",
      rejectionReasonError: ""
    };
  }

  rejectionTypeChange = e => {
    this.setState({ rejectionType: e.value, rejectionTypeError: "" });
  };

  rejectionReasonChange = e => {
    this.setState({
      rejectionReason: e.target.value,
      rejectionReasonError: ""
    });
  };

  handleReject = () => {
    let rejectionType = this.state.rejectionType;
    let rejectionReason = this.state.rejectionReason;
    if (rejectionType === null || rejectionReason === "") {
      if (rejectionType === null) {
        this.setState({
          rejectionTypeError: this.props.t(
            "modal_reject_component_error_rejection_type_required",
            "You must select a rejection type"
          )
        });
      }
      if (rejectionReason === "") {
        this.setState({
          rejectionReasonError: this.props.t(
            "modal_reject_component_error_reason_required",
            "You need to type a reason for rejecting this component"
          )
        });
      }
      return;
    }

    if (rejectionType === "permanently") {
      typeof this.props.reduxProps.handleRejectPermanently === "function" &&
        this.props.reduxProps.handleRejectPermanently(rejectionReason);
    } else {
      typeof this.props.reduxProps.handleRejectUpdate === "function" &&
        this.props.reduxProps.handleRejectUpdate(rejectionReason);
    }
    this.props.closeModal();
  };
  render() {
    const modalContentStyle = {
      borderRadius: "4px",
      width: "500px",
      padding: "0 24px 24px"
    };

    return (
      <Modal onClose={this.props.closeModal} contentStyle={modalContentStyle}>
        <ModalTemplateOne
          heading={this.props.t(
            "modal_reject_component_heading",
            "Reject Component?"
          )}
          onClose={this.props.closeModal}
        >
          <div
            className="RejectComponent__inner-container"
            data-testid="RejectComponent"
          >
            <div className="RejectComponent__message">
              {this.props.t(
                "modal_reject_component_are_you_sure",
                "Are you sure you would like to reject this component?"
              )}
            </div>

            <InputLabelApollo
              className="RejectComponent__rejection-type"
              label={this.props.t(
                "modal_reject_component_rejection_type",
                "Rejection Type"
              )}
              required
              error={!!this.state.rejectionTypeError}
              helperText={this.state.rejectionTypeError}
            >
              <ReactSelectApollo
                classNamePrefix="RejectComponentRejectReason"
                placeholder={this.props.t(
                  "general_dropdown_placeholder",
                  "Select"
                )}
                options={[
                  {
                    label: this.props.t(
                      "modal_reject_component_rejection_type_needs_update",
                      "Needs Update"
                    ),
                    value: "update"
                  },
                  {
                    label: this.props.t(
                      "modal_reject_component_rejection_type_reject_permanently",
                      "Reject Permanently"
                    ),
                    value: "permanently"
                  }
                ]}
                onChange={this.rejectionTypeChange}
              />
            </InputLabelApollo>

            <InputTextApollo
              className="RejectComponent__reject-reason"
              InputProps={{
                value: this.state.rejectionReason,
                onChange: this.rejectionReasonChange
              }}
              label={this.props.t(
                "modal_reject_component_rejection_reason",
                "Rejection Reason"
              )}
              error={!!this.state.rejectionReasonError}
              helperText={this.state.rejectionReasonError}
              required
            />

            <div className="RejectComponent__button-container">
              <ButtonApolloSecondary onClick={this.props.closeModal}>
                {this.props.t("modal_reject_component_cancel", "Cancel")}
              </ButtonApolloSecondary>
              <ButtonApolloPrimary onClick={this.handleReject}>
                {this.props.t("modal_reject_component_reject", "Reject")}
              </ButtonApolloPrimary>
            </div>
          </div>
        </ModalTemplateOne>
      </Modal>
    );
  }
}

const mapStateToProps = ({ modal }) => ({
  reduxProps: modal.modalProps
});

export default withTranslation("common")(
  connect(mapStateToProps)(RejectComponent)
);
